<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th class="">Security Operations Centre - Direct Feeds
      </th>
      <th class="col-1">
        Enabled
      </th>
      <th class="col-1">
        <div (click)="create()" class="btn btn-primary float-end">Create</div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of this.data ">
      <td><span class="divLink" (click)="edit(row.Id)">{{row.Name}}</span>

      </td>
      <td>
        <i *ngIf="row.Enabled" class="fad fa-check-circle fa-2x" aria-hidden="true" style="color: #46a946;"></i>
        <i *ngIf="!row.Enabled" class="fad fa-times-circle fa-2x" aria-hidden="true" style="color: indianred;"></i>

      </td>
      <td>
        <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
        <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
