<div class="sectionBox p-0">
  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
  <div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

    <table class="table align-middle tableStyle">
      <thead>
      <tr>
        <th class="">Scheduled Tickets
          <div (click)="create()" class="btn btn-primary float-end">Create</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of this.filterData() ">
        <td><span class="divLink" (click)="edit(row.Id)">{{row.Request_Subject}}</span>
          <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
          <i class="fas fa-search float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
