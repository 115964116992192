import {Component, ViewContainerRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {RequestApiService} from '../../../services/api/request/request-api.service';
import {OptionsApiService} from '../../../services/api/options/options-api.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {EnvironmentService} from '../../../services/environment/environment.service';
import {AttachmentService} from '../../../services/api/attachment/attachment.service';
import {RequestChangeService} from '../../../services/api/request/request-change/request-change.service';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {interval, Subscription} from 'rxjs';
import {ChatApiService} from '../../../services/api/agent/chat/chat-api';
import {ChatWindowComponent} from './chat-window/chat-window.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.css'
})
export class ChatComponent {

  private updatePoller: Subscription;

  public chatConfigId;
  public viewName;
  public requestId;

  public conversations = [];

  public isLoading = true;

  public maxConversationId = null;

  constructor(
    private route: ActivatedRoute,
    private chatApi: ChatApiService
  ) {  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

      this.ngOnDestroy();

      this.chatConfigId = params.get("chatConfigId");
      this.viewName = params.get("viewName");
      this.requestId = null;
      if (params.get("RequestId") === '') {
        this.requestId = null;
      } else {
        this.requestId = params.get("RequestId");
      }

      console.error("RequestId is: " + this.requestId);

      this.refreshConversations();
      this.startPollingForUpdates();
    })

    // // Start the real time timer and send data back to the API every 10 seconds
    // this.realTimeTimer = interval(10000);
    // this.realTimeTimer = interval(10000).subscribe(poll => this.refreshRealTimeStats());
  }

  refreshConversations(silentRefresh = false){

    if(!silentRefresh) {
      this.isLoading = true;
    }

    this.chatApi.listConversations(this.viewName).then(response => {
      this.conversations = response;
      this.isLoading = false;

      // find the MAX id of the conversations in this.conversations
      let newMaxId = Math.max.apply(Math, this.conversations.map(function(o) { return o.Id; }))

      // if the newMaxId is greater than the current maxConversationId, then play a sound
      if (this.maxConversationId && newMaxId > this.maxConversationId) {
        console.log("Play audio from refresh conversations")
        console.log("newMaxId: " + newMaxId, "maxConversationId: " + this.maxConversationId)
        //this.playAudio();
      }

      this.maxConversationId = newMaxId;


    })
  }

  startPollingForUpdates() {
    this.updatePoller = interval(10000).subscribe(() => {

      if(this.isLoading)
        return;

      this.refreshConversations(true);

    });
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "/assets/notification.wav";
    audio.load();
    audio.play();
  }

  ngOnDestroy() {
    if (this.updatePoller) {
      this.updatePoller.unsubscribe();
    }
  }

}
