import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.css']
})
export class UserGroupsComponent implements OnInit {
  selectedTab;
  constructor() { }

  ngOnInit(): void {
  }
 
}
