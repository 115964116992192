import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Dialog} from "../../../../../shared/dialog/dialog";
import {ApiAdminAutomationService} from "../../../../../services/api/admin/admin-automation-api/admin-automation-api.service";
import {OptionsApiService} from "../../../../../services/api/options/options-api.service";

@Component({
  selector: 'app-admin-automation-create-edit-dialog',
  templateUrl: './admin-automation-create-edit-dialog.component.html'
})

export class AdminAutomationComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  selectedTab = 'main'

  showEditor = true;

  isLoading = true;
  isLoadingRequestTypes = true;
  requestTypes = [];

  isLoadingOptions = false;
  options;
  previousGroupId = null;

  loadedId = null;

  loadedData;

  selectedActionDropdown = 0;


  actionOptions = [
    {id: 0, name: 'Select an action', applyOnce: false },
    // {id: 1, name: 'Set Group', applyOnce: true },
    {id: 2, name: 'Set Group & Agent', applyOnce: true },
    {id: 3, name: 'Set Status', applyOnce: true },
    {id: 4, name: 'Set Customer', applyOnce: true },
    {id: 5, name: 'Set Service', applyOnce: true },
    {id: 6, name: 'Set Impact', applyOnce: true },
    {id: 7, name: 'Set Urgency', applyOnce: true },
    {id: 8, name: 'Reply', applyOnce: false },
    {id: 9, name: 'Internal Comment', applyOnce: false },
    {id: 10, name: 'Send Email', applyOnce: false },
    {id: 11, name: 'Send to Slack', applyOnce: false },
    {id: 12, name: 'Send SMS', applyOnce: false },
    {id: 13, name: 'Set Priority', applyOnce: true },
    {id: 14, name: 'Email Agent', applyOnce: false },
    {id: 15, name: 'Send Via Webhook', applyOnce: false },
    {id: 16, name: 'Send to Meta Alerts', applyOnce: false },
    {id: 17, name: 'Email Group', applyOnce: false }
  ]

  triggerOptions = [
    {name: 'Ticket is Created / Updated',id: 1},
    {name: 'There is an SLA breach',id: 2}
  ]

  slaTriggerOptions = [
    {id: 1, name: 'Response'},
    {id: 2, name: 'Resolution'}
  ];

  slaTriggerTypeOptions = [
    {id: 3, name: 'After'},
    {id: 1, name: 'Before'},
    {id: 2, name: 'When'}
  ];

  filterTypes = [
    {id: 3, name: 'Not Filtered'},
    {id: 1, name: 'Is'},
    {id: 4, name: 'Is Not'},
    {id: 2, name: 'Is Unassigned'}
  ];

  filterTextTypes = [
    {id: 1, name: 'Contains'},
    {id: 2, name: 'Does not Contain'},
    {id: 3, name: 'Not Filtered'}
  ];

  dropdownSettings = {
    singleSelection: false,
    text: "Select",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'name',
    lazyLoading: true,
    badgeShowLimit: 4,
    classes: "sdesk-multi-select"
  };

  constructor(
    private apiAdminAutomationService: ApiAdminAutomationService,
    private apiAdminOptionsService: OptionsApiService
  ) {
    this.getRequestTypes();
  }

  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        Description: '',
        RequestTypeId : null,
        RunOnce: 1,
        Enabled: 1,
        Group_FilterTypeId : 3,
        Agent_FilterTypeId : 3,
        Status_FilterTypeId : 3,
        Priority_FilterTypeId : 3,
        Customer_FilterTypeId : 3,
        Service_FilterTypeId : 3,
        Subject_FilterTextTypeId : 3,
        Body_FilterTextTypeId : 3,
        Actions : [],
        GroupValues: [],
        AgentValues: [],
        StatusValues: [],
        PriorityValues: [],
        CustomerValues: [],
        ServiceValues: [],
        SubjectValues: [],
        BodyValues: [],
        Trigger: 1,
        Method: 'POST'
      };

      this.isLoading = false;

    }
  }

  getActions(){
    return this.actionOptions.filter(action => {

      // Some filters can only be used once, check the applied filters to filter out the ones that have already been used.
      let isThisActionAlreadyUsed = this.loadedData.Actions.some(a => a.ActionId == action.id);

      console.log("name "+action.name)
      console.log("applyOnce "+action.applyOnce)
      console.log("isThisActionAlreadyUsed "+isThisActionAlreadyUsed)


      if (action.applyOnce && isThisActionAlreadyUsed) {
        return false;
      }
      return true;
    });
  }

  removeAction(index) {
    this.loadedData.Actions.splice(index, 1);
  }

  getActionName(actionId){
    return (this.actionOptions.find(action => action.id == actionId)).name
  }

  addAction(){
    if(!this.selectedActionDropdown){
      return;
    }

    let action = {
      ActionId : this.selectedActionDropdown,
      ValueId : null,
      TemplateText: '', // Used By 8 (Reply), 9 (Comment), 10 (New Email), 12 (SMS), 11 (Send to Slack), 14 (Email Agent)
      EmailAddress : '', // Used By 10 (New Email)
      SlackWebhookUrl: '', // Used By 11 (Send to Slack)
      MobileNumber: '+44', // Used by 12 (Send SMS)
    }

    if (action.ActionId == 16) {
      action.TemplateText = JSON.stringify({
        "payload": {
          "summary": "{RequestSubject}",
          "timestamp": "2015-07-17T08:42:58.315+0000",
          "source": "{AgentRequestLink}",
          "severity": "{PriorityName}",
          "component": "postgres",
          "group": "{GroupName}",
          "custom_details": {
            "ping time": "1500ms",
            "load avg": 0.75
          }
        },
        "dedup_key": "samplekeyhere",
        "images": [
          {
            "src": "https://em.dev.aytra.io/assets/images/logo-words.png",
            "href": "https://example.com/",
            "alt": "Example text"
          }
        ],
        "links": [
          {
            "href": "https://example.com/",
            "text": "Link text"
          }
        ],
        "event_action": "trigger",
        "client": "Sample Monitoring Service",
        "client_url": "https://monitoring.example.com"
      }, null, 2);
    }

    this.loadedData.Actions.push(action);
    this.selectedActionDropdown = 0;

  };

  getRequestTypes(){
    this.isLoadingRequestTypes = true;
    this.apiAdminOptionsService.AllRequestTypes().then(response => {
      this.requestTypes = response;
      this.isLoadingRequestTypes = false;
    })
  }

  getOptions(action=null, groupId=null){
    this.isLoadingOptions = true;

    // Clear agent field only if the group has changed
  if (this.previousGroupId !== groupId) {
      action.ValueId = null;
    }

    // Update previous groupId
    this.previousGroupId = groupId;

    if(this.loadedData.RequestTypeId){
      this.apiAdminOptionsService.DynamicOptions(this.loadedData.RequestTypeId, groupId).then(response => {
        this.options = response;
        this.isLoadingOptions = false;
        })
    } else {
      console.log("Cannot refresh options, no typeId is selected.");
    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminAutomationService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;

      this.getOptions();
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminAutomationService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminAutomationService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
