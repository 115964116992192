<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
  <button class="navbar-button" id="menu-search" (click)="toggleSearchDiv()">
  <i class="fas fa-search"></i>
</button>

<div *ngIf="isSearchDivVisible" class="row" id="search-div">
  <div class="col-md-2" style="display: flex; align-items: stretch;">
    <select [(ngModel)]="defaultSearchType" class="form-control large-search-box" data-style="btn-white" style="height: 100%;">
      <option *ngFor="let option of searchType" [label]="option.name" [value]="option.value"></option>
    </select>
  </div>
  <div class="col-md-10">
    <input [(ngModel)]="searchText" (keyup.enter)="searchAuditLog()" type="text" class="form-control large-search-box" autocomplete="off" placeholder="Search..." id="search">
  </div>
</div>

<div class="row" >      
<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20 m-t-20 sectionBox">

<div class="col">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="recordCount" [rowsPerPageOptions]="[50,100,500]"></p-paginator>
   
   </div>
  <table class="table align-middle tableStyle">
    <thead>
      <tr>
        <th (click)="toggleSortDirection('AffectedId')" style="cursor: pointer;">
          Affected Id
          <span *ngIf="sortField === 'AffectedId' && sortDirection === 'asc'"
            style="font-weight: 900; font-size: 1rem; cursor: pointer;">
            ↑
          </span>
          <span *ngIf="sortField === 'AffectedId' && sortDirection === 'desc'"
            style="font-weight: 900; font-size: 1rem; cursor: pointer;">
            ↓
          </span>
        </th>
        <th (click)="toggleSortDirection('AuditDateTime')" style="cursor: pointer;">
          Dates (UTC)
          <span *ngIf="sortField === 'AuditDateTime' && sortDirection === 'asc'"
            style="font-weight: 900; font-size: 1rem; cursor: pointer;">
            ↑
          </span>
          <span *ngIf="sortField === 'AuditDateTime' && sortDirection === 'desc'"
            style="font-weight: 900; font-size: 1rem; cursor: pointer;">
            ↓
          </span>
        </th>
        <th (click)="toggleSortDirection('UserName')" style="cursor: pointer;">
          Users
          <span *ngIf="sortField === 'UserName' && sortDirection === 'asc'"
          style="font-weight: 900; font-size: 1rem; cursor: pointer;">
          ↑
        </span>
        <span *ngIf="sortField === 'UserName' && sortDirection === 'desc'"
        style="font-weight: 900; font-size: 1rem; cursor: pointer;">
        ↓
      </span>
        </th>
        <th (click)="toggleSortDirection('AuditType')" style="cursor: pointer;">
          Type
          <span *ngIf="sortField === 'AuditType' && sortDirection === 'asc'"
            style="font-weight: 900; font-size: 1rem; cursor: pointer;">
            ↑
          </span>
    
          <span *ngIf="sortField === 'AuditType' && sortDirection === 'desc'"
            style="font-weight: 900; font-size: 1rem; cursor: pointer;">
            ↓
          </span>
        </th>
        <th class="col-md-5" (click)="toggleSortDirection('EventInfo')" style="cursor: pointer;">
          Info
          <span *ngIf="sortField === 'EventInfo' && sortDirection === 'asc'"
            style="font-weight: 900; font-size: 1rem; cursor: pointer;">
            ↑
          </span>
          <span *ngIf="sortField === 'EventInfo' && sortDirection === 'desc'"
          style="font-weight: 900; font-size: 1rem; cursor: pointer;">
          ↓
        </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let event of sortedData">
        <td>{{ event.AffectedId }}</td>
        <td>{{ event.AuditDateTime }}</td>
        <td>{{ event.SystemTriggered ? 'System' : event.UserName }}</td>
        <td>{{ event.AuditType }}</td>
        <td class="text-break">{{ event.EventInfo }}</td>
      </tr>
    </tbody>
  </table>
</div>
