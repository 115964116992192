<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">


  <ng-select [items]="requestTypes"
             bindLabel="name"
             bindValue="id"
             [(ngModel)]="requestTypeId"
             (change)="getData()"
             [clearable]="false">
  </ng-select>

  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th class="">Email Templates
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of this.data ">
      <td><span class="divLink" (click)="edit(row.Id)">{{row.TemplateDescription}}</span>
        <span class="float-end divLink" (click)="override(this.requestTypeId, row.Name)" *ngIf="!row.Id">(Override)</span>
        <span class="float-end divLink" (click)="delete(row.Id)" *ngIf="this.requestTypeId != 0 && row.Id">(Revert)</span>

        <i *ngIf="row.Id" class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
