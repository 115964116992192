import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {ApiService} from "../../services/api/api.service";
import {BrandingService} from "../../services/branding/branding.service";
import {ButtonComponent} from "../button-component/button-component.component";
import {DialogService} from "../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css']
})
export class ParentComponent implements OnInit {

  constructor(private dialogService: DialogService, private viewReference: ViewContainerRef) {}

  test(){
    this.dialogService.createDialog(
      ButtonComponent,
      {id:12399999999999},
      this.viewReference)
  }

  // createComponent(id) {
  //
  //   //this.entry.clear();
  //   const factory = this.resolver.resolveComponentFactory(ButtonComponent);
  //   const hey = this.entry.createComponent(factory);
  //   hey.instance.id = id;
  //   hey.instance.remove.subscribe(hey2 => {
  //     this.hey = hey2
  //     hey.destroy();
  //   })
  // }
  //
  // destroyComponent() {
  //   //this.componentRef.destroy();
  // }

  ngOnInit(): void {
  }

}
