<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-xlarge">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Custom Field</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="customFieldTypeOptions"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.FieldType"
                   [disabled]="this.loadedId">
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Required
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Required" class="form-check-input" id="Required" type="checkbox"><label for="Required" class="p-l-10"></label>

      </div>
    </div>

    <div class="row" *ngIf="!isCmdbEnabled()">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Shared with all request types
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Shared" class="form-check-input" id="Shared" type="checkbox"><label for="Shared" class="p-l-10"></label>
      </div>
    </div>

    <div class="row m-t-10" *ngIf="loadedData.FieldType == 2">
      <div class="col p-r-10">
        <ul class="list-group m-t-10 m-b-5" *ngIf="loadedData.Options">
          <li *ngFor="let option of filterOptions(1, 0)" [class.active]="selectedLevel1Id == option.Id" (click)="selectedLevel1Id = option.Id; selectedLevel2Id = null;" class="list-group-item divLink">
            <input type="checkbox" [(ngModel)]="option.selected">
            {{option.Name}}

            <span class="col-xs-1 pull-right">
              <span (click)="deleteOption(option.Id)" class="fa fa-trash m-r-5"></span>
            </span>

            <span class="col-xs-1 pull-right">
              <a (click)="moveOptionUp(option.Id)"><i ng-hide="$first" class="fa fa-arrow-up m-r-5"></i></a>
            </span>
            <span class="col-xs-1 pull-right">
              <a (click)="moveOptionDown(option.Id)"><i ng-hide="$last" class="fa fa-arrow-down"></i></a>
            </span>
          </li>
        </ul>
        <div class="input-group m-b-10">
          <input id="AddLevel1Value" autocomplete="off" [(ngModel)]="addLevel1Value" type="text"
                 class="form-control" [disabled]="!loadedId">
          <span class="input-group-btn">
                    <button (click)="createOption(addLevel1Value,1,0)" type="button"
                            class="btn waves-effect waves-light btn-primary m-0 m-l-5" [disabled]="!loadedId">Add</button>
                            <button (click)="deleteSelectedOptions()" type="button" class=" btn-cancel  m-l-5 "  [disabled]="!loadedId">Delete Selected</button>
                </span>
                

        </div>
      </div>

      <div class="col p-r-10">
        <div *ngIf="selectedLevel1Id">
          <ul class="list-group m-t-10 m-b-5" *ngIf="loadedData.Options">
            <li *ngFor="let option2 of filterOptions(2, selectedLevel1Id)" [class.active]="selectedLevel2Id == option2.Id" (click)="selectedLevel2Id = option2.Id" class="list-group-item divLink">
               {{option2.Name}}

              <span class="col-xs-1 pull-right">
                <span (click)="deleteOption(option2.Id)" class="fa fa-trash m-r-5"></span>
              </span>

              <span class="col-xs-1 pull-right">
                <a (click)="moveOptionUp(option2.Id)"><i ng-hide="$first" class="fa fa-arrow-up m-r-5"></i></a>
              </span>
              <span class="col-xs-1 pull-right">
                <a (click)="moveOptionDown(option2.Id)"><i ng-hide="$last" class="fa fa-arrow-down"></i></a>
              </span>
            </li>
          </ul>
          <div class="input-group m-b-10">
            <input id="AddLevel2Value" autocomplete="off" [(ngModel)]="addLevel2Value" type="text"
                   class="form-control" [disabled]="!loadedId">
            <span class="input-group-btn">
              <button (click)="createOption(addLevel2Value,2,selectedLevel1Id)" type="button"
                      class="btn waves-effect waves-light btn-primary m-0" [disabled]="!loadedId">Add</button>
                    </span>
          </div>
        </div>
      </div>

      <div class="col">
        <div *ngIf="selectedLevel2Id">
          <ul class="list-group m-t-10 m-b-5" *ngIf="loadedData.Options">
            <li class="list-group-item divLink" *ngFor="let option3 of filterOptions(3, selectedLevel2Id)">

              {{option3.Name}}

              <span class="col-xs-1 pull-right">
                <span (click)="deleteOption(option3.Id)" class="fa fa-trash m-r-5"></span>
              </span>

              <span class="col-xs-1 pull-right">
                <a (click)="moveOptionUp(option3.Id)"><i ng-hide="$first" class="fa fa-arrow-up m-r-5"></i></a>
              </span>
              <span class="col-xs-1 pull-right">
                <a (click)="moveOptionDown(option3.Id)"><i ng-hide="$last" class="fa fa-arrow-down"></i></a>
              </span>
            </li>
          </ul>
          <div class="input-group m-b-10">
            <input id="addLevel3Value" autocomplete="off" [(ngModel)]="addLevel3Value" type="text" class="form-control" [disabled]="!loadedId">
            <span class="input-group-btn">
              <button (click)="createOption(addLevel3Value,3,selectedLevel2Id)" type="button" class="btn waves-effect waves-light btn-primary m-0" [disabled]="!loadedId">Add</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <p class="text-warning" *ngIf="loadedData.FieldType == 2 && !loadedId">Please save before options can be added.</p>

    <div class="m-t-20">
      <button (click)="save()"  class="btn btn-primary" [disabled] ="isSaving" >Save</button>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
