

import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminCustomFieldService} from "../../../../../services/api/admin/admin-custom-fields-api/admin-custom-fields-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminUserCustomFieldsComponentDialog} from "./admin-user-custom-fields-create-edit-dialog/admin-user-custom-fields-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import { ApiAdminUsersService } from 'src/app/services/api/admin/admin-users-api/admin-users-api.service';

@Component({
  selector: 'app-admin-user-custom-fields',
  templateUrl: './admin-user-custom-fields.html'
})
export class AdminUserCustomFieldsComponent implements OnInit {

  @Input() requestTypeId;
  selectedTab;
  data;
  isLoading = true;

  constructor(private apiAdminCustomFieldService: ApiAdminCustomFieldService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private apiAdminUsersService: ApiAdminUsersService,) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.isLoading = true;
    this.apiAdminUsersService.getUserAllCustomField().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

   create(){
  //   // Replace null with Component
   this.dialogService.createDialog(AdminUserCustomFieldsComponentDialog, {id: null, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

       })
   }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminUserCustomFieldsComponentDialog, {id: id, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
      this.getData();
    }, dialogCancelled => {

      })
  }

   delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
       this.apiAdminUsersService.deleteCustomField(id).then(response => this.getData())
     }, cancelled => null)
  }



}
