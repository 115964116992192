import {Component, Renderer2} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthenticationService} from "./services/authentication/authentication.service";
import {UserSearchService} from './services/user-search.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SDesk-AdminCentre';
  sideBarNavOpen = true;
  theme = 'hey';
  //theme = 'darkTheme';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private auth: AuthenticationService) {

  }

  ngOnInit() {
    // Add Dark theme, if its selected?
    this.renderer.addClass(document.body, this.theme);

    this.route.queryParams.subscribe(params => {
      if(params['jwt']){
        console.log("JWT Detected. Adding it to localStorage")
        this.auth.setAuthToken(params['jwt']);

        // Redirect to returnPath if exists....
        if(params['returnPath']){
          this.router.navigate([params['returnPath']]).catch(error => {
            console.log("route does not exist:" + error);
            this.router.navigate(['/']);
          });
        } else {
          this.router.navigate(['/']);
        }

      }
    });
  }
}
