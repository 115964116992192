<div class="row m-t-20">
  <div class="col-12">

    <ul class="nav m-b-5">
      <li class="nav-item divLink" [routerLink]="['/agent/admin/email-webhooks/email']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Inbound Email
        </a>
      </li>

        <li class="nav-item divLink" [routerLink]="['/agent/admin/email-webhooks/outbound']">
          <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            Outbound Email
          </a>
        </li>

          <li class="nav-item divLink" [routerLink]="['/agent/admin/email-webhooks/templates']">
            <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              Email Templates
            </a>
          </li>

      <li class="nav-item divLink" [routerLink]="['/agent/admin/email-webhooks/webhooks']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Webhooks
        </a>
      </li>

    </ul>
  </div>
  <div class="col-12 sectionBox">
    <div class="m-t-20">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
