import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminScheduledRequestsService} from "../../../../services/api/admin/admin-scheduled-requests-api/admin-scheduled-requests-api.service";
import {DialogService} from "../../../../services/dialog-service/dialog.service";
import {AdminScheduledRequestsComponentDialog} from "./admin-scheduled-requests-create-edit-dialog/admin-scheduled-requests-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-scheduled-requests',
  templateUrl: './admin-scheduled-requests.html'
})
export class AdminScheduledRequestsComponent implements OnInit {

  @Input() filterRequestTypeId: number;

  data;
  isLoading = true;

  constructor(private apiAdminScheduledRequestsService: ApiAdminScheduledRequestsService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  filterData() {
    if (this.filterRequestTypeId) {
      return this.data.filter(row => row.RequestTypeId == this.filterRequestTypeId)
    } else {
      return this.data;
    }
  }

  getData() {
    this.isLoading = true;
    this.apiAdminScheduledRequestsService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create() {
    // Replace null with Component
    this.dialogService.createDialog(AdminScheduledRequestsComponentDialog, {
      createNewWithRequestTypeId: this.filterRequestTypeId,
      id: null
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id) {
    // Replace null with Component
    this.dialogService.createDialog(AdminScheduledRequestsComponentDialog, {
      createNewWithRequestTypeId: null,
      id: id
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminScheduledRequestsService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }


}
