

import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAssetRegisterService} from '../../../../services/api/asset-register-api/asset-register-api.service';
import {AssetRegisterComponentDialog} from './asset-register-create-edit-dialog/asset-register-create-edit-dialog.component';
import {ModalConfirmationComponent} from '../../../../shared/modal-confirmation/modal-confirmation.component';
import {DialogService} from '../../../../services/dialog-service/dialog.service';
import {SelectionModel} from '@angular/cdk/collections';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-asset-register',
  templateUrl: './asset-register.html'
})
export class AssetRegisterComponent implements OnInit {

  data;
  isLoading = true;
  searchTerm = "";

  // filter
  filterTypeIds = new SelectionModel(true,[1,2,3,4,5,6,7,8,9,10,11,12]);
  filterStatusIds = new SelectionModel(true,[1,2,3,4]);


  cols: any[];
  _selectedColumns: any[];

  statusOptions = [
    {id: 1, name: 'In Use'},
    {id: 2, name: 'Missing'},
    {id: 3, name: 'In Transit'},
    {id: 4, name: 'In Stock'},
    {id: 5, name: 'Retired'},
    {id: 6, name: 'Disposed'},
  ];

  assetTypes = [
    {id: 1, name: 'Desktop'},
    {id: 2, name: 'Laptop'},
    {id: 3, name: 'Server'},
    {id: 4, name: 'Mobile'},
    {id: 5, name: 'Tablet'},
    {id: 6, name: 'Monitor'},
    {id: 7, name: 'Printer'},
    {id: 8, name: 'Router'},
    {id: 9, name: 'Switch'},
    {id: 10, name: 'Access Point'},
    {id: 11, name: 'Firewall'},
    {id: 12, name: 'Other'},
  ];

  constructor(private apiAssetRegisterService: ApiAssetRegisterService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {

    this.cols = [
      { field: 'Name', header: 'Name' }
    ];

    this._selectedColumns = this.cols;

  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  toggleStatus(){
    if(this.filterStatusIds.selected.length == this.statusOptions.length){
      this.filterStatusIds = new SelectionModel(true,[]);
    } else {
      this.statusOptions.forEach(status => {
        this.filterStatusIds.select(status.id);
      })
    }
  }

  toggleTypes(){
    if(this.filterTypeIds.selected.length == this.assetTypes.length){
      this.filterTypeIds = new SelectionModel(true,[]);
    } else {
      this.assetTypes.forEach(ty => {
        this.filterTypeIds.select(ty.id);
      })
    }
  }

  getData(){
    this.isLoading = true;
    this.apiAssetRegisterService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  getFilteredAssets(){

    var filteredData = this.data;

    filteredData = filteredData.filter(row => {
      return this.filterStatusIds.selected.includes(row.Status);
    });
    filteredData = filteredData.filter(row => {
      return this.filterTypeIds.selected.includes(row.AssetTypeId);
    });



    if (this.searchTerm) {
      filteredData = filteredData.filter(row => {
        return row.Name?.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          || row.Manufacturer?.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          || row.Model?.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          || row.SerialNumber?.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          || row.AssetNo?.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          || row.Imei?.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          || row.OwnerName?.toString().toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    }

    return filteredData;

  }

  export() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "assets");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AssetRegisterComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AssetRegisterComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAssetRegisterService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }

  getType(id){
    return this.assetTypes.find(type => type.id == id).name
  }
  getStatus(id){
    return this.statusOptions.find(type => type.id == id).name
  }


}
