
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminGroupsService {

  apiUri = '/api/admin/group'

  constructor(private apiService: ApiService) {
  }

  listAll() {
    return this.apiService.get(this.apiUri);
  }
  
  GetPagination(page:number,perpage:number){
    return this.apiService.get(`/api/admin/group?page=${page}&perPage=${perpage}`);
  }

  get(id) {
    return this.apiService.get(`/api/v2/admin/group/${id}`);
  }

  delete(id) {
    return this.apiService.delete(`${this.apiUri}/${id}`);
  }

  create(data) {
    return this.apiService.post(this.apiUri, data);
  }

  update(id, data) {
    return this.apiService.put(`${this.apiUri}/${id}`, data);
  }

  recover(id) {
    return this.apiService.post(`${this.apiUri}/${id}/recover`);
  }


}



