import { Injectable } from '@angular/core';
import { ApiService } from "../api.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AgentUserService {
  public usersSubscription = new BehaviorSubject<any[]>([]);

  constructor(private api: ApiService) { }

  GetUsers() {
    return this.api.get(`/api/agent/user`);
  }

  GetPagination(page: number, perpage: number) {
    return this.api.get(`/api/agent/user?page=${page}&perPage=${perpage}`);
  }

  refreshUsers() {
    this.GetUsers().then(response => {
      this.usersSubscription.next(response);
    }).catch(error => {
      console.error('Error fetching users:', error);
    });
  }
}
