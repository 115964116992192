import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {DashboardSettingsApiService} from "../../../../services/api/agent/dashboard-settings/dashboard-settings.service";

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.css']
})
export class DashboardSettingsComponent implements OnInit {

  @Output() closeSettings = new EventEmitter<boolean>();
  public dashboardSettings;
  public loading = true;

  constructor(private dashboardSettingsApi: DashboardSettingsApiService) {
    this.getData();
  }

  getData(){
    this.loading = true;
    this.dashboardSettingsApi.getSettings().then(data => {
      this.dashboardSettings = data
      this.loading = false;
    }).catch(error => {})
  }

  save(){
    this.dashboardSettingsApi.updateSettings(this.dashboardSettings).then(response => {
      this.getData();
      this.close();
    }).catch(error => {});
  }

  close(){
    this.closeSettings.emit(true);
  }

  ngOnInit(): void {
  }

}
