import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  public brandingSubscription = new BehaviorSubject<any>(null);
  private brandingApiUrl = '/api/branding'

  constructor(private api: ApiService) {
    this.refreshBranding();
  }

  public refreshBranding() {
    this.api.get(this.brandingApiUrl).then(response => {
      this.brandingSubscription.next(response)
    }).catch(error => {
        console.error(error);
        console.error("Failed to get branding");
      }
    )
  }

}
