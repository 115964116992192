import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {RequestLinkService} from "../../../../services/api/request/request-link/request-link.service";
import {RequestLinkModalComponent} from "../request-link-modal/request-link-modal.component";
import {DialogService} from "../../../../services/dialog-service/dialog.service";
import {RequestMajorIncidentService} from "../../../../services/api/request/request-major-incident/request-major-incident.service";
import {MajorIncidentLinkCustomersModalComponent} from "./major-incident-link-customers-modal/major-incident-link-customers-modal.component";
import {MajorIncidentLinkServicesModalComponent} from "./major-incident-link-services-modal/major-incident-link-services-modal.component";
import {MajorIncidentSendMassCommunicationBladeComponent} from "./major-incident-send-mass-communication-blade/major-incident-send-mass-communication-blade.component";

@Component({
  selector: 'app-request-major-incident-management',
  templateUrl: './request-major-incident-management.component.html',
  styleUrls: ['./request-major-incident-management.component.css']
})
export class RequestMajorIncidentManagementComponent implements OnInit {

  @Input() requestDetails;
  @Output() refreshData = new EventEmitter<any>();

  public selectedTab = 'tasks';

  constructor(private requestLinkApi: RequestLinkService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private majorIncidentApi: RequestMajorIncidentService) { }

  ngOnInit(): void {
  }

  markTaskComplete(taskId){
    this.majorIncidentApi.markTaskComplete(this.requestDetails.Id, taskId).then(response => this.refreshParent())
  }

  sendMassCommunication(taskObject){
    this.dialogService.createDialog(MajorIncidentSendMassCommunicationBladeComponent, {requestDetails: this.requestDetails, taskObject: taskObject}, this.viewReference)
      .then(dialogSaved => {
        this.refreshParent();
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  addLink(){
    this.dialogService.createDialog(RequestLinkModalComponent, {requestId: this.requestDetails.Id, requestTypeId: this.requestDetails.TypeId}, this.viewReference)
      .then(dialogSaved => {
        this.refreshParent();
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  linkServices(){
    this.dialogService.createDialog(MajorIncidentLinkServicesModalComponent, {requestId: this.requestDetails.Id}, this.viewReference)
      .then(dialogSaved => {
        this.refreshParent();
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  linkCustomers(){
    this.dialogService.createDialog(MajorIncidentLinkCustomersModalComponent, {requestId: this.requestDetails.Id}, this.viewReference)
      .then(dialogSaved => {
        this.refreshParent();
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  removeServiceLink(serviceId){
    this.majorIncidentApi.removeServiceLink(this.requestDetails.Id, serviceId).then(response => this.refreshParent());
  }

  removeCustomerLink(customerId){
    this.majorIncidentApi.removeCustomerLink(this.requestDetails.Id, customerId).then(response => this.refreshParent());
  }

  removeLink(requestLinkId){
    this.requestLinkApi.removeLink(requestLinkId).then(response => this.refreshParent());
  }

  refreshParent() {
    this.refreshData.emit();
  }

}
