<h1>Child Component</h1>

<!--<h2>Test1: Using parameter from parent:</h2>-->
<!--<p>Message from Parent : {{Message}}</p>-->

<!--<hr>-->

<!--<h2>Test1: Send Message from child to parent:</h2>-->
<!--<p (click)="sendMessage()">Send Message</p>-->



<h1 (click)="setToken()">Click here to set token</h1>
<h1>Child Token: {{Token}}</h1>

