
<div>
  <ng-select bindLabel="name"
           bindValue="id"
           [(ngModel)]="level1SelectedId"
           (change)="onChange(1)"
           [clearable]="false"
            [disabled]="isDisabled">
  <ng-option *ngFor="let option of getDropdownOptions(1,0)" [value]="option.id">{{option.name}}</ng-option>
</ng-select>

<ng-select class="m-t-3" *ngIf="level2SelectedId || getDropdownOptions(2,level1SelectedId).length > 0"
           bindLabel="name"
           bindValue="id"
           [(ngModel)]="level2SelectedId"
           (change)="onChange(2)"
           [clearable]="false"
           [disabled]="isDisabled">
  <ng-option *ngFor="let option of getDropdownOptions(2,level1SelectedId)" [value]="option.id">{{option.name}}</ng-option>
</ng-select>

<ng-select class="m-t-3" *ngIf="level3SelectedId || getDropdownOptions(3,level2SelectedId).length > 0"
           bindLabel="name"
           bindValue="id"
           [(ngModel)]="level3SelectedId"
           (change)="onChange(3)"
           [clearable]="false"
           [disabled]="isDisabled">
  <ng-option *ngFor="let option of getDropdownOptions(3,level2SelectedId)" [value]="option.id">{{option.name}}</ng-option>
</ng-select>
</div>


<!--<select [(ngModel)]="level1SelectedId" (ngModelChange)="onChange(1);" class="form-control input-sm dropdownStyle" id="{{this.customFieldId}}_Level1" data-style="btn-white">-->
<!--  <option [value]="null" disabled></option>-->
<!--  <option *ngFor="let option of getDropdownOptions(1,0)" [value]="option.id" [label]="option.name"></option>-->
<!--</select>-->

<!--<select *ngIf="level2SelectedId || getDropdownOptions(2,level1SelectedId).length > 0" [(ngModel)]="level2SelectedId" (ngModelChange)="onChange(2);" class="form-control input-sm dropdownStyle" id="{{this.customFieldId}}_Level2" data-style="btn-white">-->
<!--  <option [value]="null" disabled></option>-->
<!--  <option *ngFor="let option of getDropdownOptions(2,level1SelectedId)" [value]="option.id" [label]="option.name"></option>-->
<!--</select>-->

<!--<select *ngIf="level3SelectedId || getDropdownOptions(3,level2SelectedId).length > 0" [(ngModel)]="level3SelectedId" (ngModelChange)="onChange(3); " class="form-control input-sm dropdownStyle" id="{{this.customFieldId}}_Level3" data-style="btn-white">-->
<!--  <option [value]="null" disabled></option>-->
<!--  <option *ngFor="let option of getDropdownOptions(3,level2SelectedId)" [value]="option.id" [label]="option.name"></option>-->
<!--</select>-->

