<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">

    <h3 class="modal-title" id="exampleModalLabel">Please select the task list you would like to add</h3>
    <input [(ngModel)]="searchTerm" (ngModelChange)="onSearchTermChange()" type="text" class="form-control form-control-sm" id="Search" placeholder="Search..." autocomplete="off" style="width: 190px;">
  </div>
 
   

  <div class="modal-body">

    <div *ngFor="let taskList of filteredTaskLists" class="TabSelector divLink" [class.active]="selectedTaskListId == taskList.Id" (click)="selectedTaskListId = taskList.Id">
      {{ taskList.Name }}
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary" [class.disabled]="!selectedTaskListId">Select</div>
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
