import { Component, OnInit } from '@angular/core';
import {RootApiService} from '../../../services/api/root/root-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {
  instanceData: any;

  constructor(private rootApi: RootApiService) { }

  ngOnInit(): void {
    this.getData();
  }

  loginToInstance = (instanceId, subDomain) => {
    this.rootApi.getLoginJwt(instanceId).then(data => {
        window.location.href = `https://${subDomain}.sdesk.co.uk/?jwt=${data.jwt}&returnPath=/agent/`;
      }
    );
  }

  getData = () => {
    this.rootApi.getInstances().then(data => this.instanceData = data);
  }

}
