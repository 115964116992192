<div class="row m-t-20">
  <div class="col-12">

    <ul class="nav m-b-5">
      <li class="nav-item divLink" [routerLink]="['/agent/admin/users-groups/users']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Users
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['/agent/admin/users-groups/groups']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Groups
        </a>
      </li>
     
      <li class="nav-item divLink" [routerLink]="['/agent/admin/users-groups/custom-fields']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          User Custom Fields
        </a>
      </li>
    

    </ul>
  </div>
  
  <div class="col-12 sectionBox">
    
    <div class="m-t-20">
      
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
