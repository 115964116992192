import { Injectable } from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class RequestChangeService {

  private requestApi = '/api/itil/request'

  constructor(private apiService: ApiService) { }

  updateChangePlanning(requestId, Planning_ReasonForChange, Planning_Impact, Planning_RolloutPlan, Planning_BackoutPlan, Planning_RiskLevel,iscomplete:boolean){
    let body = {
      Planning_ReasonForChange: Planning_ReasonForChange,
      Planning_Impact: Planning_Impact,
      Planning_RolloutPlan: Planning_RolloutPlan,
      Planning_BackoutPlan: Planning_BackoutPlan,
      Planning_RiskLevel: Planning_RiskLevel,
      iscomplete
    };
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/`, body);
  }

  setPlanningComplete(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/planningcomplete`);
  }

  editPlanning(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/editplanning`);
  }

  updateImplementation(requestId, Implementation_Notes,Actual_StartDate,Actual_EndDate,iscomplete:boolean){
    let body = {
      Implementation_Notes: Implementation_Notes,
      Actual_StartDate: Actual_StartDate,
      Actual_EndDate: Actual_EndDate,
      iscomplete
    };
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/implementation`, body);
  }

  setImplementationComplete(requestId, implemented){
    let body = {
      Implemented: implemented
    };
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/implementationcomplete`, body);
  }

  updateClosureNotes(requestId, Closure_Notes){
    let body = {
      Closure_Notes: Closure_Notes
    };
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/closurenotes`, body);
  }

  setClosureComplete(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/close`);
  }

  updateReview(requestId, Review_Notes){
    let body = {
      Review_Notes: Review_Notes
    };
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/review`, body);
  }

  setReviewComplete(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/reviewcomplete`);
  }

  setChangeManager(requestId, newChangeManagerId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/changemanager/${newChangeManagerId}`);
  }

  setChangeType(requestId, newChangeTypeId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/changetype/${newChangeTypeId}`);
  }

  getCabBoards(requestId){
    return this.apiService.get(`${this.requestApi}/${requestId}/changemanagement/cab`);
  }

  searchCabBoards(requestId, searchTerm){
    return this.apiService.post(`${this.requestApi}/${requestId}/changemanagement/cab/search`, {"SearchTerm":searchTerm});
  }

  getCabBoardMembers(requestId, cabId){
    return this.apiService.get(`${this.requestApi}/${requestId}/changemanagement/cab/${cabId}/members`);
  }

  requestAddCabMembers(requestId, arrayOfUsers){
    return this.apiService.post(`${this.requestApi}/${requestId}/changemanagement/cabmembers`, arrayOfUsers);
  }

  requestRemoveCabMember(requestId, userId, cabId){
    return this.apiService.delete(`${this.requestApi}/${requestId}/changemanagement/cabmembers/${userId}/${cabId}`);
  }

  requestCabApproval(requestId, arrayOfUserIds){
    return this.apiService.post(`${this.requestApi}/${requestId}/changemanagement/requestapproval`, arrayOfUserIds);
  }

  approve(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/approve`);
  }

  reject(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/reject`);
  }

  updateScheduleInfo(requestId, Planning_ScheduledStart, Planning_ScheduledEnd, Planning_DowntimeStart, Planning_DowntimeEnd, Requested_Date,Iscomplete:boolean){
    let body = {
      Planning_ScheduledStart: Planning_ScheduledStart,
      Planning_ScheduledEnd: Planning_ScheduledEnd,
      Planning_DowntimeStart: Planning_DowntimeStart,
      Planning_DowntimeEnd: Planning_DowntimeEnd,
      Requested_Date: Requested_Date,
      Iscomplete
    };
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/schedule`, body);
  }

  setScheduleComplete(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/schedulecomplete`);
  }

  editScheduleInfo(requestId){
    return this.apiService.put(`${this.requestApi}/${requestId}/changemanagement/editschedule`);
  }

}
