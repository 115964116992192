

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminCustomerService} from "../../../../../../services/api/admin/admin-customer-api/admin-customer-api.service";
import {ApiService} from "../../../../../../services/api/api.service";
import {DialogService} from "../../../../../../services/dialog-service/dialog.service";
import {AdminCustomersLocationsComponentDialog} from "../../locations/admin-customers-locations/admin-customers-locations-create-edit-dialog/admin-customers-locations-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../../shared/modal-confirmation/modal-confirmation.component";
import {ApiAdminCustomerLocationsService} from "../../../../../../services/api/admin/admin-customer-locations-api/admin-customer-locations-api.service";

@Component({
  selector: 'app-admin-customers-create-edit-dialog',
  templateUrl: './admin-customers-create-edit-dialog.component.html'
})

export class AdminCustomersComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingOptions = true;
  isLoadingSlaTemplates = true;

  // If LoadedId is set, it is an edit, not a create
  loadedId = null;
  showToast = false; // Property to control toast visibility
  selectedTab = 'details';

  loadedData;
  slaTemplates;
  options;

  domainToAdd = '';

  constructor(
    private apiAdminCustomerService: ApiAdminCustomerService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef,
    private apiAdminCustomerLocationsService: ApiAdminCustomerLocationsService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        Timezone: 'Europe/London',
        Domains: [],
        Monday: {
          Enabled: false,
          TwentyFour: null,
          Start: null,
          Finish: null
        },
        Tuesday: {
          Enabled: false,
          TwentyFour: null,
          Start: null,
          Finish: null
        },
        Wednesday: {
          Enabled: false,
          TwentyFour: null,
          Start: null,
          Finish: null
        },
        Thursday: {
          Enabled: false,
          TwentyFour: null,
          Start: null,
          Finish: null
        },
        Friday: {
          Enabled: false,
          TwentyFour: null,
          Start: null,
          Finish: null
        },
        Saturday: {
          Enabled: false,
          TwentyFour: null,
          Start: null,
          Finish: null
        },
        Sunday: {
          Enabled: false,
          TwentyFour: null,
          Start: null,
          Finish: null
        },
      }

      this.isLoading = false;

    }

    // Load Options
    this.apiAdminCustomerService.options().then(response => {
      this.options = response;

      if(!this.loadedId){
        this.loadedData.SlaLinks = response.BlankSlaLinks;
      }

      this.isLoadingOptions = false;
    })

    // Load SLA Templates
    // ToDo: Use the SLA Template service when it has been generated
    this.apiService.get('/api/admin/slatemplate').then(response => {
      this.slaTemplates = response;
      this.isLoadingSlaTemplates = false;
    })

  }

  setupIntuneSync(){
    this.apiAdminCustomerService.intuneSync(this.loadedId).then(response => {
      this.goToLink(response.intuneAuthoriseUrl, false);
    })
  }

  goToLink(url: string, sameWindow = false) {
    if (sameWindow) {
      window.open(url, '_self');
    } else {
      window.open(url, '_blank');
    }
  }

  updateDayData(Day) {

    if(!this.loadedData[Day].Enabled) {
      this.loadedData[Day].TwentyFour = null;
      this.loadedData[Day].Start = null;
      this.loadedData[Day].Finish = null;
    }

    if(this.loadedData[Day].Enabled && !this.loadedData[Day].TwentyFour) {
      this.loadedData[Day].TwentyFour = null;
      this.loadedData[Day].Start = null;
      this.loadedData[Day].Finish = null;
    }

    if(this.loadedData[Day].Enabled && !this.loadedData[Day].TwentyFour) {
      this.loadedData[Day].Start = '09:00';
      this.loadedData[Day].Finish = '17:00';
    }
  };

  addDomain() {
    if (!this.domainToAdd.trim()) {
      this.showToast = true;
      setTimeout(() => this.showToast = false, 3000); // Hide the toast after 3 seconds
    } else if (!this.loadedData.Domains.includes(this.domainToAdd)) {
      this.loadedData.Domains.push(this.domainToAdd);
      this.domainToAdd = ''; // Clear the input after adding
    }
  }

  removeDomain(index){
    this.loadedData.Domains.splice(index, 1);
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomerService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  createLocation(){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomersLocationsComponentDialog, {customerId: this.loadedId, locationId: null}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  editLocation(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomersLocationsComponentDialog, {customerId: this.loadedId, locationId: id}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  deleteLocation(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomerLocationsService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminCustomerService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminCustomerService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
