

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from "../../../../services/dialog-service/dialog.service";
import {ApiAdminAutomationService} from "../../../../services/api/admin/admin-automation-api/admin-automation-api.service";
import {AdminAutomationComponentDialog} from "./admin-automation-create-edit-dialog/admin-automation-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-automation',
  templateUrl: './admin-automation.html'
})
export class AdminAutomationComponent implements OnInit {

  data;
  isLoading = true;

  constructor(private apiAdminAutomationService: ApiAdminAutomationService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminAutomationService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminAutomationComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminAutomationComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminAutomationService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
