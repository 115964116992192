import { Injectable } from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  private dashboardStatsApi = "/api/dashboard/view/v2";
  private dashboardViewsApi = "/api/dashboard";

  constructor(private api: ApiService) { }

  getDashboardStats(){
    return this.api.get(this.dashboardStatsApi);
  }

  getDashboardTrend(){
    return this.api.get(this.dashboardStatsApi+'/trend');
  }

  getDashboardView(viewName, page=1, perPage=50, orderById = 29, orderByAsc=true){
    return this.api.get(`${this.dashboardViewsApi}/${viewName}?page=${page}&perPage=${perPage}&orderby=${orderById}&ascending=${orderByAsc}`);
  }

}
