import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminTypeSecurityDirectFeedsService {

  apiUri = '/api/admin/type/typeId/socfeed'

  constructor(private apiService: ApiService) {
  }

  listAll(typeId) {
    return this.apiService.get(`/api/admin/type/${typeId}/socfeed`);
  }

  get(typeId, id) {
    return this.apiService.get(`/api/admin/type/${typeId}/socfeed/${id}`);
  }

  delete(typeId, id) {
    return this.apiService.delete(`/api/admin/type/${typeId}/socfeed/${id}`);
  }

  create(typeId, data) {
    return this.apiService.post(`/api/admin/type/${typeId}/socfeed`, data);
  }

  update(typeId, id, data) {
    return this.apiService.put(`/api/admin/type/${typeId}/socfeed/${id}`, data);
  }
  
}



