<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

  <div (click)="create()" class="btn btn-primary float-end">Create</div>

  <div class="table align-middle tableStyle">
    <table class="table table-hover mails m-0 table table-actions-bar">
      <thead>
      <tr>
        <th>Basic / Advanced</th>
        <th>Description (Left to Right)</th>
        <th>Description (Right to Left)</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr  *ngFor="let x of this.data ">
        <td>
          <p *ngIf="!x.IsAdvanced">Basic</p>
          <p *ngIf="x.IsAdvanced">Advanced</p>
        </td>
        <td>
          <strong>  {{x.LeftType}}  <i *ngIf="!x.IsAdvanced" class="fas fa-arrows-alt-h"></i> </strong>{{x.LeftToRight}} <strong>{{x.RightType}}</strong>
        </td>
        <td>
          <div *ngIf="x.IsAdvanced">
            <strong>  {{x.RightType}}  <i *ngIf="!x.IsAdvanced" class="fas fa-arrows-alt-h"></i> </strong>{{x.RightToLeft}} <strong>{{x.LeftType}}</strong>
          </div>
        </td>

        <td>
          <i *ngIf="!x.ReadOnly" class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(x.Id)"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>





</div>
