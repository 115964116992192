import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})

export class ApiAdminAssetTypesService {

    constructor(private apiService: ApiService) {
    }
  
    listAll() {
      return this.apiService.get(`/api/asset-types`);
    }

    get(id){
        return this.apiService.get(`/api/asset-types/${id}`)
    }
  
    create( data) {
      return this.apiService.post(`/api/asset-types`, data);
    }

    update(id, data) {
        return this.apiService.put(`/api/asset-types/${id}`, data)
    }

    delete(id){
        return this.apiService.delete(`/api/asset-types/${id}`)
    }
  
  }
  