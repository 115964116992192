<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading || !isLoadingCustomers" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Asset</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row" *ngIf="(!this.data || !this.data.assetRequestType)">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Asset Number <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.AssetNo" type="text" class="form-control" id="AssetNo" placeholder="Leave blank to auto generate" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Status <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">

        <ng-select [items]="this.statusOptions"
                   bindLabel="Name"
                   bindValue="Id"
                   [(ngModel)]="loadedData.Status"
                   [clearable]="false">
        </ng-select>

      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Asset Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="this.assetTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.AssetTypeId"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>


    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Customer
      </div>
      <div class="col-sm p-10">
        <ng-select
          [items]="this.customerOptions"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="loadedData.CustomerId"
          [clearable]="true">
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Owner
      </div>
      <div class="col-sm p-10">

        <span *ngIf="!loadedData.OwnerId"><i class="fas fa-user icon-size-medium m-r-10" aria-hidden="true"></i> ...</span>
        <span *ngIf="loadedData.OwnerId"><i class="fas fa-user icon-size-medium m-r-10" aria-hidden="true"></i> {{loadedData.OwnerName}}</span>


        <div *ngIf="loadedData.OwnerId" (click)="loadedData.OwnerId = null; loadedData.OwnerName = null" style="
    width: 50px;
    height: 30px;
    padding-top: 8px;
        background-color: indianred;
" class="sdesk-right-button divLink vertical-center float-end">
          <i class="fas fa-user-times icon-size-medium"></i>
        </div>

        <div (click)="openUserSearchModal()" style="
    width: 50px;
    height: 30px;
    padding-top: 8px;
" class="sdesk-right-button divLink vertical-center float-end">
          <i class="fas fa-search icon-size-medium"></i>
        </div>

      </div>
    </div>


    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Manufacturer
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Manufacturer" type="text" class="form-control" id="Manufacturer" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Model
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Model" type="text" class="form-control" id="Model" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Serial Number
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.SerialNumber" type="text" class="form-control" id="SerialNumber" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        IMEI
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Imei" type="text" class="form-control" id="Imei" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Cost
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Cost" type="number" class="form-control" id="Cost" placeholder="" autocomplete="off">
      </div>
    </div>

    <div *ngFor="let customField of assetOptions.CustomFields" class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        {{customField.Name}} <span *ngIf="customField.Required" class="text-danger">*</span>
      </div>
      <div class="col p-10">
        <ng-container *ngIf="customField.FieldType === 2">
          <app-custom-field-dropdown
            (optionOutput)="setCustomFieldValue(customField, $event)"
            [customFieldId]="customField.CustomFieldId"
            [customFieldOptions]="customField.Options"
            [selectedLevelValues]="{ Level1SelectedId: null, Level2SelectedId: null,Level3SelectedId: null }">

          </app-custom-field-dropdown>
        </ng-container>
        <ng-container *ngIf="customField.FieldType === 1">
          <!-- Show textbox for FieldType 1 -->
          <input [(ngModel)]="customField.Value" type="text" class="form-control" [id]="'customField_' + customField.CustomFieldId" [placeholder]="'Enter ' + customField.Name" autocomplete="off" (input)="setCustomFieldValue(customField, $event.target.value)">
        </ng-container>

        <ng-container *ngIf="customField.FieldType === 3">
          <!-- Show textbox for FieldType 1 -->
          <input type="date" [id]="'customField_' + customField.CustomFieldId" [(ngModel)]="customField.Value"
                            class="form-control"  autocomplete="off" [placeholder]="'Enter ' + customField.Name" autocomplete="off" (input)="setCustomFieldValue(customField, $event.target.value)">
        </ng-container>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
