import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminCabService {

  constructor(private apiService: ApiService) {
  }

  listAll(typeId) {
    return this.apiService.get(`/api/admin/type/${typeId}/cab`);
  }

  get(typeId, id) {
    return this.apiService.get(`/api/admin/type/${typeId}/cab/${id}`);
  }

  delete(typeId, id) {
    return this.apiService.delete(`/api/admin/type/${typeId}/cab/${id}`);
  }

  create(typeId, data) {
    return this.apiService.post(`/api/admin/type/${typeId}/cab`, data);
  }

  update(typeId, id, data) {
    return this.apiService.put(`/api/admin/type/${typeId}/cab/${id}`, data);
  }


}



