import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class SearchApiService {

  private searchApiUri = '/api/search/request';
  private searchApiUri2 ='/api/search/agent-portal/request';

  constructor(private apiService: ApiService) { }

  newSearch(searchTerm, searchType){
    let body = {SearchTerm: searchTerm, SearchType: searchType}
    return this.apiService.post(this.searchApiUri, body);
  }

  customerPortalSearch(searchTerm, searchType){
    let body = {SearchTerm: searchTerm, SearchType: searchType}
    return this.apiService.post(this.searchApiUri2, body);
  }

}
