import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../dialog/dialog";
import {AgentUserService} from "../../services/api/agent-user/agent-user.service";
import {OptionsApiService} from '../../services/api/options/options-api.service';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-agent-search-modal',
  templateUrl: './agent-search-modal.component.html',
  styleUrls: ['./agent-search-modal.component.css']
})
export class AgentSearchModalComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  userList;
  selectedUserId = null;

  groupId = null;

  searchTerm = '';

  isLoading = true;

  constructor(private agentUserApi : AgentUserService, private optionsApi: OptionsApiService, private api: ApiService) {
    this.refreshUsers();
  }

  filteredUsers(){

    let filteredData  = this.userList;
    let searchTerm = this.searchTerm.toLocaleLowerCase();

    if(searchTerm){
      filteredData = filteredData.filter(user => {
        return user.name.toString().toLocaleLowerCase().includes(searchTerm)
      })
    }

    filteredData = filteredData.slice(0, 10);

    return filteredData;
  }

  refreshUsers() {
    this.isLoading = true;
    this.optionsApi.AllOptions().then(response => {
      this.userList = response.agent;
      this.isLoading = false;
    })
  }

  ngOnInit(): void {

    this.groupId = this.data;
  }

  closeModal(){
    console.log("Clicked Cancel...");
    this.remove.emit(null);
  }

  save(){

    this.api.post(`/api/admin/group-membership/${this.groupId}/user/${this.selectedUserId}`).then(response => {
      this.remove.emit(true);
    })

  }


}
