

import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ApiAdminGroupsService } from "../../../../../services/api/admin/admin-groups-api/admin-groups-api.service";
import { DialogService } from "../../../../../services/dialog-service/dialog.service";
import { AdminGroupsComponentDialog } from "./admin-groups-create-edit-dialog/admin-groups-create-edit-dialog.component";
import { ModalConfirmationComponent } from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-groups',
  templateUrl: './admin-groups.html',
  styleUrls: ['./admin-groups.css']
})
export class AdminGroupsComponent implements OnInit {

  data;
  isLoading = true;
  activeCount;
  inactiveCount;
  allCount;
  searchTerm;

  currentFilter;
  rows: number = 50;
  showingRowscount:number = 50;
  fromRowscount:number = 1;
  currentPage: number = 1;
  paginateddata;
  recordCount;
  first:number=0;

  constructor(private apiAdminGroupsService: ApiAdminGroupsService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getPaginatedData()
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.apiAdminGroupsService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
      this.updateCounts();
    })
  }

  updateCounts() {
    const filteredData = this.data.Result.filter(row => row.Enabled === 0 || row.Enabled === 1);
    this.allCount = filteredData.length;
    this.activeCount = this.data.Result.filter(row => row.Enabled === 1).length;
    this.inactiveCount = this.data.Result.filter(row => row.Enabled === 0).length;
  }

  getFilteredData() {

  let filteredData = this.data.Result.filter(row => row.Enabled !== 2);

  if (this.searchTerm) {
    filteredData = filteredData.filter(row =>
      row.Name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
  if (this.currentFilter === 'active') {
    filteredData = filteredData.filter(row => row.Enabled === 1);
  } 
  if (this.currentFilter === 'inactive') {
    filteredData = filteredData.filter(row => row.Enabled === 0);
  }
  return filteredData;
}

  getPaginatedData() {
    this.isLoading = true;
    let perPage = 50
    this.apiAdminGroupsService.GetPagination(this.currentPage,perPage).then(
      response => {
        this.paginateddata = response;
        this.recordCount = this.paginateddata.RecordCount;  
        this.isLoading = false;
      },
      error => {
        console.error('Failed to fetch data', error);
        this.isLoading = false;
      }
    );
  }


  create() {
    // Replace null with Component
    this.dialogService.createDialog(AdminGroupsComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        if(dialogSaved == "Saved"){
          this.getData();
          this.getPaginatedData();
        }
        
      }, dialogCancelled => {

      })
  }

  edit(id) {
    // Replace null with Component
    this.dialogService.createDialog(AdminGroupsComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        if(dialogSaved == "Saved"){
          this.getData();
          this.getPaginatedData();
        }
      }, dialogCancelled => {

      })
  }

  delete(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        if (confirmed){
          this.apiAdminGroupsService.delete(id).then(response => {
            this.getData();
            this.getPaginatedData();
        })
        }
        
      }, cancelled => null)
  }


  recover(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        if (confirmed){
        this.apiAdminGroupsService.recover(id).then(response =>{
          this.getData();
          this.getPaginatedData();
        } )
      }
      }, cancelled => null)
  }

  isLastPage(): boolean {
    return this.currentPage === Math.ceil(this.paginateddata.RecordCount / this.showingRowscount);
  }

  onPageChange(event)
  {
    let page = event.page + 1;
    let perpage = event.rows;
    this.showingRowscount = page * perpage;
    this.fromRowscount = ((page-1) * perpage)+1;
    this.apiAdminGroupsService.GetPagination(page,perpage).then(
      response => {
        this.paginateddata = response;       
        this.isLoading = false;
      },
      error => {
        console.error('Failed to fetch data', error);
        this.isLoading = false;
      }
    );
  }

}
