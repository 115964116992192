<div class="row">
  <div class="col-3">
    <div class="row filterContainer">
      <div class="col-lg-3">
        <div [routerLink]="['/agent/chat/conversation/unassigned']" [class.selected]="this.viewName == 'unassigned'" class="filterOption">Unassigned</div>
      </div>
      <div class="col-lg-3">
        <div [routerLink]="['/agent/chat/conversation/mine']" [class.selected]="this.viewName == 'mine'" class="filterOption">My Chats</div>
      </div>
      <div class="col-lg-3">
        <div  [routerLink]="['/agent/chat/conversation/all']"  [class.selected]="this.viewName == 'all'" class="filterOption">Open</div>
      </div>
      <div class="col-lg-3">
        <div  [routerLink]="['/agent/chat/conversation/closed']" [class.selected]="this.viewName == 'closed'"  class="filterOption">Closed</div>
      </div>
    </div>
<div class="chatSectionBox">


<!--  <div class="searchContainer">-->
<!--    <input type="text" class="searchTextBox" placeholder="Search...">-->
<!--  </div>-->



  <div class="chatListWindow">

    <div [routerLink]="['/agent/chat/', this.chatConfigId, this.viewName, conversation.RequestId]" *ngFor="let conversation of this.conversations" class="conversationListItem" [class.active]="this.requestId == conversation.RequestId" [class.unread]="conversation.UnreadMessageCount">
      <span style="font-size: 12px" class="float-end">
        {{conversation.LastMessageDateTime | amTimeAgo}}
      </span>

      <div style="font-weight: bold">{{conversation.RequesterName}} <span></span></div>

      <div *ngIf="!conversation.AssignedToAgentId" style="margin-right: -3px; margin-left: 5px; background-color: #c95300;color: white;/* font-weight: bold; */padding: 1px 10px;text-align: center;border-radius: 20px;font-size: 11px;" class="float-end">
        Unassigned
      </div>

      <div *ngIf="conversation.ClosedDateTime" style="margin-right: -3px; margin-left: 5px; background-color: #4b935e;color: white;/* font-weight: bold; */padding: 1px 10px;text-align: center;border-radius: 20px;font-size: 11px;" class="float-end">
        Closed
      </div>

      <div *ngIf="conversation.AssignedToAgentId" style="margin-right: -3px;margin-left: 5px;background-color: #e7e7e7;color: #595959;/* font-weight: bold; */padding: 1px 10px;text-align: center;border-radius: 20px;font-size: 11px;" class="float-end">
        <strong>Assigned:</strong> {{conversation.AgentName}}
      </div>


      <div style="font-size: 12px">{{conversation.RequesterEmailAddress}}</div>
    </div>

  </div>

</div>
  </div>
<div class="col">

  <app-request *ngIf="requestId" compactView="true"></app-request>

</div>
</div>
