import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../services/api/api.service";

@Component({
  selector: 'app-raise-a-bug-dialog',
  templateUrl: './raise-a-bug-dialog.component.html',
  styleUrls: ['./raise-a-bug-dialog.component.css']
})
export class RaiseABugDialogComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  bugInfo;

  constructor(public apiService: ApiService) {
  }

  ngOnInit(): void {
  }

  close() {
    this.remove.emit(null);
  }

  save() {

    // Add current page to bug Info
    let currentPage = window.location.href;
    let bugInfo = this.bugInfo + '</br>' + 'URL:' + currentPage;

    this.apiService.post('/api/bug', {BugInfo: bugInfo}).then(success => {

      this.remove.emit("Success");
    })

  }

}
