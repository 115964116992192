<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Please provide a reason to override the priority</h5>
  </div>
  <div class="modal-body">

    <input [(ngModel)]="reason" placeholder="..." type="text" class="form-control m-b-10" id="reason">

  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary" [class.disabled]="!reason">Override</div>
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
