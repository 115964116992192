<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div class="row" *ngIf="!isLoading">
  <div class="col-9">
    <nav aria-label="breadcrumb" style="margin-left: 15px; margin-top:10px;">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/solutions']">All solutions</a></li>
        <li *ngIf="categoryData" class="breadcrumb-item">
          <a [routerLink]="['/solutions']" (click)="filterByCategoryAndNavigate(categoryData.Id)" style="cursor: pointer;">{{ categoryData?.Name }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" style="color: black;">{{ articleData?.Title }}</li>
      </ol>
    </nav>
    <h3 class="p-20 p-b-0" style="font-weight: bold; color: black; margin-left: 15px;">
      <i class="fa fa-list" style="background-color:#EBEFF2; color: #355672;"></i>
      {{ articleData?.Title }}
    </h3>

    <h4 class="p-20 p-b-0" style="color: black; margin-top: 10px; margin-left: 45px; font-weight: bold;">Description</h4>
    <h6 [innerHTML]="sanitize(articleData?.Body)" class="custom-link" style="margin-left: 65px;"></h6>
  </div>

  <div class="col-3">
    <div style="border-left: 2px solid white; height: 500px; margin: 20px 0;">
      <div *ngIf="articleTags?.length > 0" class="tags-section">
        <h4 class="p-20 p-b-0" style="color: #355672">Related Tags</h4>
        <div style="display: flex; flex-wrap: wrap; margin-left: 20px;">
          <span *ngFor="let tag of articleTags" class="badge badge-primary" style="display: inline-block; background-color: #AEBBC6; color: #355672; border-radius: 5px; padding: 10px; margin: 5px; font-size: medium;">{{ tag?.Tag }}</span>
        </div>
      </div>
      <h4 class="p-20 p-b-0" style="color: #355672; margin-top: 30px;">Related Articles</h4>
      <div class="sectionBox" style="margin-left: 20px; margin-right: 20px;">
        <div [routerLink]="['/solutions/', article.Url]" *ngFor="let article of articleData?.RelatedArticles" class="solutionsHomeLink divLink">
          <i class="fas fa-chevron-right m-r-10"></i> {{ article?.Title }}
        </div>
      </div>
      <div style="margin-left: 20px; margin-top: 60px;">
        <i class="fas fa-link" (click)="copyLink()" style="cursor: pointer; color: #355672; font-size: 10px;"></i>
          Copy Link
        <span *ngIf="isCopied" style="margin-left: 10px; color: green;">Link copied!</span>
      </div>
    </div>
  </div>
</div>
