import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class RequestApiService {

  private requestApi = "/api/itil/request";

  constructor(private api: ApiService) { }

  create(data){
    return this.api.post(this.requestApi, data);
  }

  get(requestId){
    return this.api.get(`${this.requestApi}/${requestId}`);
  }

  saveTaskList(requestId, tasks){
    return this.api.put(`/api/saveTaskList/${requestId}`, {"Tasks":tasks});
  }

  getAuditLogs(requestId){
    return this.api.get(`${this.requestApi}/${requestId}/audit`);
  }

  delete(requestId){
    return this.api.delete(`${this.requestApi}/${requestId}`);
  }

  reOpen(requestId){
    return this.api.patch(`${this.requestApi}/${requestId}/reopen`);
  }

  getCannedResponseCompiled(requestId, cannedResponseId){
    return this.api.get(`${this.requestApi}/${requestId}/cannedresponse/${cannedResponseId}`);
  }

  updateAgent(requestId, agentId){
    return this.api.patch(`${this.requestApi}/${requestId}/updateagent/${agentId}`);
  }

  updateRequester(requestId, userId){
    return this.api.patch(`${this.requestApi}/${requestId}/updaterequester/${userId}`);
  }

  resolveChildTickets(requestId){
    return this.api.patch(`${this.requestApi}/${requestId}/resolve-child-tickets`);
  }

  updateGroup(requestId, groupId){
    return this.api.patch(`${this.requestApi}/${requestId}/updategroup/${groupId}`);
  }

  updatePriority(requestId, priorityId){
    return this.api.patch(`${this.requestApi}/${requestId}/updatepriority/${priorityId}`);
  }

  updateStatus(requestId, statusId){
    return this.api.patch(`${this.requestApi}/${requestId}/updatestatus/${statusId}`);
  }

  updateService(requestId, serviceId){
    return this.api.patch(`${this.requestApi}/${requestId}/updateservice/${serviceId}`);
  }

  updateCustomer(requestId, customerId){
    return this.api.patch(`${this.requestApi}/${requestId}/updatecustomer/${customerId}`);
  }

  updateCustomerLocation(requestId, customerLocationId){
    return this.api.patch(`${this.requestApi}/${requestId}/updatecustomerlocation/${customerLocationId}`);
  }

  updateType(requestId, requestTypeId){
    return this.api.patch(`${this.requestApi}/${requestId}/updatetype/${requestTypeId}`);
  }

  updateImpact(requestId, impactId){
    return this.api.patch(`${this.requestApi}/${requestId}/updateimpact/${impactId}`);
  }

  updateUrgency(requestId, urgencyId){
    return this.api.patch(`${this.requestApi}/${requestId}/updateurgency/${urgencyId}`);
  }

  overridePriority(requestId, reason){
    let requestBody = {
      Reason: reason
    }
    return this.api.patch(`${this.requestApi}/${requestId}/overridepriority/`, requestBody);
  }

  updateCustomField(requestId, customFieldId, customFieldOptionId){
    let requestBody = {
      Value: customFieldOptionId
    }
    return this.api.patch(`${this.requestApi}/${requestId}/customfield/${customFieldId}`, requestBody);
  }

  updateSubjectBody(requestId, subject, body){
    let requestBody = {
      Subject: subject,
      Body: body
    }
    return this.api.patch(`${this.requestApi}/${requestId}`, requestBody);
  }

  threadReply(requestId, text, attachmentArray, threadId, toAddress, forwardOriginalRequest = false, elapsedSeconds = null, updateChildTickets = false){
    let requestBody = {
      Text: text,
      Attachments: attachmentArray,
      ThreadId: threadId,
      ToAddress: toAddress,
      ForwardOriginalRequest: forwardOriginalRequest,
      SecondsElapsed: elapsedSeconds,
      UpdateChildTickets: updateChildTickets
    }
    return this.api.post(`${this.requestApi}/${requestId}/thread/reply`, requestBody);
  }

  threadNew(requestId, text, attachmentArray, threadId, NewEmailCCAddresses, forwardOriginalRequest = false, elapsedSeconds = null, updateChildTickets = false, toAddress){
    let requestBody = {
      Text: text,
      Attachments: attachmentArray,
      ThreadId: threadId,
      NewEmailCCAddresses:NewEmailCCAddresses,
      ForwardOriginalRequest: forwardOriginalRequest,
      SecondsElapsed: elapsedSeconds,
      UpdateChildTickets: updateChildTickets,
      ToAddress: toAddress,
    }
    return this.api.post(`${this.requestApi}/${requestId}/thread/reply`, requestBody);
  }

  threadComment(requestId, text, attachmentArray, elapsedSeconds = null){
    let requestBody = {
      Text: text,
      Attachments: attachmentArray,
      SecondsElapsed: elapsedSeconds
    }
    return this.api.post(`${this.requestApi}/${requestId}/thread/comment`, requestBody);
  }

  threadUpdateComment(requestId, updatedText, commentId){
    let requestBody = {
      Text: updatedText,
      RequestId: requestId,
      CommentId: commentId
    }
    return this.api.put(`${this.requestApi}/${requestId}/thread/comment/${commentId}`, requestBody);
  }

  threadDeleteComment(requestId, commentId){
    let requestBody = {
      RequestId: requestId,
      CommentId: commentId
    }
    return this.api.put(`${this.requestApi}/${requestId}/thread/delete/comment/${commentId}`, requestBody);
  }

  threadResolve(requestId, text, resolutionCodeId,IsResolvedEmailNotification, attachmentArray, elapsedSeconds = null){
    let requestBody = {
      Text: text,
      IsResolvedEmailNotification:IsResolvedEmailNotification,
      ResolutionCodeId: resolutionCodeId,
      Attachments: attachmentArray,
      SecondsElapsed: elapsedSeconds
    }
    return this.api.post(`${this.requestApi}/${requestId}/thread/resolution`, requestBody);
  }

  getThread(requestId){
    return this.api.get(`/api/v2/request/${requestId}/thread`);
  }

  resetUnreadCount(requestId, threadId){
    return this.api.post(`${this.requestApi}/${requestId}/thread/${threadId}/reset_unread`);
  }

  addCC(requestId, emailAddress){
    let requestBody = {EmailAddress: emailAddress}
    return this.api.post(`${this.requestApi}/${requestId}/cc`, requestBody);
  }

  removeCC(requestId, ccId){
    return this.api.delete(`${this.requestApi}/${requestId}/cc/${ccId}`);
  }

  toggleFollow(requestId){
    return this.api.post(`${this.requestApi}/${requestId}/togglefollow`);
  }

  mergeRequests(primaryRequestId, arrayOfRequestIds){
    let requestBody = {
      PrimaryRequestId: primaryRequestId,
      ArrayOfRequestIds: arrayOfRequestIds
    }
    return this.api.put(`${this.requestApi}/merge`, requestBody);
  }

  getVendorEscalateInfo(requestId){
    return this.api.get(`${this.requestApi}/${requestId}/vendorescalate`);
  }

  getRealTime(requestId){
    return this.api.get(`${this.requestApi}/realtime/${requestId}`);
  }

  setRealTime(requestId){
    return this.api.put(`${this.requestApi}/realtime/${requestId}`);
  }

  uploadTempAttachment(fileData){
    return this.api.post(`/api/tempattachment`, fileData, {
      'Content-Type': undefined
    });
  }

}
