

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminCannedResponsesService} from "../../../../services/api/admin/admin-canned-responses-api/admin-canned-responses-api.service";
import {AdminCannedResponsesComponentDialog} from "./admin-canned-responses-create-edit-dialog/admin-canned-responses-create-edit-dialog.component";
import {DialogService} from "../../../../services/dialog-service/dialog.service";
import {ModalConfirmationComponent} from "../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-canned-responses',
  templateUrl: './admin-canned-responses.html'
})
export class AdminCannedResponsesComponent implements OnInit {

  data;
  isLoading = true;

  constructor(
    private apiAdminCannedResponsesService: ApiAdminCannedResponsesService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCannedResponsesService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminCannedResponsesComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminCannedResponsesComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCannedResponsesService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
