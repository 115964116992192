import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../../dialog/dialog";

@Component({
  selector: 'app-ticket-bulk-update-merge-select-primary',
  templateUrl: './ticket-bulk-update-merge-select-primary.component.html',
  styleUrls: ['./ticket-bulk-update-merge-select-primary.component.css']
})
export class TicketBulkUpdateMergeSelectPrimaryComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  selectedTicketId = null;

  constructor() { }

  ngOnInit(): void { }

  closeModal(){
    this.remove.emit(null);
  }

  save(){
    this.remove.emit(this.selectedTicketId);
  }

}
