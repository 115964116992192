import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class RootApiService {

  private rootApi = '/api/root/instances';

  constructor(private api: ApiService) { }

  getInstances = () => this.api.get(`${this.rootApi}`);
  getLoginJwt = instanceId => this.api.post(`/api/root/login-to-instance/${instanceId}`);

}
