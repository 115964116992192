import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {OptionsApiService} from "../api/options/options-api.service";
import {InstanceParamsApiService} from "../api/instance-params/instance-params-api.service";

@Injectable({
  providedIn: 'root'
})
export class InstanceParamsService {

  public instanceParamsSubscription = new BehaviorSubject<any>(null);

  constructor(private apiInstanceParams: InstanceParamsApiService) {
    this.refreshInstanceParams();
  }

  refreshInstanceParams() {
    this.apiInstanceParams.getParams().then(response => {
      this.instanceParamsSubscription.next(response)
    }).catch(error => {
    })
  }
}
