import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminTypeSecurityDirectFeedsService} from "../../../../../../services/api/admin/admin-soc-direct-feeds-api/admin-soc-direct-feeds-api.service";
import {OptionsApiService} from "../../../../../../services/api/options/options-api.service";

@Component({
  selector: 'app-admin-soc-direct-feeds-create-edit-dialog',
  templateUrl: './admin-soc-direct-feeds-create-edit-dialog.component.html'
})

export class AdminSocDirectFeedsComponentDialog implements Dialog {

  @Input() data; // {id: 122, requestTypeId: 12}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingOptions = true;

  loadedId = null;
  requestTypeId = null;

  loadedData;
  options;

  constructor(
    private apiAdminTypeSecurityDirectFeedsService: ApiAdminTypeSecurityDirectFeedsService,
    private apiAdminOptionsService: OptionsApiService
  ) {
  }


  ngOnInit(): void {
    this.requestTypeId = this.data.requestTypeId;

    if (this.data.id !== null) {
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        FeedUrl: '',
        Enabled: 1,
        DefaultAgentId: null,
        DefaultCustomerId: null,
        DefaultImpactId: null,
        DefaultPriorityId: null,
        DefaultServiceId: null,
        DefaultStatusId: null,
        DefaultSupportGroupId: null,
        DefaultUrgencyId: 0
      }

      this.isLoading = false;
      this.refreshOptions();

    }


  }

  changeSupportGroup() {
    this.loadedData.DefaultAgentId = null;
  }

  refreshOptions() {
    this.isLoadingOptions = true;
    if (this.requestTypeId) {
      this.apiAdminOptionsService.DynamicOptions(
        this.requestTypeId,
        this.loadedData.DefaultSupportGroupId,
        this.loadedData.DefaultCustomerId
      ).then(response => {
        this.options = response;
        this.isLoadingOptions = false;
      })
    }
  }

  getData() {
    this.isLoading = true;
    this.apiAdminTypeSecurityDirectFeedsService.get(this.requestTypeId, this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
      this.refreshOptions();
    })
  }

  close() {
    this.remove.emit(null);
  }

  save() {
    if (this.loadedId) {
      this.apiAdminTypeSecurityDirectFeedsService.update(this.requestTypeId, this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminTypeSecurityDirectFeedsService.create(this.requestTypeId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
