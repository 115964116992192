import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestChangeService} from "../../../../../services/api/request/request-change/request-change.service";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-app-request-change-approval-add-members-dialog',
  templateUrl: './app-request-change-approval-add-members-dialog.component.html',
  styleUrls: ['./app-request-change-approval-add-members-dialog.component.css']
})
export class AppRequestChangeApprovalAddMembersDialogComponent implements OnInit {

  @Input() data; // RequestDetails
  @Output() remove = new EventEmitter<any>();

  public selectedMembers = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  cabBoards;
  selectedCabBoardId;
  cabMembers: [any];
  loadingCabMembers = false;
  searchTerm='';

  constructor(private changeApi: RequestChangeService) { }

  ngOnInit(): void {
    this.changeApi.getCabBoards(this.data.Id).then(response => this.cabBoards = response);
  }

  checkboxIsAllSelected(){
    return this.selectedMembers.selected.length == (this.cabMembers).length;
  }

  checkboxToggleAll(){
    if(this.checkboxIsAllSelected()){
      this.cabMembers.forEach(member => {
        this.selectedMembers.deselect(member.Id);
      })
    } else {
      this.cabMembers.forEach(member => {
        this.selectedMembers.select(member.Id);
      })
    }
  }

  refreshCabMembers(){
    this.loadingCabMembers = true;
    if(this.selectedCabBoardId){
      this.changeApi.getCabBoardMembers(this.data.Id, this.selectedCabBoardId).then(response => {
        this.cabMembers = response;
        this.loadingCabMembers = false;
      })
    }
  }

  getListOfMembers(){
    let selected = this.selectedMembers.selected;
    let arrayOfUsers = this.cabMembers.filter(member => selected.includes(member.Id));
    return arrayOfUsers;
  }
  searchCab(){
    this.loadingCabMembers = true;
    this.changeApi.searchCabBoards(this.data.Id, this.searchTerm).then(response => {
      this.cabBoards = response
      this.loadingCabMembers = false;

    })
  }

  cancelSearch(){
    this.searchTerm = '';
    this.loadingCabMembers = true;
    this.changeApi.getCabBoards(this.data.Id).then(response => {
      this.cabBoards = response
      this.loadingCabMembers = false;
    });

  }

  close(){
    this.remove.emit(null);
  }

  save(){

    this.changeApi.requestAddCabMembers(this.data.Id, this.getListOfMembers()).then(
      response => {
        this.remove.emit("RefreshParent");
      }
    )
  }

}
