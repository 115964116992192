<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-xlarge">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Task List</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="p-10 m-t-20 p-b-0">
      <h4>Tasks</h4>
      <hr>

    </div>

    <div class="row">
      <div class="col-sm p-10">
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let domain of loadedData.Tasks; let i = index">

            <div class="row mb-2">
              <div style="width: 250px; padding-top: 6px;">Task Name</div>
              <div class="col">
                <input [(ngModel)]="domain.TaskName" type="text" class="form-control" placeholder="" autocomplete="off">
              </div>
              <div style="width: 50px; padding-top: 8px;">
                <i class="far fa-trash-alt float-end m-r-20 fa-12x divLink" (click)="removeFromArray(i)" aria-hidden="true"></i>
              </div>

              <div class="row mb-2 mt-2">
                <div style="width: 250px; padding-top: 6px;">Instructions</div>
                <div class="col">
                  <quill-editor [(ngModel)]="domain.Instructions" [styles]="{height: '100px'}" placeholder=""></quill-editor>
                </div>
                <div style="width: 50px; padding-top: 8px;">
                </div>
              </div>
              <div class="row mb-2">
                <div style="width: 250px; padding-top: 6px;">Group</div>
                <div class="col">
                  <ng-select [items]="options?.group"
                             bindLabel="name"
                             bindValue="id"
                             [(ngModel)]="domain.GroupId"
                             (ngModelChange)="filterTaskAgents(domain)"
                             [clearable]="true"
                             
                             [disabled]="false">
                  </ng-select>
                </div>
                <div style="width: 50px; padding-top: 8px;">
                </div>
              </div>
              <!-- <div class="row mb-2">
                <div style="width: 250px; padding-top: 6px;">Enable group notification</div>
                <div class="col">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="domain.IsEmailEnabled">              
                </div>
                
              </div> -->
              <div class="row mb-2">
                <div style="width: 250px; padding-top: 6px;">Assign To</div>
                <div class="col">
                  <ng-select [items]="domain.optionAgents"
                             bindLabel="name"
                             bindValue="id"
                             [(ngModel)]="domain.AgentId"
                             [clearable]="true"
                             [disabled]="!domain.GroupId">
                  </ng-select>
                </div>
                <div style="width: 50px; padding-top: 8px;">
                </div>
              </div>


              <div class="row">
                <div style="width: 250px; padding-top: 6px;">E-mail requester on completion</div>
                <div class="col">
                  <input class="form-check-input mb-2" type="checkbox" [(ngModel)]="domain.SendCompletionEmail">
                  <quill-editor *ngIf="domain.SendCompletionEmail" [(ngModel)]="domain.CompletionEmailContents" [styles]="{height: '100px'}" placeholder=""></quill-editor>
                  <app-template-syntax *ngIf="domain.SendCompletionEmail"></app-template-syntax>
                </div>
                <div style="width: 50px; padding-top: 8px;">
                </div>
              </div>



            </div>


          </li>
        </ul>

        <div class="input-group m-t-20">
          <input [(ngModel)]="taskToAdd" type="text" class="form-control" id="domainToAdd" placeholder="Add new task" autocomplete="off">
          <div (click)="addToArray()" class="btn btn-primary m-0">Add</div>
        </div>

      </div>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
