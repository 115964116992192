<h3 _ngcontent-cre-c186="" class="p-20 p-b-0">My Support Tickets</h3>

<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<!-- <div *ngIf="!isLoading" class="sectionBox p-0">
  <div class="table-responsive">
    <table class="table align-middle tableStyle">
      <thead>
      <tr>
        <th class="">Id</th>
        <th class="">Requester</th>
        <th class="col-sm-5">Subject</th>
        <th class="">Status</th>
        <th class="">Priority</th>
        <th class="">Created</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let request of this.ticketData" class="divLink">
        <td [routerLink]="['/ticket/', request.Id]">{{request.LocalRequestId}}</td>
        <td [routerLink]="['/ticket/', request.Id]">{{request.Requester}}</td>
        <td [routerLink]="['/ticket/', request.Id]">{{request.Subject}}</td>
        <td [routerLink]="['/ticket/', request.Id]">{{request.Status}}</td>
        <td [routerLink]="['/ticket/', request.Id]">{{request.Priority}}</td>
        <td [routerLink]="['/ticket/', request.Id]"><app-friendly-time [utcTimeData]="request.date_browser"></app-friendly-time></td>
        <td>
          <span *ngIf="request.NextSlaPaused" class="label m-0 SDeskSlaIndicator-Paused">Paused</span>
          <span *ngIf="!request.NextSlaPaused && request.NextSlaDueDateTime" [className]="request.NextSlaOverdue? 'SDeskSlaIndicator-Red' : 'SDeskSlaIndicator-Green'" class="label m-0"><app-friendly-time [utcTimeData]="request.NextSlaDueDateTime"></app-friendly-time></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>-->
<div class="row">
  <div class="col-md-12">
    <div *ngIf="!isLoading" class="sectionBox p-0">
      <p-table class="support-tickets" [value]="ticketData" [tableStyle]="{'min-width': '60rem'}" >
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="LocalRequestId" style="width:18%">Id <p-sortIcon field="LocalRequestId"></p-sortIcon></th>
                <th pSortableColumn="Requester" style="width:18%">Requester <p-sortIcon field="Requester"></p-sortIcon></th>
                <th pSortableColumn="Subject" style="width:18%">Subject <p-sortIcon field="Subject"></p-sortIcon></th>
                <th pSortableColumn="Status" style="width:18%">Status <p-sortIcon field="Status"></p-sortIcon></th>
                <th pSortableColumn="Priority" style="width:18%">Priority <p-sortIcon field="Priority"></p-sortIcon></th>
                <th pSortableColumn="date_browser" style="width:18%">Created <p-sortIcon field="date_browser"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ticket>
            <tr [routerLink]="['/ticket/', ticket.Id]" class="route-row">
                <td>{{ticket.LocalRequestId}}</td>
                <td>{{ticket.Requester}}</td>
                <td>{{ticket.Subject}}</td>
                <td>{{ticket.Status}}</td>
                <td>{{ticket.Priority}}</td>
                <td><app-friendly-time [utcTimeData]="ticket.date_browser"></app-friendly-time></td>
                <td>
                  <span *ngIf="ticket.NextSlaPaused" class="label m-0 SDeskSlaIndicator-Paused">Paused</span>
                  <span *ngIf="!ticket.NextSlaPaused && ticket.NextSlaDueDateTime" [className]="ticket.NextSlaOverdue? 'SDeskSlaIndicator-Red' : 'SDeskSlaIndicator-Green'" class="label m-0"><app-friendly-time [utcTimeData]="ticket.NextSlaDueDateTime"></app-friendly-time></span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>
  </div>
</div>
