<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>New Ticket</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div id="selectRequestType">
      <p class="text-muted">Select the ticket type you would like to raise</p>
      <div class="list-group m-b-15 m-t-10">
        <a class="list-group-item divLink" *ngFor="let x of this.requestTypes" [class.active]="selectedRequestTypeId == x.Id" (click)="selectedRequestTypeId = x.Id">
          <h4 class="list-group-item-heading">{{x.Name}}</h4>
        </a>
      </div>
    </div>

    <div *ngIf="selectedRequestTypeId">
      <div class="row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Subject <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="requestSubject" type="text" class="form-control" id="Subject" placeholder="Enter Subject" autocomplete="off">

        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 formHeader p-10">
          Description <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <textarea rows="5" id="RequestBody" [(ngModel)]="requestBody" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
        </div>
      </div>
    </div>

    <div class="m-t-20">
      <button [disabled]="!selectedRequestTypeId || !requestSubject || !requestBody" (click)="save()" class="btn btn-primary">
        <span *ngIf="loading">Creating...</span>
        <span *ngIf="!loading">Create</span>
      </button>
      <div (click)="close()" class="btn btn-cancel">Close</div>

    </div>

  </div>
</app-blade-right>


