import { Injectable } from '@angular/core';
import {BehaviorSubject, timer} from 'rxjs';
import {ApiOnlineIndicatorService} from './api/agent/online-indicator/api-online-indicator.service';
import {AgentUserService} from './api/agent-user/agent-user.service';

@Injectable({
  providedIn: 'root'
})
export class UserSearchService {

  public userList = [];

  constructor(private agentUserApi : AgentUserService) {  }

  filteredUsers(searchTerm){

    searchTerm = searchTerm.toLowerCase();

    if(!searchTerm){
      return [];
    }

    let filteredData  = this.userList;


      filteredData = filteredData.filter(user => {
        return user.Name.toString().toLocaleLowerCase().includes(searchTerm) || user.EmailAddress.toString().toLocaleLowerCase().includes(searchTerm)
      })


    filteredData = filteredData.slice(0, 8);

    return filteredData;
  }

  refreshUsers() {
    this.agentUserApi.GetUsers().then(response => {
      this.userList = response["Result"];
    });
  }


}
