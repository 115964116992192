<highcharts-chart  *ngIf="showGraph"
  [Highcharts]="Highcharts"
  [constructorType]="chartConstructor"
  [options]="chartOptions"
  [callbackFunction]="chartCallback"
  [(update)]="updateFlag"
  [oneToOne]="oneToOneFlag"
  [runOutsideAngular]="runOutsideAngular"
  style="height: {{this.height}}; display: block;">

</highcharts-chart>
