import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.css']
})
export class SlaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
