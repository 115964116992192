<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3><i class="fad fa-stopwatch fa-15x" style="color: #43aa8b;"></i> Submit Time Entry</h3>
  </div>
  <hr>
  <div class="container p-10">


    <div class="row">
      <div class="col-2 p-5">
        <div class="col-1">Hours</div>
        <div class="col">  <input type="number" [(ngModel)]="hours" id="hours" name="hours" class="form-control"></div>
      </div>
<!--      <div class="col-1"></div>-->
      <div class="col-2 p-5">
        <div class="col-1">Minutes</div>
        <div class="col">  <input type="number" [(ngModel)]="minutes" id="minutes" name="minutes" class="form-control"></div>
      </div>
    </div>

    <div class="row p-t-10" style="padding-left: 5px;">
      <div class="row"><p>Comment</p></div>
      <div class="row">
        <textarea [(ngModel)]="comment" rows="5" id="comment" spellcheck="false" autocomplete="off" class="form-control" style="width: 100%;"></textarea>
      </div>
    </div>

    <div class="row p-t-10" style="padding-left: 5px;">
      <div class="row"><p>Customer</p></div>
      <div class="row">

        <ng-select [items]="customers"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="customerId"
                   [clearable]="true">
        </ng-select>

      </div>
    </div>

<!--    <div class="row">-->
<!--      <div class="col p-10">-->
<!--        <quill-editor [(ngModel)]="null" [styles]="{height: '450px'}" placeholder=""></quill-editor>-->
<!--      </div>-->
<!--    </div>-->

    <div class="m-t-20 m-l-5">
      <div (click)="save()" class="btn btn-primary">Submit</div>
    </div>

  </div>
</app-blade-right>
