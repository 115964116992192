import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {SelfServiceApiService} from "../../services/api/self-service/self-service-api.service";

@Component({
  selector: 'app-self-service-my-tickets',
  templateUrl: './self-service-my-tickets.component.html',
  styleUrls: ['./self-service-my-tickets.component.css']
})
export class SelfServiceMyTicketsComponent implements OnInit {

  constructor(private auth: AuthenticationService, private selfServiceApi: SelfServiceApiService) {
    // Check logged in because this is an authenticated page only
    this.auth.checkLoggedIn();
    this.getData();
  }

  ticketData = [];
  isLoading = true;

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.selfServiceApi.getMyRequests().then(response => {
      this.ticketData = response;
      this.isLoading = false;
    })
  }

}
