<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Azure AD Sync Configuration</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Description <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Description" type="text" class="form-control" id="Description" placeholder="Description" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Azure TenantId <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Azure_TenantId" type="text" class="form-control" id="Azure_TenantId" placeholder="" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Azure ClientId <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Azure_ClientId" type="text" class="form-control" id="Azure_ClientId" placeholder="" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Azure Client Secret <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Azure_ClientSecret" type="password" class="form-control" id="Azure_ClientSecret" placeholder="" autocomplete="new-password">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Domain to Sync <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Azure_DomainToSync" type="text" class="form-control" id="Azure_DomainToSync" placeholder="" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">

      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.OnlyCreateUsersThatHaveADepartment" class="form-check-input" id="private" type="checkbox"><label for="private" class="p-l-10">Only sync users that have a Department set</label>
      </div>
    </div>

    <hr/>

    <h4>How to obtain the Azure AD Details</h4>
    <ol>
      <li>Login to Azure Active Directory</li>
      <li>App Registrations -> New Registration -> Set the description to "SDesk AD Sync" and use the default options</li>
      <li>Locate the Directory (tenant) ID and input it above</li>
      <li>Locate the  Application (client) ID and input it above</li>
      <li>Go to Certificates & secrets, create a new client secret and input the secret above</li>
      <li>Go to Api Permissions -> Add a permission > Microsoft Graph > Application Permissions</li>
      <li>Assign the User.Read.All permission</li>
      <li>Once added, click "Grant Admin consent for SDesk"</li>
    </ol>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
