import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ApiAdminCannedResponsesService
} from '../../../../../services/api/admin/admin-canned-responses-api/admin-canned-responses-api.service';
import {OptionsApiService} from '../../../../../services/api/options/options-api.service';
import {ApiAdminChatConfig} from '../../../../../services/api/admin/chat-api/admin-chat-api';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {QuillEditorComponent} from 'ngx-quill';

@Component({
  selector: 'app-admin-chat-config-dialog',
  templateUrl: './admin-chat-config-dialog.component.html',
})
export class AdminChatConfigDialogComponent {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  options;

  loadedData;

  constructor(
    private apiAdminChatConfig: ApiAdminChatConfig,
    private optionsService: OptionsApiService
  ) {

  }

  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        UseMultiTenantBot: true,
        AzureAdAppId: '',
        AzureAdAppSecret: '',
        ConversationOpenedMessage: '',
        ConversationResolvedMessage: '',
        FeedbackMessage: '',
        AllowReopenHours: 24,
      }

      // Get options on create
      this.optionsService.AllOptions().then(response => {
        this.options = response;
        this.isLoading = false;
      })

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminChatConfig.get(this.loadedId).then(response => {
      this.loadedData = response;

      this.optionsService.AllOptions().then(response => {
        this.options = response;
        this.isLoading = false;
      })

    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminChatConfig.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminChatConfig.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
