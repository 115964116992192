import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AdminAuditLogApiService } from 'src/app/services/api/admin/admin-audit-log-api/admin-audit-log-api.service';
import { DialogService } from '../../../../services/dialog-service/dialog.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css']
})
export class AuditLogComponent implements OnInit {
  data: any[] = [];
  sortedData: any[] = [];
  isLoading = true;
  sortField = 'AuditDateTime'; // Default sorting field
  sortDirection = 'asc';
  recordCount
  first:number=0;
  searchText = ''
  isSearchDivVisible: boolean = false;
  rows: number = 50;
  showingRowscount:number = 50;
  fromRowscount:number = 1;
  currentPage: number = 1;

  searchType = [
    {
      "value":"Users",
      "name":"Users",
    },
  ];

  public defaultSearchType = "Users"

  constructor(
    private apiAdminAuditLogApiService: AdminAuditLogApiService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.apiAdminAuditLogApiService.listAll(this.currentPage, this.showingRowscount).then((response) => {
      this.data = response["Result"];
      this.recordCount = response["RecordCount"]
      this.sortData();
      this.isLoading = false;
    });
  }

  onPageChange(event) {
    let page = event.page + 1;
    let perpage = event.rows;
    this.showingRowscount = page * perpage;
    this.fromRowscount = ((page - 1) * perpage) + 1;
  
    // Check if searchText is not empty
    if (this.searchText && this.searchText.length > 0) {
      // Call search API
      this.apiAdminAuditLogApiService.search(this.searchText, this.defaultSearchType,page, perpage).then(
        response => {
          this.data = response["Result"];
          this.recordCount = response["RecordCount"];
          this.sortData();
          this.isLoading = false;
        },
        error => {
          console.error('Failed to fetch search results', error);
          this.isLoading = false;
        }
      );
    } else {
      // Call list API
      this.apiAdminAuditLogApiService.listAll(page, perpage).then(
        response => {
          this.data = response["Result"];
          this.recordCount = response["RecordCount"];
          this.sortData();
          this.isLoading = false;
        },
        error => {
          console.error('Failed to fetch data', error);
          this.isLoading = false;
        }
      );
    }
  }
  

  toggleSearchDiv() {
    this.isSearchDivVisible = !this.isSearchDivVisible;
    this.searchText=''
    this.getData()
  }


  searchAuditLog(){
    this.isLoading = true;
    this.apiAdminAuditLogApiService.search(this.searchText,this.defaultSearchType,1, 50 ).then((response) => {
        this.data = response["Result"];
        this.recordCount = response["RecordCount"]
        this.sortData()
        this.isLoading = false;
    }
  ).catch((err)=>{
         console.error('Failed to fetch data', err);
        this.isLoading = false;
  })
  }

  toggleSortDirection(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }
    this.sortData();
  }

  sortData() {
    this.sortedData = [...this.data].sort((a, b) => {
      let comparison = 0;
      // Handle sorting based on the field type
      if (typeof a[this.sortField] === 'string' && typeof b[this.sortField] === 'string') {
        comparison = a[this.sortField].localeCompare(b[this.sortField]);
      } else if (a[this.sortField] < b[this.sortField]) {
        comparison = -1;
      } else if (a[this.sortField] > b[this.sortField]) {
        comparison = 1;
      }
      return this.sortDirection === 'asc' ? comparison : -comparison;
    });
  }
}
