<div class="row m-t-20">
  <div class="col-12">

    <ul class="nav m-b-5">
      <li class="nav-item divLink" [routerLink]="['/agent/admin/sla/templates']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          SLA Templates
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['/agent/admin/sla/holidays']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          SLA Holidays
        </a>
      </li>

    </ul>
  </div>
  <div class="col-12 sectionBox">
    <div class="m-t-20">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
