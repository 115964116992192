<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-paperclip m-r-5"></i> Ticket Links</h5>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
        <table class="table align-middle tableStyle">
          <thead>
          <tr>
            <th>Id</th>
            <th>Subject</th>
            <th>Link Type</th>
            <th>Link Description</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let x of this.data.Links" ng-repeat="x in details.Links">
            <th scope="row">
              <a href="/agent/request/{{(x.LeftRequestId == this.data.Id) ? x.RightRequestId : x.LeftRequestId }}">
                {{(x.LeftRequestId == this.data.Id) ? (x.RightAssetNo || x.RightRequestLocalId) : x.LeftRequestLocalId}}
              </a>
            </th>
            <td>
              <a href="/agent/request/{{(x.LeftRequestId == this.data.Id) ? x.RightRequestId : x.LeftRequestId }}">
                {{(x.LeftRequestId == this.data.Id) ? x.RightSubject : x.LeftSubject}}
              </a>
            </td>
            <td>
              {{(x.LeftRequestId == this.data.Id) ? x.Right_Name : x.Left_Name}}
            </td>
            <td>
              {{x.IsAdvanced ? ((x.LeftRequestId == this.data.Id) ? x.RightToLeft :
              x.LeftToRight) : 'Linked to'}} this {{(x.LeftRequestId == this.data.Id) ?
              x.Left_Name : x.Right_Name}}
            </td>
            <td class="divLink" (click)="removeLink(x.Id)">Delete</td>
          </tr>
          </tbody>
        </table>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Close</div>
  </div>
</app-modal>
