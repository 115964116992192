<app-modal [modalSize]="'modal-large'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Search Users</h5>
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
  </div>
  <div class="modal-body p-0">

    <div class="row" style="height: 100%">
      <div class="col-2" style="border-right: solid #dfdfdf 1px;background-color: rgba(235,239,242,0.91);">
        <div class="m-r-10">
          <!--          <div class="p-10 p-b-0">-->
          <!--            <h4>Filters</h4>-->
          <!--          </div>-->
          <!--          <hr>-->
          <div class="p-10 p-b-0">

            <h4><i class="fal fa-filter"></i> Company</h4>

            <ng-container *ngIf="filterCompanyOptions && filterCompanyOptions.length > 0">
              <app-sdesk-multi-select [data]="filterCompanyOptions" [(selectedData)]="filterCompanyNames"></app-sdesk-multi-select>
            </ng-container>

          </div>
          <hr>

          <div class="p-10 p-b-0">


            <h4><i class="fal fa-filter"></i> Office</h4>
            <ng-container *ngIf="filterOfficeOptions && filterOfficeOptions.length > 0">
              <app-sdesk-multi-select [data]="filterOfficeOptions" [(selectedData)]="filterOfficeNames"></app-sdesk-multi-select>
            </ng-container>
          </div>
          <hr>
          <div class="p-10 p-b-0">

            <h4><i class="fal fa-filter"></i> Department</h4>
            <ng-container *ngIf="filterDepartmentOptions && filterDepartmentOptions.length > 0">
              <app-sdesk-multi-select [data]="filterDepartmentOptions" [(selectedData)]="filterDepartmentNames"></app-sdesk-multi-select>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-10">
        <div class="">
          <div class="p-10">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input [(ngModel)]="searchTerm" (ngModelChange)="resetPage()" class="form-control" name="searchTerm" placeholder="Search...">
          </div>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

          <div *ngIf="!isLoading">
            <div class="table-responsive">
              <table class="table align-middle tableStyle m-b-5">
                <thead>
                <tr>
                  <th class="col-1">

                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <!--                        <i class="far fa-chevron-down"></i>-->
                      </a>

                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <a class="dropdown-item divLink" (click)="selectAllVisible()">Select Visible ({{this.filteredUsersThisPage().length}})</a>
                        </li>
                        <li>
                          <a class="dropdown-item divLink" (click)="selectAll()">Select All ({{this.filteredUsers().length}})</a>
                        </li>
                        <li>
                          <a class="dropdown-item divLink" (click)="clearSelection()">Clear Selection</a>
                        </li>
                      </ul>
                    </div>
                    <!--                    <div class="form-check">-->
                    <!--                      <input [ngModel]="this.checkboxIsAllSelected()" (change)="this.checkboxToggleAll()"-->
                    <!--                             class="form-check-input" type="checkbox" id="flexCheckDefault">-->
                    <!--                      <label class="form-check-label" for="flexCheckDefault"></label>-->
                    <!--                    </div>-->
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Customer</th>
                  <th>Office</th>
                  <th>Department</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of this.filteredUsersThisPage()" class="divLink">
                  <td><input [ngModel]="this.selected.isSelected(user.Id)"
                             (change)="this.selected.toggle(user.Id)" class="form-check-input" type="checkbox"
                             id="filterViewTable">
                    <label class="form-check-label" for="flexCheckDefault"></label></td>
                  <td>{{user.Name}}</td>
                  <td>{{user.EmailAddress}}</td>
                  <td>{{user.CustomerName}}</td>
                  <td>{{user.Office}}</td>
                  <td>{{user.Department}}</td>

                </tr>
                </tbody>
              </table>
            </div>
            <ul *ngIf="this.totalPages > 1" class="pagination m-b-0 float-end">
              <li class="page-item">
                <a class="page-link divLink" (click)="pageBackwards()" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link divLink" (click)="pageForward()" ria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
            <p class="text-muted text-center m-b-10">
              {{this.filteredUsersThisPage().length}} of {{this.totalFilteredResults}} results - page {{this.page}} of {{this.totalPages}}
            </p>

          </div>


        </div>
      </div>

    </div>


  </div>
  <div class="modal-footer">
    <h4 style="margin-right: 40px">Selected: {{selected.selected.length}}</h4>

    <div (click)="save()" class="btn btn-primary" [class.disabled]="!this.selected.selected.length">Add Subscribers</div>
    <div (click)="close()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
