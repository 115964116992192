import { Injectable } from '@angular/core';
import { ApiService } from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  filterApi = "/api/filter";
  viewApi = "/api/view";

  constructor(private api: ApiService) { }

  createFilter(data){
    return this.api.post(this.filterApi, data);
  }

  listFilters(requestType = null){
    if(requestType){
      return this.api.get(`${this.filterApi}/requesttype/${requestType}`);
    } else {
      return this.api.get(this.filterApi);
    }
  }

  executeFilter(filterId, orderById = undefined, ascending = undefined, pageNo = undefined, resultsPerPage = undefined, searchTerm = undefined, searchType=undefined){

    let params = {
      'orderby': orderById ?? '',
      'ascending': ascending ?? '',
      'page': pageNo ?? '',
      'results': resultsPerPage ?? ''
    }

    // Add searchTerm and searchType to params if they have values
    if (searchTerm !== undefined && searchType !== undefined) {
      params['searchTerm'] = searchTerm;
      params['searchType'] = searchType;
  }

    return this.api.get(`${this.filterApi}/${filterId}`, params);

  }

  downloadFilter(filterId, orderById = undefined, ascending = undefined, pageNo = undefined, resultsPerPage = undefined, searchTerm = undefined, searchType=undefined){

    let params = {
      'orderby': orderById ?? '',
      'ascending': ascending ?? '',
      'page': pageNo ?? '',
      'results': resultsPerPage ?? ''
    }

    // Add searchTerm and searchType to params if they have values
    if (searchTerm !== undefined && searchType !== undefined) {
      params['searchTerm'] = searchTerm;
      params['searchType'] = searchType;
  }
    return this.api.downloadInChunks(`${this.filterApi}/${filterId}/download`,'get', null,params);

  }

  executeView(viewName, requestTypeId, orderById = null, ascending = null,page:number,perpage:number, ticketType= null){

    let params = {
      'orderby': orderById ?? '',
      'ascending': ascending ?? '',
      'page': page ?? '',
      'perPage': perpage ?? ''

    };

    // Build the API Url
    let apiUrl = `${this.viewApi}/${viewName}`
    if(requestTypeId){
      apiUrl += `/requesttype/${requestTypeId}`;
    }
    if(viewName==='myrequests'){
      params['by'] = ticketType ? ticketType : 'all';
    }

    return this.api.get(apiUrl, params);
  }

  GetPagination(viewName:any,page:number,perpage:number){
    let apiUrl = `${this.viewApi}/${viewName}`
    let params = {
      'page': page ?? '',
      'perPage': perpage ?? ''
    };
    return this.api.get(apiUrl, params);
  }

  updateFilter(id, data){
    return this.api.put(`${this.filterApi}/${id}`, data);
  }

  getExistingFilterValues(id){
    return this.api.get(`${this.filterApi}/${id}/values/v2`);
  }

  deleteFilter(id){
    return this.api.delete(`${this.filterApi}/${id}`);
  }

  updateSchedulingReports(id, data){
    return this.api.patch(`${this.filterApi}/${id}/schedulereport`, data);
  }
}
