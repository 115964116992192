import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AgentUserService} from "../../../../../services/api/agent-user/agent-user.service";
import {SelectionModel} from "@angular/cdk/collections";
import {OptionsApiService} from "../../../../../services/api/options/options-api.service";
import {ApiMassCommunicationService} from "../../../../../services/api/mass-communication/mass-communication-api/mass-communication-api.service";

@Component({
  selector: 'app-mass-communication-add-subscribers',
  templateUrl: './mass-communication-add-subscribers.component.html',
  styleUrls: ['./mass-communication-add-subscribers.component.css']
})
export class MassCommunicationAddSubscribersComponent implements OnInit {


  @Input() data;
  @Output() remove = new EventEmitter<any>();

  public isLoading = true;
  public userList;

  public searchTerm = '';
  public filterCompanyNames = [];
  public filterOfficeNames = [];
  public filterDepartmentNames = [];

  public filterCompanyOptions = [];
  public filterOfficeOptions = [];
  public filterDepartmentOptions = [];

  public page = 1;
  public totalPages = null;
  public totalFilteredResults = null;

  public selected = new SelectionModel(true, []);

  public dropdownSettings = {
    singleSelection: false,
    text: "Select",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    lazyLoading: true,
    classes: "sdesk-multi-select",
    labelKey: 'name',
  };

  constructor(private agentUserApi: AgentUserService, private optionsApi: OptionsApiService, private apiMassCommunicationService: ApiMassCommunicationService) {
    this.refreshUsers();
  }

  filteredUsersThisPage() {
    const startNumber = (this.page - 1) * 15;
    const EndNumber = (this.page) * 15;

    return this.filteredUsers().slice(startNumber, EndNumber);
  }

  filteredUsers() {

    let filteredData = this.userList;
    let searchTerm = this.searchTerm.toLocaleLowerCase();

    if (searchTerm) {
      filteredData = filteredData.filter(user => {
        return user.Name.toString().toLocaleLowerCase().includes(searchTerm) || user.EmailAddress.toString().toLocaleLowerCase().includes(searchTerm)
      })
    }

    const companyUniqueValues: Array<any> = [...new Set(this.filterCompanyNames.map(row => row.name))];
    const officeUniqueValues: Array<any> = [...new Set(this.filterOfficeNames.map(row => row.name))];
    const departmentUniqueValues: Array<any> = [...new Set(this.filterDepartmentNames.map(row => row.name))];

    if (companyUniqueValues.length > 0) {
      filteredData = filteredData.filter(users => companyUniqueValues.includes(users.CustomerName));
    }

    if (officeUniqueValues.length > 0) {
      filteredData = filteredData.filter(users => officeUniqueValues.includes(users.Office));
    }

    if (departmentUniqueValues.length > 0) {
      filteredData = filteredData.filter(users => departmentUniqueValues.includes(users.Department));
    }

    this.totalPages = Math.ceil(filteredData.length / 15);
    this.totalFilteredResults = filteredData.length;

    return filteredData;
  }


  selectAllVisible() {
    this.filteredUsersThisPage().forEach(user => {
      this.selected.select(user.Id);
    })
  }

  selectAll() {
    this.filteredUsers().forEach(user => {
      this.selected.select(user.Id);
    })
  }

  clearSelection() {
    this.selected = new SelectionModel(true, []);
  }

  resetPage() {
    this.page = 1;
  }

  pageBackwards() {
    if (this.page == 1) {
      return;
    }
    this.page = this.page - 1;
  }

  pageForward() {
    if (this.page == this.totalPages) {
      return;
    }
    this.page = this.page + 1;
  }


  refreshUsers() {
    this.isLoading = true;
    this.agentUserApi.GetUsers().then(response => {
      this.userList = response.Result;
      this.isLoading = false;
      this.generateFilterOptions();
    });

  }

  generateFilterOptions() {

    // Total hack...
    const companyUniqueValues: Array<any> = [...new Set(this.userList.map(user => user.CustomerName))];
    this.filterCompanyOptions = [];
    companyUniqueValues.forEach(row => {
      // Dont add blank / null values...
      if (row) {
        this.filterCompanyOptions.push({id: row, name: row})
      }
    })

    const officeUniqueValues: Array<any> = [...new Set(this.userList.map(user => user.Office))];
    this.filterOfficeOptions = [];
    officeUniqueValues.forEach(row => {
      // Dont add blank / null values...
      if (row) {
        this.filterOfficeOptions.push({id: row, name: row})
      }
    })

    const departmentUniqueValues: Array<any> = [...new Set(this.userList.map(user => user.Department))];
    this.filterDepartmentOptions = [];
    departmentUniqueValues.forEach(row => {
      // Dont add blank / null values...
      if (row) {
        this.filterDepartmentOptions.push({id: row, name: row})
      }
    })

  }

  ngOnInit(): void {
  }

  close() {
    console.log("Clicked Cancel...");
    this.remove.emit(null);
  }

  save() {

    this.apiMassCommunicationService.addSubscribers(this.data, this.selected.selected).then(response => {
        this.remove.emit(true);
      }
    )
    console.log("Clicked Save...");
  }

}
