<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Category</h3>
  </div>
  <hr>
  <div class="container p-0">
    <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name"
          autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Description <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <textarea rows="5" id="description" [(ngModel)]="loadedData.Description" class="form-control"
          style="width: 100%;"></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Published
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Published" class="form-check-input" id="SlaBreak" type="checkbox"><label
          for="SlaBreak" class="p-l-10"></label>
      </div>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
      <div (click)="deleteCategory()" class="btn btn-cancel float-end">
        <i class="fas fa-trash m-r-5"></i>Delete
      </div>
    </div>

  </div>
</app-blade-right>