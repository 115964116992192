

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminAzureAdSyncService} from "../../../../../services/api/admin/admin-azure-ad-sync-api/admin-azure-ad-sync-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {AdminAzureAdComponentDialog} from "./admin-azure-ad-sync-create-edit-dialog/admin-azure-ad-sync-create-edit-dialog.component";

@Component({
  selector: 'app-admin-azure-ad-sync',
  templateUrl: './admin-azure-ad-sync.html'
})
export class AdminAzureAdComponent implements OnInit {

  data;
  isLoading = true;

  constructor(private apiAdminAzureAdSyncService: ApiAdminAzureAdSyncService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminAzureAdSyncService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminAzureAdComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminAzureAdComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminAzureAdSyncService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
