<div class="SDeskSidePanel-GreyBackground"></div>
 
<app-blade-right  *ngIf="!isLoading && !isLoadingRequestTypes" size="blade-xlarge">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Sla Template</h3>
  </div>
  <hr>
  <div class="container p-0">
 
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
 
      </div>
    </div>
 
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Request Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
 
        <!-- Cannot change requestType on edit -->
        <ng-select [items]="requestTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.TypeId"
                   (ngModelChange)="refreshOptions()"
                   [clearable]="false"
                   [disabled]="this.loadedId">
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Response SLA Stop Criteria <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
 
        <!-- Cannot change requestType on edit -->
        <ng-select [items]="responseCriteriaOptions"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.SLAResponseStopCriteria"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>
 
    <hr>
 
    <div class="alert alert-danger" *ngIf="!loadedData.TypeId">Please select a request type.</div>
 
    <div>
      <div class="alert alert-danger" *ngIf="(loadedData.Priorities).length < 1 && loadedData.TypeId">No Priorities defined. Please define them on the priorities tab before creating an SLA template.</div>
 
      <div *ngIf="(loadedData.Priorities).length >= 1">
        <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th>Priority</th>
          <th>Enabled</th>
          <th>Hours</th>
          <th>Timezone</th>
          <th>Allow Pause</th>
          <th>Allow Holidays</th>
          <th>Response (Hours:Minutes)</th>
          <th>Resolution (Hours:Minutes)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let x of loadedData.Priorities">
          <th scope="row">{{x.Name}}</th>
          <td>
            <input [(ngModel)]="x.Enabled" class="form-check-input" id="Enabled" type="checkbox"><label for="Enabled" class="p-l-10"></label>
 
          </td>
          <td>
            <ng-select [items]="workingHoursOptions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="x.WorkingHours"
                       [clearable]="false"
                       [disabled]="!x.Enabled">
            </ng-select>
          </td>
          <td>
            <ng-select [items]="timezoneOptions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="x.Timezone"
                       [clearable]="false"
                       [disabled]="!x.Enabled">
            </ng-select>
          </td>
          <td>
            <input [(ngModel)]="x.AllowPause" [disabled]="!x.Enabled" class="form-check-input" id="AllowPause" type="checkbox"><label for="AllowPause" class="p-l-10"></label>
          </td>
          <td>
            <input [(ngModel)]="x.AllowHoliday" [disabled]="!x.Enabled" class="form-check-input" id="AllowHoliday" type="checkbox"><label for="AllowHoliday" class="p-l-10"></label>
          </td>
          <td>
            <div class="row">
              <input [(ngModel)]="x.ResponseHours" [disabled]="!x.Enabled" size="2" type="number" class="form-control" id="ResponseHours" placeholder="" autocomplete="off" style="width: 75px;" (input)="validateInput($event)" >
              <input [(ngModel)]="x.ResponseMinutes" [disabled]="!x.Enabled" size="2" type="number" class="form-control" id="ResponseMinutes" placeholder="" autocomplete="off" style="width: 75px;" (input)="validateInput($event)" >
            </div>
          </td>
          <td>
            <div class="row">
              <input [(ngModel)]="x.ResolutionHours" [disabled]="!x.Enabled" size="2" type="number" class="form-control" id="ResolutionHours" placeholder="" autocomplete="off" style="width: 75px;" (input)="validateInput($event)" >
              <input [(ngModel)]="x.ResolutionMinutes" [disabled]="!x.Enabled" size="2" type="number" class="form-control" id="ResolutionMinutes" placeholder="" autocomplete="off" style="width: 75px;" (input)="validateInput($event)" >
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
    </div>
 
    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>
 
  </div>
</app-blade-right>