<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading && !isLoadingRequestTypes" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Webhook</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name"
               autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Webhook Url:
      </div>
      <div class="col-sm p-10">
        <span *ngIf="data.id">https://{{browserHostname}}/webhook/{{loadedData.WebHookUniqueIdentifier}}</span>
        <span *ngIf="!data.id">Webhook URL Will be visible after save</span>

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Enabled
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Enabled" class="form-check-input" id="Enabled" type="checkbox"><label
        for="Enabled" class="p-l-10"></label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Default Request Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">

        <!-- Cannot change requestType on edit -->
        <ng-select [items]="requestTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.RequestTypeId"
                   (ngModelChange)="changeRequestType()"
                   [clearable]="false"
                   [disabled]="this.loadedId">
        </ng-select>
      </div>
    </div>

    <hr>

    <div *ngIf="!isLoadingOptions">

      <div class="row">
        <div class="col-3"></div>
        <div class="col-4"><h4>Value Key</h4></div>
        <div class="col-5"><h4>Default Value</h4></div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Subject</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Subject" type="text" class="form-control" id="ValueKey_Subject"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <input [(ngModel)]="loadedData.Default_Subject" type="text" class="form-control" id="Default_Subject"
                 placeholder="" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Body</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Body" type="text" class="form-control" id="ValueKey_Body"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <input [(ngModel)]="loadedData.Default_Body" type="text" class="form-control" id="Default_Body" placeholder=""
                 autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Requester <span class="text-danger">*</span></div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Requester" type="text" class="form-control" id="ValueKey_Requester"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.requester"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_RequesterId">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Group</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Group" type="text" class="form-control" id="ValueKey_Group"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.group"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_GroupId"
                     (ngModelChange)="refreshOptions();"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Status</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Status" type="text" class="form-control" id="ValueKey_Status"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.status"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_StatusId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Customer</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Customer" type="text" class="form-control" id="ValueKey_Customer"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.customer"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_CustomerId"
                     (ngModelChange)="refreshOptions();"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Service</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Service" type="text" class="form-control" id="ValueKey_Service"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.service"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_ServiceId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row" *ngIf="options.impact">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Impact</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Impact" type="text" class="form-control" id="ValueKey_Impact"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.impact"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_ImpactId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row" *ngIf="options.urgency">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Urgency</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Urgency" type="text" class="form-control" id="ValueKey_Urgency"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.urgency"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_UrgencyId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div *ngIf="options.priority && !options.RequestTypeOptions.AutoPriority" class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">Default Priority</div>
        <div class="col-4">
          <input [(ngModel)]="loadedData.ValueKey_Priority" type="text" class="form-control" id="ValueKey_Priority"
                 placeholder="" autocomplete="off">
        </div>
        <div class="col-5">
          <ng-select [items]="options.priority"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Default_PriorityId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <hr>
      <div class="p-10">
        <h4>PowerShell Example:</h4>

        <pre style="color:#93a1a1;background-color:#002b36;-moz-tab-size:4;-o-tab-size:4;tab-size:4">
        <code class="language-powershell" data-lang="powershell">
  <span style="color:#586e75">## sDesk Powershell Example</span>
  <span style="color:#b58900">$webhook</span> = <span style="color:#2aa198">"{{data.id ? "https://" + browserHostname + "/webhook/" + loadedData.WebHookUniqueIdentifier : "Webhook URL Will be visible after save"}}"</span>
  <span style="color:#b58900">$subject</span> = <span style="color:#2aa198">"Ticket created using PowerShell!"</span>
  <span style="color:#b58900">$body</span> = <span style="color:#2aa198">"Ticket created using PowerShell!"</span>
  <span style="color:#b58900">$requesterEmailAddress</span> = <span style="color:#2aa198">"test&#64;sdesk.co.uk"</span>
  <span style="color:#b58900">$groupName</span> = <span style="color:#2aa198">"Support Group"</span>
  <span style="color:#b58900">$statusName</span> = <span style="color:#2aa198">"Open"</span>
  <span style="color:#b58900">$customerName</span> = <span style="color:#2aa198">""</span>
  <span style="color:#b58900">$serviceName</span> = <span style="color:#2aa198">""</span>
  <span style="color:#b58900">$priorityName</span> = <span style="color:#2aa198">"High"</span>

  <span style="color:#b58900">$postParams</span> = &#64;&#123;subject=$subject; body=$body;requesterEmailAddress=$requesterEmailAddress; groupName=$groupName; statusName=$statusName; customerName=$customerName; serviceName=$serviceName; priorityName=$priorityName; &#125;
  <span style="color:#b58900">$webhookResult</span> = Invoke-WebRequest -Uri $webhook -Method POST -Body ($postParams | ConvertTo-Json) -ContentType "application/json"

  <span style="color:#b58900">$ticketId</span> =  ($webhookResult.content | ConvertFrom-Json).Id
  <span style="color:#b58900">$ticketUrl</span> =  "{{"https://" + browserHostname + "/agent/request/"}}$ticketId"
        </code></pre>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
