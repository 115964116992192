
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminServicesService {

  apiUri = '/api/admin/service'

  constructor(private apiService: ApiService) {
  }

  listAll() {
    return this.apiService.get(this.apiUri);
  }

  get(id) {
    return this.apiService.get(`/api/v2/admin/service/${id}`);
  }

  options() {
    return this.apiService.get(`/api/v2/admin/service/options`);
  }

  delete(id) {
    return this.apiService.delete(`${this.apiUri}/${id}`);
  }

  create(data) {
    return this.apiService.post(this.apiUri, data);
  }

  update(id, data) {
    return this.apiService.put(`${this.apiUri}/${id}`, data);
  }


}



