<!--<div ng-show="ShowMICommunicationPanel" ng-cloak class="m-l-15">-->
<!--  <div  ng-repeat="x in details.MIThread">-->
<!--    <ul class="sortable-list taskList list-unstyled ui-sortable sectionBox">-->
<!--      <li class="task-info ui-sortable-handle ng-binding">-->
<!--        <span class="font-bold ng-binding">{{x.AgentName }}</span>-->
<!--        <div class="m-t-20">-->
<!--          <p class="pull-right m-b-0"><i class="fa fa-clock-o"></i> <span class="ng-binding"><app-friendly-time [utcTimeData]="x.date_browser"></app-friendly-time></span>-->
<!--          </p>-->
<!--          <p ng-bind-html="">{{x.UpdateText}}</p>-->

<!--          <i *ngIf="x.SentToSlack" ng-show="x.SentToSlack" class="fa fa-slack"></i>-->
<!--        </div>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
<!--</div>-->




<div class="col" *ngFor="let x of requestDetails.MIThread">
  <div class="threadItem outboundEmail">
            <span>
              <strong>{{x.AgentName }}</strong>
              <span class="float-end"><i class="far fa-clock m-r-5"></i> <app-friendly-time [utcTimeData]="x.date_browser" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time></span>
            </span>

    <div [innerHTML]="x.UpdateText"></div>

    <i *ngIf="x.SentToSlack" ng-show="x.SentToSlack" class="fa fa-slack"></i>

  </div>
</div>
