import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServicePopoutService} from "../../../../services/api/service-popout/service-popout.service";
import {InstanceParamsService} from "../../../../services/instance-params/instance-params.service";


@Component({
  selector: 'app-request-service-modal',
  templateUrl: './request-service-modal.component.html',
  styleUrls: ['./request-service-modal.component.css']
})
export class RequestServiceModalComponent implements OnInit {

  constructor(private servicePopoutApi: ServicePopoutService, private instanceParamsService: InstanceParamsService) { }

  @Input() data; // ServiceId
  @Output() remove = new EventEmitter<any>();

  public isLoading = true;
  public serviceData;
  public selectedTab = 'info';
  instanceParams: any;


  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });
    this.servicePopoutApi.getServiceDetails(this.data).then(success => {
      this.serviceData = success;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){

    this.remove.emit("Hey");
  }

}
