import {Component, Input, OnInit, Output, EventEmitter, ViewContainerRef} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {DialogService} from "../../services/dialog-service/dialog.service";
import {ButtonComponent} from "../../test/button-component/button-component.component";
import {TicketBulkUpdateMergeSelectPrimaryComponent} from "./ticket-bulk-update-merge-select-primary/ticket-bulk-update-merge-select-primary.component";
import {RequestApiService} from "../../services/api/request/request-api.service";
import {ModalConfirmationComponent} from "../modal-confirmation/modal-confirmation.component";
import { UserPermissionsService } from 'src/app/services/user-permissions/user-permissions.service';
import { ApiAdminUsersService } from 'src/app/services/api/admin/admin-users-api/admin-users-api.service';

@Component({
  selector: 'app-ticket-bulk-update',
  templateUrl: './ticket-bulk-update.component.html',
  styleUrls: ['./ticket-bulk-update.component.css']
})
export class TicketBulkUpdateComponent implements OnInit {

  @Input() selectedTickets: SelectionModel<any>;
  @Input() ticketsData: [any];
  @Output() refreshData = new EventEmitter<void>();
  @Input() isCmdbEnabled:boolean;
  public userPermissionsSubscription;
  requestsInProgress = true;
  @Input() accessPermissions;
  refreshParent() {
    this.refreshData.emit();
  }

  constructor(private dialogService: DialogService, private viewReference: ViewContainerRef, private requestApi: RequestApiService,private userPermissions: UserPermissionsService,private apiAdminUsersService: ApiAdminUsersService) {
  }

  selectedTicketsData(){
    return this.ticketsData.filter(ticket => {
      return this.selectedTickets.isSelected(ticket.Id);
    })
  }

  mergeSelected() {
    this.requestsInProgress = true;

    if (this.selectedTickets.selected.length < 2) {
      return;
    }

    this.dialogService.createDialog(TicketBulkUpdateMergeSelectPrimaryComponent, this.selectedTicketsData(), this.viewReference)
      .then(primaryRequestId => {
        this.requestApi.mergeRequests(primaryRequestId, this.selectedTickets.selected)
          .then(r => {
            this.refreshData.emit();
            this.requestsInProgress = false;
          })
      }, dialogCancelled => {})
  }

  deleteSelected() {

    this.requestsInProgress = true;

    let arrayOfPromises = [];

    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure you want to delete these tickets?', this.viewReference)
      .then(confirmed => {

        this.selectedTickets.selected.forEach(requestId => {
          arrayOfPromises.push(this.requestApi.delete(requestId));
        })

        Promise.all(arrayOfPromises)
          .then(allSuccessful =>{
            this.refreshData.emit()
            this.requestsInProgress = false;

          }).catch(issue => {})

      }, dialogCancelled => {})
  }

  ngOnInit(): void {

  }

}
