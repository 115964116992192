

import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminCustomFieldService} from "../../../../../services/api/admin/admin-custom-fields-api/admin-custom-fields-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminCustomFieldsComponentDialog} from "./admin-custom-fields-create-edit-dialog/admin-custom-fields-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import { ApiAdminRequestTypeService } from 'src/app/services/api/admin/admin-request-type-api/admin-request-type-api.service';

@Component({
  selector: 'app-admin-custom-fields',
  templateUrl: './admin-custom-fields.html'
})
export class AdminCustomFieldsComponent implements OnInit {

  @Input() requestTypeId;

  data;
  values;
  isLoading = true;

  constructor(private apiAdminCustomFieldService: ApiAdminCustomFieldService,
              private dialogService: DialogService,
              private typeApiService: ApiAdminRequestTypeService,
              private viewReference: ViewContainerRef) {}

  ngOnInit(): void {
    this.getRequestDetail(this.requestTypeId)
    this.getData();
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomFieldService.listAll(this.requestTypeId).then(response => {
      
      // Separate fields with and without RequestTypeId
      const withRequestTypeId = response.filter(item => item.RequestTypeId !== null);
      const withoutRequestTypeId = response.filter(item => item.RequestTypeId === null);
      
      withRequestTypeId.sort((a, b) => a.DisplayOrder - b.DisplayOrder);

      this.data = [...withRequestTypeId, ...withoutRequestTypeId];
      this.isLoading = false;
    })
  }

  getRequestDetail(typeId){
    this.isLoading = true;

    this.typeApiService.get(typeId).then(response => {
      this.values = response;
      this.isLoading = false;
    })
  }

  isCmdbEnabled() {
    if (this.values && this.values.hasOwnProperty('CmdbEnabled') && this.values.CmdbEnabled === 1) {
        return true;
    }
    return false;
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomFieldsComponentDialog, {id: null, requestTypeId: this.requestTypeId, isCmdbEnabled: this.isCmdbEnabled()}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomFieldsComponentDialog, {id: id, requestTypeId: this.requestTypeId, isCmdbEnabled: this.isCmdbEnabled()}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomFieldService.delete(this.requestTypeId, id).then(response => this.getData())
      }, cancelled => null)
  }

  moveUp(id){
    this.apiAdminCustomFieldService.moveUp(this.requestTypeId, id).then(response => {
      this.getData()
    })
  }
  moveDown(id){
    this.apiAdminCustomFieldService.moveDown(this.requestTypeId, id).then(response => {
      this.getData()
    })
  }

}
