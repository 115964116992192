import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class RequestLinkService {

  private requestLinkApi = "/api/itil/request/link";

  constructor(private api: ApiService) {
  }

  getAvailableLinks(requestTypeId) {
    return this.api.get(`${this.requestLinkApi}/requesttype/${requestTypeId}`);
  }

  createLink(requestId, linkConfigurationId, targetRequestId, direction) {
    let bodyData = {
      RequestId: requestId,
      LinkConfigurationId: linkConfigurationId,
      TargetRequestId: targetRequestId,
      Direction: direction
    };

    return this.api.post(this.requestLinkApi, bodyData);
  }

  removeLink(requestLinkId) {
    return this.api.delete(`${this.requestLinkApi}/${requestLinkId}`);
  }

}
