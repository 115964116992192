

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from '../../../../../shared/dialog/dialog';
import {ApiAssetRegisterService} from '../../../../../services/api/asset-register-api/asset-register-api.service';
import {OptionsApiService} from '../../../../../services/api/options/options-api.service';
import {AgentUserService} from '../../../../../services/api/agent-user/agent-user.service';
import {RequesterSearchModalComponent} from '../../../../../shared/requester-search-modal/requester-search-modal.component';
import {DialogService} from '../../../../../services/dialog-service/dialog.service';
import { ApiAdminStatusService } from 'src/app/services/api/admin/admin-status-api/admin-status-api.service';
import { ApiAdminAssetTypesService } from 'src/app/services/api/admin/admin-asset-types-api/admin-asset-types-api.service';

@Component({
  selector: 'app-asset-register-create-edit-dialog',
  templateUrl: './asset-register-create-edit-dialog.component.html'
})

export class AssetRegisterComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingCustomers = true;

  loadedId = null;

  customerOptions = [];

  statusOptions2 = [
    {Id: 1, Name: 'In Use'},
    {Id: 2, Name: 'Missing'},
    {Id: 3, Name: 'In Transit'},
    {Id: 4, Name: 'In Stock'},
    {Id: 5, Name: 'Retired'},
    {Id: 6, Name: 'Disposed'},
  ];

  assetTypes2 = [
    {id: 1, name: 'Desktop'},
    {id: 2, name: 'Laptop'},
    {id: 3, name: 'Server'},
    {id: 4, name: 'Mobile'},
    {id: 5, name: 'Tablet'},
    {id: 6, name: 'Monitor'},
    {id: 7, name: 'Printer'},
    {id: 8, name: 'Router'},
    {id: 9, name: 'Switch'},
    {id: 10, name: 'Access Point'},
    {id: 11, name: 'Firewall'},
    {id: 12, name: 'Other'},
  ];

  loadedData;
  assetOptions;
  statusOptions = [];
  assetTypes = [];
  typeId;

  constructor(private agentUserApi: AgentUserService,private apiAdminAssetTypesService:ApiAdminAssetTypesService, private dialogService: DialogService, private apiOptions: OptionsApiService, private apiAdminStatusService: ApiAdminStatusService,
    private apiAssetRegisterService: ApiAssetRegisterService, private options: OptionsApiService, private viewReference: ViewContainerRef
  ) {
    this.getCustomers();
  }


  ngOnInit(): void {
    this.refreshOptions();
    this.getStatus();
    this.getCustomAssetTypes()
    if(this.data !== null && !this.data.assetRequestType){
      this.loadedId = this.data;
      this.getData();
      console.log('assetoptions ::' , this.loadedData)
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAssetRegisterService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  getCustomAssetTypes(){
    if (this.data && this.data.assetRequestTypeId) {
      this.isLoading = true;
      this.apiAdminAssetTypesService.listAll().then(response => {
        const transformedResponse = response.map(item => {
          return { id: item.Id, name: item.Name };
      });
        this.assetTypes = transformedResponse
        this.isLoading = false;
      })
    }else{
       // Handle the case when `assetRequestTypeId` is not available in `this.data`
        // For example, you might want to set statusOptions to an empty array or handle it in some other way.
        this.assetTypes = this.assetTypes2;
        this.isLoading = false;
    }

  }

  getStatus() {
    if (this.data && this.data.assetRequestTypeId) {
        this.isLoading = true;
        this.apiAdminStatusService.listAll(this.data.assetRequestTypeId).then(response => {
            this.statusOptions = response;
            this.isLoading = false;
        });
    } else {
        // Handle the case when `assetRequestTypeId` is not available in `this.data`
        // For example, you might want to set statusOptions to an empty array or handle it in some other way.
        this.statusOptions = this.statusOptions2;
        this.isLoading = false;
    }
}

  refreshOptions(refreshSilently = false) {
    if (!refreshSilently) {
        this.isLoading = true;
    }

    if (this.data && this.data.assetRequestTypeId) {
        this.apiOptions.DynamicOptions(this.data.assetRequestTypeId)
            .then(r => {
                this.assetOptions = r;
                this.typeId = this.assetOptions?.RequestTypeOptions?.Id;
                // console.log('assetOptions ::', this.assetOptions);
                this.isLoading = false;
            });
    } else {
        // Handle the case when `assetRequestTypeId` is not available in `this.data`
        this.assetOptions = [];
        console.log('assetOptions ::', this.assetOptions);
        this.isLoading = false;
    }
}

  setCustomFieldValue(customField, data){
    if (!this.loadedData.CustomFields) {
      this.loadedData.CustomFields = [];
    }
    const customFieldId = customField.CustomFieldId;

    if(customField.FieldType === 2) {

      const alreadyExists = this.loadedData.CustomFields.find(field => field.CustomFieldId == customFieldId);

      if(alreadyExists){
        alreadyExists.Value = data.selected;
      } else {
        this.loadedData.CustomFields.push({
          CustomFieldId: customFieldId,
          Value: data.selected,
          FieldType: customField.FieldType
        })
      }
    }

    else if (customField.FieldType === 1 || customField.FieldType === 3) { // If FieldType is 1 (textbox)
      const alreadyExists = this.loadedData.CustomFields.find(field => field.CustomFieldId === customFieldId);

      if (alreadyExists) {
          alreadyExists.Value = data;
      } else {
          this.loadedData.CustomFields.push({
              CustomFieldId: customFieldId,
              Value: data,
              FieldType: customField.FieldType
          });
      }
  }


  }

  getCustomers(){
    this.options.AllCustomers().then(response => {
      this.customerOptions = response;
      this.isLoadingCustomers = false;
    })
  }

  openUserSearchModal(){
    this.dialogService.createDialog(RequesterSearchModalComponent, null, this.viewReference)
      .then((dialogSaved: any) => {
        const userId = dialogSaved?.userId;
        const customerId = dialogSaved?.customerId;
        const userName = dialogSaved?.userName;

        console.log("UserIdReturned");
        console.log(userId);
        console.log(customerId);

        if(userId) {
          this.loadedData.OwnerId = userId;
        }

        if(customerId && !this.data.CustomerId){
          this.loadedData.CustomerId = customerId;
        }

        this.loadedData.OwnerName = userName;

      }, dialogCancelled => {
        console.log("dialogCancelled:"+dialogCancelled)
      })

  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAssetRegisterService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAssetRegisterService.create(this.loadedData, this.typeId).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
