import {Component, Input, OnInit, Output, EventEmitter, ViewContainerRef} from '@angular/core';
import {Dialog} from "../dialog/dialog";
import {OptionsApiService} from "../../services/api/options/options-api.service";
import {FilterService} from "../../services/api/filter/filter.service";
import {ModalConfirmationComponent} from "../modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../services/dialog-service/dialog.service";
import { ApiAdminAssetTypesService } from 'src/app/services/api/admin/admin-asset-types-api/admin-asset-types-api.service';

@Component({
  selector: 'app-filter-create-edit-blade',
  templateUrl: './filter-create-edit-blade.component.html',
  styleUrls: ['./filter-create-edit-blade.component.css']
})
export class FilterCreateEditBladeComponent implements Dialog {

  @Input() data; // create // filterId // requestTypeId (Required on create) //cmdbEnabled
  @Output() remove: EventEmitter<{successId: any; typeId: number; state: string }> = new EventEmitter<{ successId: any; typeId: number; state: string }>();

  filterData;
  filterOptions;
  requestTypeId;
  isLoading = true;
  isLoadingOptions = true;

  dropdownSettings = {
    singleSelection: false,
    text: "Select",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'name',
    lazyLoading: true,
    badgeShowLimit: 4,
    classes: "sdesk-multi-select"
  };

  predefinedDates = [
    { id: 8, name: 'All' },
    { id: 1, name: 'Last 24 Hours' },
    { id: 2, name: 'Last Week' },
    { id: 3, name: 'Last Month' },
    { id: 4, name: 'Last 3 Months' },
    { id: 5, name: 'Last 6 Months' },
    { id: 6, name: 'Last 12 Months' },
    { id: 7, name: 'Custom Dates' }
  ]

  columnDropdownSettings = {
    singleSelection: false,
    text: "Select",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: false,
    labelKey: 'name',
    lazyLoading: true,
    badgeShowLimit: 15,
    classes: "sdesk-multi-select"
  };

  filterTypes = [
    {id: 3, name: 'Not Filtered'},
    {id: 1, name: 'Is'},
    {id: 4, name: 'Is Not'},
    {id: 2, name: 'Is Unassigned'}
  ];

  filterTypesForChangeState = [
    {id: 3, name: 'Not Filtered'},
    {id: 1, name: 'Is'},
    {id: 4, name: 'Is Not'},
  ];

  changeStates = [
    {
      id: 1,
      name: "Opened"
    },
    {
      id: 2,
      name: "Planning"
    },
    {
      id: 3,
      name: "Scheduling"
    },
    {
      id: 4,
      name: "Under Approval"
    },
    {
      id: 5,
      name: "Implementation"
    },
    {
      id: 6,
      name: "Under Review"
    },
    {
      id: 7,
      name: "Pending Closure"
    },
    {
      id: 8,
      name: "Closed"
    }
  ]





  columnOptions = [
    {id: 2, name: 'Status'},
    {id: 4, name: 'Agent'},
    {id: 8, name: 'Created'},
    {id: 9, name: 'Subject'},
    {id: 10, name: 'Requester'},
    {id: 11, name: 'Id'},
    {id: 12, name: 'Responded'},
    {id: 13, name: 'Resolved'},
    {id: 14, name: 'Updated'},
    {id: 20, name: 'Group'},
    {id: 25, name: 'Requester Responded'},
    {id: 26, name: 'Last Status Change'},
    {id: 41, name: 'Requester Email'},
  ];

  columnOptionsCmdbEnabled = [
    {id: 2, name: 'Status'},
    {id: 8, name: 'Created'},
    {id: 9, name: 'Asset Name'},
    {id: 10, name: 'Owner'},
    {id: 11, name: 'Asset No.'},
    {id: 14, name: 'Updated'},
    {id: 21, name: 'Customer'},
    {id: 26, name: 'Last Status Change'},
    {id: 30, name: 'Asset Type'},
    {id: 31, name: 'Serial Number'},
    {id: 32, name: 'Imei'},
    {id: 33, name: 'Manufacturer'},
    {id: 34, name: 'Model'},
    {id:35, name:'Cost'}
  ];

  orderByOptions = [
    {id: 0, name: 'High to Low (Z-A)'},
    {id: 1, name: 'Low to High (A-Z)'}
  ];

  assetTypes = [
    {id: 1, name: 'Desktop'},
    {id: 2, name: 'Laptop'},
    {id: 3, name: 'Server'},
    {id: 4, name: 'Mobile'},
    {id: 5, name: 'Tablet'},
    {id: 6, name: 'Monitor'},
    {id: 7, name: 'Printer'},
    {id: 8, name: 'Router'},
    {id: 9, name: 'Switch'},
    {id: 10, name: 'Access Point'},
    {id: 11, name: 'Firewall'},
    {id: 12, name: 'Other'},
  ];


  constructor(private optionsApi: OptionsApiService, private filterApi: FilterService,private apiAdminAssetTypesService: ApiAdminAssetTypesService, private dialogService: DialogService,
              private viewReference: ViewContainerRef) {
    console.log("Constructing!!!");
  }

  existingFilterGetData(){
    this.isLoading = true;
    this.filterApi.getExistingFilterValues(this.data.filterId).then(data => {
      this.filterData = data;
      if(this.isCmdbEnabled()){
        this.filterData.Columns.forEach(item =>{
          if (item.id === 9) {
            item.name = 'Asset Name';
          }
          else if(item.id === 10){
            item.name = 'Owner'
          }
          else if(item.id === 11) {
            item.name = 'Asset No.'
          }
        })

      }
      this.getOptions();
      this.isLoading = false;
    })
  }

  ngOnInit(): void {
    if(this.data.create) {

      // Set Some Default Values
      this.filterData = {
        Name: '',
        Private: 1,
        RequestTypeId: this.data.requestTypeId,
        Filter_GroupType: 3,
        Filter_AgentType: 3,
        Filter_StatusType: 3,
        Filter_PriorityType: 3,
        Filter_CustomerType: 3,
        Filter_ServiceType: 3,
        Filter_ChangeStateType: 3,
        SlaResponseOverdue: 0,
        SlaResolutionOverdue: 0,
        Columns: [
        {
          "id": 2,
          "name": "Status"
        },
        {
          "id": 4,
          "name": "Agent"
        },
        {
          "id": 8,
          "name": "Created"
        },
        {
          "id": 9,
          "name": "Subject"
        },
        {
          "id": 10,
          "name": "Requester"
        },
        {
          "id": 11,
          "name": "Id"
        },
        {
          "id": 20,
          "name": "Group"
        }
      ],
      ColumnsCmdbEnabled:[
        {
          "id": 2,
          "name": "Status"
        },
        {
          "id": 8,
          "name": "Created"
        },
        {
          "id": 9,
          "name": "Asset Name"
        },
        {
          "id": 10,
          "name": "Owner"
        },
        {
          "id": 11,
          "name": 'Asset No.'
        },
      ],
        GroupValues: [],
        AgentValues: [],
        StatusValues: [],
        PriorityValues: [],
        CustomerValues: [],
        ServiceValues: [],
        ChangeStateValues:[],
        CustomFields: [],
        Planning_ScheduledStart_From:"",
        Planning_ScheduledStart_To:"",
        LookBackPeriod:8,
        CreatedDateStart:"",
        CreatedDateEnd:"",
        OrderById: 11,
        OrderByIdCmdbEnabled: 9,
        OrderByAsc: 1
      };
      if(this.isCmdbEnabled() && this.data.create){
        this.filterData.Columns = this.filterData.ColumnsCmdbEnabled
        this.filterData.AssetTypesEnabled = [
          {id: 1, name: 'Desktop'},
          {id: 2, name: 'Laptop'},
          {id: 3, name: 'Server'},
          {id: 4, name: 'Mobile'},
          {id: 5, name: 'Tablet'},
          {id: 6, name: 'Monitor'},
          {id: 7, name: 'Printer'},
          {id: 8, name: 'Router'},
          {id: 9, name: 'Switch'},
          {id: 10, name: 'Access Point'},
          {id: 11, name: 'Firewall'},
          {id: 12, name: 'Other'},
        ];
        this.getCustomAssetTypes()
      }

      this.getOptions();

      this.isLoading = false;
    }
    if(!this.data.create && this.data.filterId){
      this.existingFilterGetData();
    }
  }

  isCmdbEnabled(){
    return this.data && this.data.hasOwnProperty('cmdbEnabled') && this.data.cmdbEnabled === 1;
  }

  getCustomAssetTypes(){
    this.isLoading = true;
    this.apiAdminAssetTypesService.listAll().then(response => {
      const transformedResponse = response.map(item => {
        return { id: item.Id, name: item.Name };
    });
      this.filterData.AssetTypesEnabled = transformedResponse;
      this.assetTypes = transformedResponse
      this.isLoading = false;
    })
  }

  getOptions(){
    this.isLoadingOptions = true;
    this.optionsApi.DynamicOptions(this.filterData.RequestTypeId).then(data => {
      this.filterOptions = data;
      this.isLoadingOptions = false;

      if (this.filterOptions.RequestTypeOptions.ApplySla) {
        this.columnOptions.push({id: 15, name: 'Response Due'});
        this.columnOptions.push({id: 16, name: 'Resolution Due'});
        this.columnOptions.push({id: 29, name: 'Next SLA Due'});
      }

      // If SLA is enabled for this Request Type then add the Sla Indicator column
      if(this.data.create && this.filterOptions.RequestTypeOptions.ApplySla) {
        this.filterData.Columns.push({id: 29, name: 'Next SLA Due'});
      }

      if (this.filterOptions.RequestTypeOptions.CustomerEnabled) {
        this.columnOptions.push({id: 21, name: 'Customer'});
      }
      if (this.filterOptions.RequestTypeOptions.ImpactEnabled) {
        this.columnOptions.push({id: 22, name: 'Impact'});
      }
      if (this.filterOptions.RequestTypeOptions.PriorityEnabled) {
        this.columnOptions.push({id: 3, name: 'Priority'});
      }
      if (this.filterOptions.RequestTypeOptions.ResolutionCodesEnabled) {
        this.columnOptions.push({id: 24, name: 'Resolution Code'});
      }
      if (this.filterOptions.RequestTypeOptions.ServiceEnabled) {
        this.columnOptions.push({id: 19, name: 'Service'});
      }
      if (this.filterOptions.RequestTypeOptions.SurveyEnabled) {
        this.columnOptions.push({id: 17, name: 'Survey Result'});
        this.columnOptions.push({id: 18, name: 'Survey Status'});
      }
      if (this.filterOptions.RequestTypeOptions.UrgencyEnabled) {
        this.columnOptions.push({id: 23, name: 'Urgency'});
      }
      if (this.filterOptions.RequestTypeOptions.ChangeManagementEnabled) {
        this.columnOptions.push({id: 27, name: 'Change Management State'});
        this.columnOptions.push({id: 36, name: 'Planning Scheduled Start'});
        this.columnOptions.push({id: 37, name: 'Planning Scheduled End'});
        this.columnOptions.push({id: 38, name: 'Requested By Date'});
        this.columnOptions.push({id: 39, name: 'Planning Downtime Start'});
        this.columnOptions.push({id: 40, name: 'Planning Downtime End'});
        this.columnOptions.push({id: 42, name: 'Approval Status'});
        this.filterOptions.changeStates = this.changeStates
      }

      if (this.filterOptions.Columns){
        for(const c of  this.filterOptions.Columns){
            if (typeof c.id === 'string' && c["id"].startsWith("CustomField_")) {
              this.columnOptionsCmdbEnabled.push(c);
              this.columnOptions.push(c)
            }
      }
    }

    })
  }

  close(){
    this.remove.emit(null);
  }

  delete(){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.filterApi.deleteFilter(this.data.filterId).then(success => {
          this.remove.emit({successId: 0, typeId: this.filterData.RequestTypeId, state: 'Deleted' }); // you need to return the typeId because this id will be used on the URL where it will go after filter deletion    
        });
      }, cancelled => null)
  }

  save(){
    if(this.data.create){
      this.filterApi.createFilter(this.filterData).then(success => {
        this.remove.emit({successId: success.Id, typeId: 0, state: 'Saved' });    
      }, fail => {
        console.log("Failed: "+fail);
      })
    } else {
      this.filterApi.updateFilter(this.data.filterId, this.filterData).then(success => {
          this.remove.emit({successId: 0, typeId: 0, state: 'DataToReturn' });    
        }, fail => {
          console.log("Failed: "+fail);
        }
      )
    }

  }

}
