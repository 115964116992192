

import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminPriorityService} from "../../../../../services/api/admin/admin-priority-api/admin-priority-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminPriorityComponentDialog} from "./admin-priority-create-edit-dialog/admin-priority-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-priority',
  templateUrl: './admin-priority.html'
})
export class AdminPriorityComponent implements OnInit {

  @Input() requestTypeId;

  data;
  isLoading = true;

  constructor(private apiAdminPriorityService: ApiAdminPriorityService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef) {  }

  ngOnInit(): void {
    this.getData();
  }

  moveUp(id){
    this.apiAdminPriorityService.moveUp(this.requestTypeId, id).then(response => {
      this.getData()
    })
  }
  moveDown(id){
    this.apiAdminPriorityService.moveDown(this.requestTypeId, id).then(response => {
      this.getData()
    })
  }

  getData(){
    this.isLoading = true;
    this.apiAdminPriorityService.listAll(this.requestTypeId).then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminPriorityComponentDialog, {id: null, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminPriorityComponentDialog, {id: id, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminPriorityService.delete(this.requestTypeId, id).then(response => this.getData())
      }, cancelled => null)
  }



}
