import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsApiService} from "../../../../services/api/options/options-api.service";
import {RequestApiService} from "../../../../services/api/request/request-api.service";
import {Dialog} from "../../../../shared/dialog/dialog";

@Component({
  selector: 'app-request-change-type-modal',
  templateUrl: './request-change-type-modal.component.html',
  styleUrls: ['./request-change-type-modal.component.css']
})
export class RequestChangeTypeModalComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  newTypeId;
  options;

  constructor(private optionsApi: OptionsApiService, private requestApi: RequestApiService) { }

  ngOnInit(): void {
    this.optionsApi.AllRequestTypes().then(results => this.options = results)
  }

  closeModal(){
    this.remove.emit(null);
  }

  save(){
    this.requestApi.updateType(this.data, this.newTypeId).then(success => {
      this.remove.emit("Success");
    })
  }

}
