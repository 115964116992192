<div *ngIf="!isLoading">
<div class="d-flex" id="wrapper" [class.toggled]="!sideBarNavOpen">

  <nav class="navbar topBarMakeFixed" id="navbar">

    <!--    Hidden only on XS-->

    <div class="navbar-logo text-center">
      <img *ngIf="brandingSubscription?.LogoUrl" alt="Logo" style="max-height:40px; max-width:200px;" class="ng-scope" [src]="brandingSubscription?.LogoUrl">
    </div>

    <!--    Visible only on XS-->
    <button (click)="sideBarNavOpen = !sideBarNavOpen" class="navbar-button d-block" id="menu-toggle-mobile"><i class="fas fa-bars"></i></button>

    <button [routerLink]="['/search']" class="navbar-button" id="menu-search"><i class="fas fa-search"></i></button>

    <button *ngIf="auth.amILoggedIn()" (click)="newTicket()" class="navbar-button float-end divLink" id="menu-new">Create New</button>

  </nav>

  <!-- Sidebar -->
  <div class="sideBarMakeFixed" id="sidebar-wrapper">
    <ul>
      <li *ngIf="this.environmentSubscription?.ShowKnowledgeBase">
        <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" ><i class="fas fa-book-open"></i> <span> Solutions </span></a>
        <hr>
      </li>

      <li *ngIf="!auth.amILoggedIn()" (click)="auth.checkLoggedIn()" class="divLink">
        <a><i class="fas fa-sign-in-alt"></i> <span> Login </span></a>
      </li>

      <li *ngIf="auth.amILoggedIn()">
        <a [routerLink]="['/mytickets']" routerLinkActive="active">
          <i class="fas fa-list"></i>
          <span> My Support Tickets </span>
        </a>
      </li>

      <li *ngIf="auth.amILoggedIn()" class="divLink">
        <a (click)="newTicket()">
          <i class="fas fa-plus"></i>
          <span> New Ticket </span>
        </a>
        <hr>
      </li>

      <li *ngIf="auth.amILoggedIn() && userPermissionSubscription?.Agent" [routerLink]="['/agent/']" class="divLink">
        <a><i class="fas fa-sign-out-alt"></i> <span> Go to Agent Portal </span></a>
      </li>

      <li *ngIf="auth.amILoggedIn()" (click)="logout()" class="divLink">
        <a><i class="fas fa-sign-out-alt"></i> <span> Logout </span></a>
      </li>
    </ul>
  </div>
  <!-- /#sidebar-wrapper -->


  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="container-fluid p-0">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->

<!-- Menu Toggle Script -->
</div>
