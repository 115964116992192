<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Template</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Name
      </div>
      <div class="col-sm p-10">
          {{loadedData.Name}}
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Subject <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Subject" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>
    <div class="row" *ngIf="loadedData.Name === 'TaskAssignedGroup' || loadedData.Name === 'TicketAssignedGroup'">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Enable group notifications 
      </div>
      <div class="col-sm p-10">
        <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.IsEmailEnabled">     
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Template <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <span *ngIf="!showEditor" (click)="showEditor = !showEditor" class="divLink float-end">(Show Editor)</span>
        <span *ngIf="showEditor" (click)="showEditor = !showEditor" class="divLink float-end">(Show HTML)</span>

        <quill-editor *ngIf="showEditor" [(ngModel)]="loadedData.Template" [styles]="{height: '550px'}" placeholder=""></quill-editor>
        <textarea *ngIf="!showEditor" rows="13" id="Template" [(ngModel)]="loadedData.Template" class="form-control" style="width: 100%;"></textarea>
      </div>
    </div>

    <hr>

    <app-template-syntax></app-template-syntax>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
