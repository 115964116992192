

import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminStatusComponentDialog} from "./admin-status-create-edit-dialog/admin-status-create-edit-dialog.component";
import {ApiAdminStatusService} from "../../../../../services/api/admin/admin-status-api/admin-status-api.service";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-status',
  templateUrl: './admin-status.html'
})
export class AdminStatusComponent implements OnInit {

  @Input() requestTypeId;

  data;
  isLoading = true;

  constructor(private apiAdminStatusService: ApiAdminStatusService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.getData();

  }

  getData(){
    this.isLoading = true;
    this.apiAdminStatusService.listAll(this.requestTypeId).then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminStatusComponentDialog, {id: null, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminStatusComponentDialog, {id: id, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminStatusService.delete(this.requestTypeId, id).then(response => this.getData())
      }, cancelled => null)
  }



}
