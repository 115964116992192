import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse} from "@angular/common/http";
import {AuthenticationService} from "../authentication/authentication.service";
import {Router} from "@angular/router";
import {saveAs} from "file-saver";
import {ToastrService} from "ngx-toastr";


@Injectable({
  providedIn: 'root',
})
export class ApiService {

  // The loaded version of SDesk
  private loadedVersion: string;

  // if forceRefresh is true, then a message should appear asking the user to hard-refresh SDesk
  // If the loaded version (JS Version) does not match the back-end version (Sent via header) then SDesk has been released during this session
  private forceRefresh: boolean;

  // Store the timestamp of the last error with status 0
  private lastErrorStatusZeroTimestamp: number;

  private downloadProgress: any;

  constructor(private http: HttpClient, private authService: AuthenticationService, private toastr: ToastrService) {
    this.loadedVersion = '{VersionNumber}'; // this will be replaced when compiling SDesk
    this.forceRefresh = false;
    this.lastErrorStatusZeroTimestamp = 0;
  }

  // the main component will call this to determine if refresh modal needs to be shown
  public isRefreshNeeded() {
    return this.forceRefresh;
  }

  public get(uri, params?, returnBody = true, responseType: "json" | "arraybuffer" | "blob" | "text" = "json") {
    return this.newHttpRequest('GET', uri, null, params, returnBody, responseType);
  }

  public post(uri, body?, params?) {
    return this.newHttpRequest('POST', uri, body, params);
  }

  public put(uri, body?, params?) {
    return this.newHttpRequest('PUT', uri, body, params);
  }

  public patch(uri, body?, params?) {
    return this.newHttpRequest('PATCH', uri, body, params);
  }

  public delete(uri, body?, params?) {
    return this.newHttpRequest('DELETE', uri, body, params);
  }

  public download(uri, method, body?, params?) {
    this.newHttpRequest(method, uri, body, params, false, "arraybuffer").then(response => {
      let fileName = response.headers.get('x-filename');
      let contentType = response.headers.get('content-type');
      let data = response.body;

      const blob = new Blob([data], {type: contentType});

      saveAs(blob, fileName);
    })
  }


public downloadInChunks(uri: string, method: string, body?: any, params?: any) {
  // Initialize toastr notification for download start
  this.startDownloadNotification();

  // Make the HTTP request
  this.http.request(method, uri, {
    observe: 'events', // Observe events to monitor progress
    reportProgress: true, // Report progress events
    responseType: 'arraybuffer', // Expected response type
    headers: this.getHttpRequestHeaders(),
    body: body,
    params: params
  }).subscribe(event => {
    this.handleHttpEvent(event);
  }, error => {
    this.handleError(error);
  });
}

// Display notification when download starts
private startDownloadNotification() {
  this.downloadProgress = this.toastr.info('Download started...', '', {
    closeButton: true,
    progressBar: true,
    tapToDismiss: false
  });
}

// Handle HTTP events including progress and response
private handleHttpEvent(event: HttpEvent<any>) {
  if (event.type === HttpEventType.DownloadProgress) {
    this.updateProgressNotification(event);
  } else if (event instanceof HttpResponse) {
    this.completeDownload(event);
  }
}

private updateProgressNotification(event: HttpEvent<any>) {
  if (event.type === HttpEventType.DownloadProgress) {
    // Cast the event to HttpProgressEvent to access 'loaded' and 'total'
    const progressEvent = event as HttpProgressEvent;

    // Calculate progress percentage
    const progress = Math.round((progressEvent.loaded / (progressEvent.total || progressEvent.loaded)) * 100);

    // Update toastr notification with progress
    this.toastr.clear(this.downloadProgress.toastId); // Clear previous progress
    this.downloadProgress = this.toastr.info(`Download progress: ${progress}%`, '', {
      closeButton: true,
      progressBar: true,
      tapToDismiss: false
    });
  }
}

// Handle completion of download
private completeDownload(event: HttpResponse<any>) {
  const contentType = event.headers.get('content-type') || 'text/csv;charset=UTF-8';
  const fileName = event.headers.get('x-filename') || 'downloaded-file.csv';
  const blob = new Blob([event.body], { type: contentType });

  saveAs(blob, fileName); // Save file using FileSaver.js

  this.toastr.clear(this.downloadProgress.toastId); // Clear progress notification
  this.toastr.success('Download complete!', '', {
    closeButton: true
  });
}

// Handle errors during download
private handleError(error: any) {
  console.error('Download failed:', error);
  this.toastr.clear(this.downloadProgress.toastId); // Clear progress notification
  this.toastr.error('Download failed. Please try again.', '', {
    closeButton: true
  });
}


  private newHttpRequest(type, uri, body?, params?, returnBody = true, responseType: "json" | "arraybuffer" | "blob" | "text" = "json") {
    return new Promise<any>((resolve, reject) => {
      this.http.request(type, uri, {
        observe: 'response',
        headers: this.getHttpRequestHeaders(),
        params: params,
        responseType: responseType,
        body: body
      }).subscribe(response => {

        //this.toastr.success('Successful API Call', uri);


        // Request was successful
        console.log(`Calling ${uri}: Successful Status code: ${response.status}`);

        const apiVersion = response.headers.get('SDeskVersion');

        if (this.loadedVersion != apiVersion) {
          this.forceRefresh = true;
        }

        if (returnBody) {
          resolve(<any>response.body);
        } else {
          resolve(<any>response);
        }

      }, error => {
        // Request failed
        console.error(`Calling ${uri}: Failed with Error Code: ${error.status}`);
        console.error(error);

        // if uri contains 'poll-updates' then we don't want to show the error to the user

        if (uri.includes('poll-updates')) {
          reject(error.body);
          return;
        }

        // Genuine error message sent from SDesk

        if (error.status == 400) {
          // ToDo: We want to show 400 errors to the user, popup ?
          const errorMessage = error?.error.error;

          this.toastr.warning(errorMessage, 'Error');

          reject(errorMessage);


        }else if(error.status == 0){
          const currentTime = new Date().getTime();
              if (currentTime - this.lastErrorStatusZeroTimestamp > 30000) { // 30 seconds
                this.toastr.info("Internet not connected");
                this.lastErrorStatusZeroTimestamp = currentTime;
              }

        }
         else if (error.status == 401) {
          // User is not authenticated, token expired or account has been disabled
          // Remove cached auth token
          // Redirect to login page
          this.authService.removeAuthTokenAndRedirectToLogin();
        } else if (error.status == 403) {
          // User is not authenticated, token expired or account has been disabled
          // Remove cached auth token
          // Redirect to login page
          this.toastr.error(`Access denied. Please contact the administrator.`, 'Error');
        }else {
          this.toastr.error(`${error.status} Error calling SDesk service.`, 'Error');
        }


        reject(error.body);
      })
    });
  }

  private getHttpRequestHeaders() {
    return new HttpHeaders().set('Authorization', `Bearer ${this.authService.returnAuthToken()}`);
  }

}
