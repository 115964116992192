import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsApiService} from "../../../../services/api/options/options-api.service";
import {RequestApiService} from "../../../../services/api/request/request-api.service";
import {RequestLinkService} from "../../../../services/api/request/request-link/request-link.service";
import {FilterService} from "../../../../services/api/filter/filter.service";

@Component({
  selector: 'app-request-link-modal',
  templateUrl: './request-link-modal.component.html',
  styleUrls: ['./request-link-modal.component.css']
})
export class RequestLinkModalComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  windowShown = 'selectLinkType' || 'selectTicket';

  requestId;

  availableLinks;
  tickets;

  selectedLinkType;
  selectedRequestId;

  searchTerm = '';

  public page = 1;
  public totalPages = null;
  public totalFilteredResults = null;

  constructor(private requestLinkApi: RequestLinkService, private filterApiService: FilterService) { }

  filteredTickets() {
    let filteredData  = this.tickets.Results;
    let searchTerm = this.searchTerm.toLocaleLowerCase();

    if(searchTerm) {
      filteredData = filteredData.filter(ticket => {
        return (ticket.LocalRequestId ? ticket.LocalRequestId.toString().toLocaleLowerCase() : '').includes(searchTerm)
          || (ticket.Requester ? ticket.Requester.toString().toLocaleLowerCase() : '').includes(searchTerm)
          || (ticket.Subject ? ticket.Subject.toString().toLocaleLowerCase() : '').includes(searchTerm)
          || (ticket.Agent ? ticket.Agent.toString().toLocaleLowerCase() : '').includes(searchTerm)
          || (ticket.AssetNo ? ticket.AssetNo.toString().toLocaleLowerCase() : '').includes(searchTerm)
      });
    }

    this.totalPages = Math.ceil(filteredData.length / 15);
    this.totalFilteredResults = filteredData.length;

    return filteredData;
  }

  filteredTicketsThisPage() {
    const startNumber = (this.page - 1) * 15;
    const endNumber = this.page * 15;
    return this.filteredTickets().slice(startNumber, endNumber);
  }

  resetPage() {
    this.page = 1;
  }

  pageBackwards() {
    if (this.page == 1) {
      return;
    }
    this.page = this.page - 1;
  }

  pageForward() {
    if (this.page == this.totalPages) {
      return;
    }
    this.page = this.page + 1;
  }


  ngOnInit(): void {

    this.requestId = this.data.requestId;

    this.requestLinkApi.getAvailableLinks(this.data.requestTypeId).then(
      results => {
        this.availableLinks = results
        this.windowShown = 'selectLinkType';
      }
    );
  }

  getTickets(){

    let targetRequestTypeId = this.selectedLinkType.Direction === "LeftToRight" ? this.selectedLinkType.Right_Id : this.selectedLinkType.Left_Id;
    let page = null;
    let perpage = null;
    let orderById;
    let ascending;
    this.filterApiService.executeView('active', targetRequestTypeId,orderById,ascending,page,perpage).then(results => {
      this.tickets = results;
      this.windowShown = 'selectTicket';
    });
  }

  closeModal(){
    this.remove.emit(null);
  }

  save(){
    this.requestLinkApi.createLink(this.requestId, this.selectedLinkType.LinkConfigurationId, this.selectedRequestId, this.selectedLinkType.Direction).then(success => {
      this.remove.emit("Success");
    })
  }

}
