import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from "../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-parent-opening-dialog',
  templateUrl: './parent-opening-dialog.component.html',
  styleUrls: ['./parent-opening-dialog.component.css']
})
export class ParentOpeningDialogComponent implements OnInit {

  constructor(private dialogService: DialogService, private viewReference: ViewContainerRef) { }

  ngOnInit(): void {
  }

  openDialog(){
    // Replace null with Component
    this.dialogService.createDialog(null, "DataToSendToDialog", this.viewReference)
      .then(dialogSaved => {

      }, dialogCancelled => {

      })
  }

}
