<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading && !isLoadingOptions" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.data.create ? 'Create' : 'Edit'}} Filter</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Filter Name <span class="text-danger">*</span>
        </div>
        <div class="col p-10">
          <input type="text" [(ngModel)]="filterData.Name" class="form-control" id="FilterName" placeholder="" autocomplete="off">
        </div>
    </div>

    <div class="row">
        <div class="col-2 formHeader p-10"></div>
        <div class="col p-10">
          <input [(ngModel)]="filterData.Private" class="form-check-input" id="private" type="checkbox"><label for="private" class="p-l-10">Private</label>
        </div>
    </div>

    <div class="row" *ngIf="data.cmdbEnabled !==1">
        <div class="col-2 formHeader p-10"></div>
        <div class="col p-10">
          <input [(ngModel)]="filterData.SlaResponseOverdue" class="form-check-input" id="SlaResponseOverdue" type="checkbox"><label for="SlaResponseOverdue" class="p-l-10">SLA Response Breached</label>
        </div>
    </div>

    <div class="row" *ngIf="data.cmdbEnabled !==1">
        <div class="col-2 formHeader p-10"></div>
        <div class="col p-10">
          <input [(ngModel)]="filterData.SlaResolutionOverdue" class="form-check-input" id="SlaResolutionOverdue" type="checkbox"><label for="SlaResolutionOverdue" class="p-l-10">SLA Resolution Breached</label>
        </div>
    </div>

    <!-- filter dates -->
    <div class="row sdesk-grey-row" *ngIf="data.cmdbEnabled !==1">
        <div class="col-2 formHeader alignMiddle p-10">
           Created Date
        </div>
        <div class="col-4 p-10">
        <ng-select [items]="predefinedDates" bindLabel="name" bindValue="id" [(ngModel)]="filterData.LookBackPeriod"
           [clearable]="false"
          [disabled]="isLoadingStats || isLoadingTicketList">
        </ng-select>

        <div *ngIf="filterData.LookBackPeriod === 7" class="row">
        <div class="col-4 p-10">
          <input  style="display:none" matInput [matDatepicker]="dateFromPicker"
            [(ngModel)]="filterData.CreatedDateStart">
            From
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
          <span>{{filterData.CreatedDateStart | date}}
            <mat-datepicker #dateFromPicker [touchUi]="true"></mat-datepicker></span>
          </div>
          <div class="col-4 p-10">

          <input  style="display:none" class="form-control" matInput
            [matDatepicker]="dateToPicker" [(ngModel)]="filterData.CreatedDateEnd">
            To
          <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
          <span>{{filterData.CreatedDateEnd | date}}
            <mat-datepicker #dateToPicker [touchUi]="true"></mat-datepicker></span>
            </div>
        </div>
        </div>
    </div>

    <!-- Filter Group -->
    <div class="row sdesk-grey-row" *ngIf="data.cmdbEnabled !==1">
      <div class="col-2 formHeader alignMiddle p-10">
        Group
      </div>
      <div class="col-4 p-10">
        <select [(ngModel)]="filterData.Filter_GroupType" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div *ngIf="filterData.Filter_GroupType == 1 || filterData.Filter_GroupType == 4" class="col p-10">
        <app-sdesk-multi-select [data]="filterOptions.group" [(selectedData)]="filterData.GroupValues"></app-sdesk-multi-select>


      </div>
    </div>

    <!-- Filter Agent -->
    <div class="row sdesk-grey-row" *ngIf="data.cmdbEnabled !==1">
      <div class="col-2 formHeader alignMiddle p-10">
        Agent
      </div>
      <div class="col-4 p-10">
        <select [(ngModel)]="filterData.Filter_AgentType" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div *ngIf="filterData.Filter_AgentType == 1 || filterData.Filter_AgentType == 4" class="col p-10">
        <app-sdesk-multi-select [data]="filterOptions.agent" [(selectedData)]="filterData.AgentValues"></app-sdesk-multi-select>

      </div>
    </div>

    <!-- Filter Status -->
    <div class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Status
      </div>
      <div class="col-4 p-10">
        <select [(ngModel)]="filterData.Filter_StatusType" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div *ngIf="filterData.Filter_StatusType == 1 || filterData.Filter_StatusType == 4" class="col p-10">
        <app-sdesk-multi-select [data]="filterOptions.status" [(selectedData)]="filterData.StatusValues"></app-sdesk-multi-select>
      </div>
    </div>

    <!-- Filter Priority -->
    <div *ngIf="filterOptions.RequestTypeOptions.PriorityEnabled" class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Priority
      </div>
      <div class="col-4 p-10">
        <select [(ngModel)]="filterData.Filter_PriorityType" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div *ngIf="filterData.Filter_PriorityType == 1 || filterData.Filter_PriorityType == 4" class="col p-10">
        <app-sdesk-multi-select [data]="filterOptions.priority" [(selectedData)]="filterData.PriorityValues"></app-sdesk-multi-select>
      </div>
    </div>

    <!-- Filter Customer -->
    <div *ngIf="filterOptions.RequestTypeOptions.CustomerEnabled" class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Customer
      </div>
      <div class="col-4 p-10">
        <select [(ngModel)]="filterData.Filter_CustomerType" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div *ngIf="filterData.Filter_CustomerType == 1 || filterData.Filter_CustomerType == 4" class="col p-10">
        <app-sdesk-multi-select [data]="filterOptions.customer" [(selectedData)]="filterData.CustomerValues"></app-sdesk-multi-select>
      </div>
    </div>

    <!-- Filter Service -->
    <div *ngIf="filterOptions.RequestTypeOptions.ServiceEnabled" class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Service
      </div>
      <div class="col-4 p-10">
        <select [(ngModel)]="filterData.Filter_ServiceType" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div *ngIf="filterData.Filter_ServiceType == 1 || filterData.Filter_ServiceType == 4" class="col p-10">
        <app-sdesk-multi-select [data]="filterOptions.service" [(selectedData)]="filterData.ServiceValues"></app-sdesk-multi-select>
      </div>
    </div>


    <!-- Filter Change State -->
    <div *ngIf="filterOptions.RequestTypeOptions.ChangeManagementEnabled" class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Change State
      </div>
      <div class="col-4 p-10">
        <select [(ngModel)]="filterData.Filter_ChangeStateType" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.filterTypesForChangeState" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div *ngIf="filterData.Filter_ChangeStateType == 1 || filterData.Filter_ChangeStateType == 4" class="col p-10">
        <app-sdesk-multi-select [data]="filterOptions.changeStates" [(selectedData)]="filterData.ChangeStateValues"></app-sdesk-multi-select>
      </div>
    </div>

    <div *ngIf="filterOptions.RequestTypeOptions.ChangeManagementEnabled" class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Planning Scheduled Start
      </div>
      <div class="col-4 p-10">

        <input style="display:none" matInput [matDatepicker]="dateFromPicker"
          [(ngModel)]="filterData.Planning_ScheduledStart_From">
          From
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <span>{{filterData.Planning_ScheduledStart_From | date}}
          <mat-datepicker #dateFromPicker [touchUi]="true"></mat-datepicker></span>
        </div>

        <div class="col-4 p-10">

        <input  style="display:none" class="form-control" matInput
          [matDatepicker]="dateToPicker" [(ngModel)]="filterData.Planning_ScheduledStart_To">
          To
        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <span>{{filterData.Planning_ScheduledStart_To | date}}
          <mat-datepicker #dateToPicker [touchUi]="true"></mat-datepicker></span>
      </div>
    </div>

    <div class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Order By
      </div>
      <div class="col-5 p-10">
        <select [(ngModel)]="filterData.OrderById" class="form-control" data-style="btn-white">
          <option *ngFor="let option of (isCmdbEnabled() ? columnOptionsCmdbEnabled : columnOptions)" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
      <div class="col-5 p-10">
        <select [(ngModel)]="filterData.OrderByAsc" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.orderByOptions" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
    </div>

    <div class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Columns <span class="text-danger">*</span>
      </div>
      <div class="col p-10">
        <app-sdesk-multi-select
            [data]="isCmdbEnabled() ? columnOptionsCmdbEnabled : columnOptions"
            [(selectedData)]="filterData.Columns">
        </app-sdesk-multi-select>
      </div>
    </div>

    <div class="row sdesk-grey-row" *ngIf="isCmdbEnabled()">
      <div class="col-2 formHeader alignMiddle p-10">
        Asset Type
      </div>
      <div class="col p-10">
        <app-sdesk-multi-select [data]="this.assetTypes" [(selectedData)]="this.filterData.AssetTypesEnabled"></app-sdesk-multi-select>
      </div>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">{{this.data.create ? 'Create' : 'Save'}}</div>
      <div *ngIf="!this.data.create" (click)="delete()" class="btn btn-cancel float-end">Delete</div>

    </div>

  </div>
</app-blade-right>


