import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiAdminTaskService} from '../../../../../services/api/admin/task-service-api/task-service-api.service';
import {Dialog} from '../../../../../shared/dialog/dialog';
import {OptionsApiService} from '../../../../../services/api/options/options-api.service';
import { ApiAdminGroupsService } from 'src/app/services/api/admin/admin-groups-api/admin-groups-api.service';
import {ToastrService} from "ngx-toastr";
import { SdeskHelper } from 'src/app/shared/helper/SdeskHelper';
@Component({
  selector: 'app-admin-task-lists-create-edit-dialog',
  templateUrl: './admin-task-lists-create-edit-dialog.component.html'
})

export class AdminTaskListsComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  options;

  isLoading = true;
  loadedId = null;
  taskToAdd = '';

  loadedData;

  constructor(private optionsApi: OptionsApiService,private toastr: ToastrService,
              private apiAdminTaskService: ApiAdminTaskService,private apiAdminGroupsService:ApiAdminGroupsService
  ) {}

  ngOnInit(): void {
    this.getoptions();
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        AgentId: null,
        Tasks: [],
        HaltClosure : false,
      }

      this.isLoading = false;

    }
  }

  addToArray(){
      this.loadedData.Tasks.push(
        {"TaskName" : this.taskToAdd, "Completed" : false, "Instructions": "", "HelpUrl":"", "CompletedBy":"", "CompletedDateTime":""});

      this.taskToAdd = "";
  };


  removeFromArray(index){
    this.loadedData.Tasks.splice(index, 1);
  }

  getData(){
    this.isLoading = true;
    this.apiAdminTaskService.get(this.loadedId).then(response => {
      this.loadedData = response;
      // Decode tasks from JSON
      this.loadedData.Tasks =  JSON.parse(this.loadedData.Tasks);

      // place the list of agents based on the group id
      if (SdeskHelper.isNotEmpty(this.loadedData.Tasks)) {
        for (let i = 0; i < this.loadedData.Tasks.length; i++) {
          let domain: any = this.loadedData.Tasks[i];

          // for each tasks, filter the agent options depending on the group
          // and put it on the "optionAgents" key, which is the one being used by the
          // "assigned to" (mapped to selected agent id)
          let optionAgents: any = [];
          this.optionsApi.AllOptions().then(response => {
            optionAgents = response ;
            if (SdeskHelper.isNotNullOrEmpty(domain.GroupId)) {
              this.apiAdminGroupsService.get(domain.GroupId)
              .then(r => {
                optionAgents = r.Members.map(item => {
                  return { id: item.Id, name: item.Name }
                })
                domain.optionAgents = optionAgents;
              });
            } else {
              domain.optionAgents = [];
            }
          });
        }
      }
      this.isLoading = false;
    })
  }

  getoptions(){
    this.optionsApi.AllOptions().then(response => {
      this.options = response      
    });
    
  }

  filterAgents(id,index){
    this.loadedData.Tasks[index].AgentId = ""
    this.apiAdminGroupsService.get(id)
      .then(r => {
        this.options.agent = r.Members.map(item => {
          return { id: item.Id, name: item.Name }
        })
      });
    this.isLoading = false;
  }

  close(){
    this.remove.emit(null);
  }

  save() {
    if (this.loadedData.Tasks.length > 0 && this.checkifTaskEmpty() == true) {
      this.toastr.warning(`Task List cannot be empty`, 'Error');
    }
    else {
      if (this.loadedId) {
        this.apiAdminTaskService.update(this.loadedId, this.loadedData).then(response => {
          this.remove.emit("Saved");
        })
      } else {
        this.apiAdminTaskService.create(this.loadedData).then(response => {
          this.remove.emit("Saved");
        })
      }
    }
  }

  checkifTaskEmpty(): boolean {
    return this.loadedData.Tasks.some(task => task.TaskName === "");
  }

  filterTaskAgents(task: any, resetAgentId = true) {
    if (resetAgentId === true) {
      task.AgentId = "";
    }
    task.optionAgents = this.options.agent;
    if (SdeskHelper.isNotNullOrEmpty(task.GroupId)) {
      this.apiAdminGroupsService.get(task.GroupId)
      .then(r => {
        task.optionAgents = r.Members.map(item => {
          return { id: item.Id, name: item.Name }
        })
      });
    } else {
      
    }
  }
}
