import { Injectable } from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiOnlineIndicatorService {

  private onlinePingApi = '/api/online';

  constructor(private api: ApiService) { }

  getOnline(){
    return this.api.get(this.onlinePingApi);
  }

}
