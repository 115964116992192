
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ChatApiService {

  constructor(private apiService: ApiService) {
  }

  listConversations(viewName: any) {
    return this.apiService.get(`/api/chat/conversations-view/${viewName}`);
  }

  getConversation(id: any) {
    return this.apiService.get(`/api/chat/conversations/${id}`);
  }

  pollConversationUpdates(id: any) {
    return this.apiService.get(`/api/chat/conversations/${id}/poll-updates`);
  }

  pollNotifications() {
    return this.apiService.get(`/api/chat/conversations/notifications`);
  }

  markRead(conversationId: any) {
    return this.apiService.post(`/api/chat/conversations/${conversationId}/reset-unread`);
  }

  sendReply(conversationId: any, reply: any) {
    return this.apiService.post(`/api/chat/conversations/${conversationId}/reply`, {Text: reply});
  }

  closeConversation(conversationId: any) {
    return this.apiService.post(`/api/chat/conversations/${conversationId}/close`);
  }

  assignToMe(conversationId: any) {
    return this.apiService.post(`/api/chat/conversations/${conversationId}/pickup`);
  }
  unassign(conversationId: any) {
    return this.apiService.post(`/api/chat/conversations/${conversationId}/unassign`);
  }

  reopenConversation(conversationId: any) {
    return this.apiService.post(`/api/chat/conversations/${conversationId}/reopen`);
  }
}



