import { Component, Input, OnInit, ViewContainerRef } from "@angular/core";
import { ApiAdminAssetTypesService } from "../../../../../services/api/admin/admin-asset-types-api/admin-asset-types-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import { AdminAssetTypesComponentDialog } from "./admin-asset-types-create-edit-dialog/admin-asset-types-create-edit-dialog.component";
import { ModalConfirmationComponent } from "src/app/shared/modal-confirmation/modal-confirmation.component";


@Component({
    selector: 'app-admin-asset-types',
    templateUrl: './admin-asset-types.html'
})

export class AdminAssetTypesComponent implements OnInit {

    @Input() requestTypeId;

    data;
    isLoading = true;

    constructor(private apiAdminAssetTypesService: ApiAdminAssetTypesService,
        private dialogService: DialogService,
        private viewReference: ViewContainerRef) {
}

    ngOnInit(): void {
        this.getData();

    }

  getData(){
    this.isLoading = true;
    this.apiAdminAssetTypesService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminAssetTypesComponentDialog, {id: null, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminAssetTypesComponentDialog, {id: id, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminAssetTypesService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }
}