

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminSamlService} from "../../../../../../services/api/admin/admin-saml/admin-saml.service";

@Component({
  selector: 'app-admin-saml-create-edit-dialog',
  templateUrl: './admin-saml-create-edit-dialog.component.html'
})

export class AdminSamlComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  domainToAdd = '';

  loadedData;

  constructor(
    private apiAdminSamlService: ApiAdminSamlService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        SSLCertificate: '',
        SSLCertificatePrivateKey: '',
        SamlSignOnUrl: '',
        SamlSignOutUrl: '',
        IdentityProviderCert: '',
        ApplyType: 2,
        IdentityProviderEntityId: '',
        ShowDebugErrors: 0,
        Domains: []
      }

      this.isLoading = false;

    }
  }

  applyTypes = [
    {id: 1, name: 'Apply to all users'},
    {id: 2, name: 'Apply to specific domains'}
  ];

  addDomain(){
    if(!this.loadedData.Domains.includes(this.domainToAdd)){
      this.loadedData.Domains.push(this.domainToAdd);
    }
  };


  removeDomain(index){
    this.loadedData.Domains.splice(index, 1);
  }

  getData(){
    this.isLoading = true;
    this.apiAdminSamlService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminSamlService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminSamlService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
