<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} SSOConfig</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Apply to <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="applyTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.ApplyType"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Apply to Domains <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">

        <div class="input-group">
          <input [(ngModel)]="domainToAdd" type="text" class="form-control" id="domainToAdd" placeholder="" autocomplete="off">
          <div (click)="addDomain()" class="btn btn-primary">Add</div>
        </div>

        <ul class="list-group">
          <li class="list-group-item" *ngFor="let domain of loadedData.Domains; let i = index">
            {{ domain }}
            <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="removeDomain(i)" aria-hidden="true"></i>
          </li>
        </ul>

      </div>
    </div>



    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        IDP Sign On URL <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.SamlSignOnUrl" type="text" class="form-control" id="SamlSignOnUrl" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        IDP Sign Out URL <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.SamlSignOutUrl" type="text" class="form-control" id="SamlSignOutUrl" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        IDP Cert <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <textarea rows="5" id="IdentityProviderCert" [(ngModel)]="loadedData.IdentityProviderCert" class="form-control" style="width: 100%;"></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        IDP Entity Id (Azure AD Identifier) <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.IdentityProviderEntityId" type="text" class="form-control" id="IdentityProviderEntityId" autocomplete="off">
      </div>
    </div>


    <div class="p-10 p-b-0">
      <h4>How to obtain the Azure AD SAML Details</h4>
    </div>
    <hr>
    <ol>
      <li>Login to Azure Active Directory</li>
      <li>Enterprise Application > New Application > Create your own application</li>
      <li>Call the application "SDesk" and select "Integrate any other application you don't find in the gallery" > Create</li>
      <li>Open the application > Single Sign On > Saml</li>
      <li>Input the following data:<br/>
        <strong>Identifier (Entity ID):</strong> SDesk-SAML2<br/>
        <strong>Reply URL (Assertion Consumer Service URL):</strong> https://<strong>YourDomain</strong>.sdesk.co.uk/ssoresponse<br/>
        <strong>Sign on URL :</strong> https://<strong>YourDomain</strong>.sdesk.co.uk/<br/>
      </li>
      <li>Download Certificate (Base64), edit with notepad and input that certificate information above in the IDP Cert field.</li>
      <li>Copy the <strong>IDP Sign On URL</strong> from Azure AD and insert it above</li>
      <li>Copy the <strong>Login URL</strong> from Azure AD and insert it above</li>
      <li>Copy the <strong>Logout URL</strong> from Azure AD and insert it above</li>
    </ol>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>



  </div>
</app-blade-right>
