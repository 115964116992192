import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminPriorityService {

  constructor(private apiService: ApiService) {
  }

  listAll(typeId) {
    return this.apiService.get(`/api/admin/type/${typeId}/priority`);
  }

  get(typeId, id) {
    return this.apiService.get(`/api/admin/type/${typeId}/priority/${id}`);
  }

  delete(typeId, id) {
    return this.apiService.delete(`/api/admin/type/${typeId}/priority/${id}`);
  }

  create(typeId, data) {
    return this.apiService.post(`/api/admin/type/${typeId}/priority`, data);
  }

  update(typeId, id, data) {
    return this.apiService.put(`/api/admin/type/${typeId}/priority/${id}`, data);
  }

  moveUp(typeId, id) {
    return this.apiService.patch(`/api/admin/type/${typeId}/priority/${id}/changeorder/up`);
  }

  moveDown(typeId, id) {
    return this.apiService.patch(`/api/admin/type/${typeId}/priority/${id}/changeorder/down`);
  }

}



