

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminServicesService} from "../../../../../services/api/admin/admin-services-api/admin-services-api.service";
import {AdminServiceComponentDialog} from "./admin-services-create-edit-dialog/admin-services-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-admin-services',
  templateUrl: './admin-services.html'
})
export class AdminServiceComponent implements OnInit {

  data;
  isLoading = true;

  constructor(private apiAdminServicesService: ApiAdminServicesService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminServicesService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminServiceComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminServiceComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminServicesService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
