import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {saveAs} from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private apiService: ApiService) {
  }

  downloadAttachment(attachmentId) {

    // returns the whole response
    this.apiService.get(`/download/${attachmentId}`, null, false, "arraybuffer").then(response => {
      let fileName = response.headers.get('x-filename');
      let contentType = response.headers.get('content-type');
      let data = response.body;

      const blob = new Blob([data], {type: contentType});

      saveAs(blob, fileName);

    })

  }

  downloadAttachmentFromUrl(uri) {

    // returns the whole response
    this.apiService.get(uri, null, false, "arraybuffer").then(response => {
      let fileName = response.headers.get('x-filename');
      let contentType = response.headers.get('content-type');
      let data = response.body;

      const blob = new Blob([data], {type: contentType});

      saveAs(blob, fileName);

    })

  }
}
