import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminImpactService} from "../../../../../services/api/admin/admin-impact-api/admin-impact-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminImpactComponentDialog} from "../admin-impact/admin-impact-create-edit-dialog/admin-impact-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {ApiAdminAutoPriorityService} from "../../../../../services/api/admin/admin-auto-priority/admin-auto-priority.service";
import {OptionsApiService} from "../../../../../services/api/options/options-api.service";

@Component({
  selector: 'app-admin-auto-priority',
  templateUrl: './admin-auto-priority.component.html',
  styleUrls: ['./admin-auto-priority.component.css']
})
export class AdminAutoPriorityComponent implements OnInit {

  @Input() requestTypeId;

  autoPriorityData;
  options;

  isLoading = true;
  isLoadingOptions = true;

  constructor(private apiAutoPriorityService: ApiAdminAutoPriorityService,
              private apiOptions: OptionsApiService) {
  }

  ngOnInit(): void {
    this.getData();
    this.getOptions();
  }

  getData() {
    this.isLoading = true;
    this.apiAutoPriorityService.get(this.requestTypeId).then(response => {
      this.autoPriorityData = response;
      this.isLoading = false;
    })
  }

  getOptions() {
    this.isLoadingOptions = true;
    this.apiOptions.DynamicOptions(this.requestTypeId).then(response => {
        this.options = response;
        this.isLoadingOptions = false;
      }
    )
  }

  update() {
    this.isLoading = true;
    this.apiAutoPriorityService.update(this.requestTypeId, this.autoPriorityData).then(response => {
      this.getData();
      this.isLoading = false;
    })
  }


}
