import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.css']
})
export class TagsInputComponent implements OnInit {

  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() formPlaceholder: string = 'Add Tag..';

  
  formText = '';

  constructor() { }

  ngOnInit(): void {
  
  }

  addTag() {
    if (this.formText.trim() !== '') {
      if(!this.model.includes(this.formText)){
        this.model.push(this.formText);
        this.modelChange.emit(this.model);
      }
      this.formText = '';
    }
  }

  removeTag(index) {
    this.model.splice(index, 1);
    this.modelChange.emit(this.model);
  }

}