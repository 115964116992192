<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading && !isLoadingRequestTypes" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Create Link Configuration</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Advanced Link?
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.IsAdvanced" class="form-check-input" id="IsAdvanced" type="checkbox"><label for="IsAdvanced" class="p-l-10"></label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Left Request Type
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="requestTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.LeftRequestTypeId"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

    <div *ngIf="loadedData.IsAdvanced" class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Description (Left to Right)
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.LeftToRight" type="text" class="form-control" id="LeftToRight" placeholder="" autocomplete="off">
      </div>
    </div>

    <div *ngIf="loadedData.IsAdvanced" class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Description (Right to Left)
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.RightToLeft" type="text" class="form-control" id="RightToLeft" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Right Request Type
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="requestTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.RightRequestTypeId"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>



    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
