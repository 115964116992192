import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Dialog } from "../../shared/dialog/dialog";
import { SelfServiceApiService } from "../../services/api/self-service/self-service-api.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-self-service-new-ticket',
  templateUrl: './self-service-new-ticket.component.html',
  styleUrls: ['./self-service-new-ticket.component.css']
})
export class SelfServiceNewTicketComponent implements Dialog {

  @Input() data; // create // filterId // requestTypeId (Required on create)
  @Output() remove = new EventEmitter<any>();

  requestTypes;
  selectedRequestTypeId;

  requestSubject;
  requestBody;
  loading = false;

  constructor(private apiSelfService: SelfServiceApiService, private router: Router) {
    this.getRequestTypes()
  }

  ngOnInit(): void {
  }

  getRequestTypes() {
    this.apiSelfService.getAvailableRequestTypes().then(response => this.requestTypes = response);
  }

  close() {
    this.remove.emit(null);
  }

  save() {

    if (this.loading) return;
    this.loading = true;

    this.apiSelfService.createRequest(this.selectedRequestTypeId, this.requestSubject, this.requestBody).then(response => {
      // Redirect to ticketId: response.Id
      this.router.navigate(['/ticket/', response.Id]);
      this.close();
    }).catch(error => {
      console.error(error);
      this.loading = false;
    });
  }
}
