import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../../../../shared/dialog/dialog";
import {RequestApiService} from "../../../../services/api/request/request-api.service";

@Component({
  selector: 'app-request-override-priority-modal',
  templateUrl: './request-override-priority-modal.component.html',
  styleUrls: ['./request-override-priority-modal.component.css']
})
export class RequestOverridePriorityModalComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  reason;

  constructor(private requestApi: RequestApiService) { }

  ngOnInit(): void {
    console.log("overridePriority",this.data)
  }

  closeModal(){
    this.remove.emit(null);
  }

  save(){
    // this.requestApi.overridePriority(this.data, this.reason).then(success => {
    //   this.remove.emit("Success");
    // })
   this.remove.emit(this.reason);
  }

}
