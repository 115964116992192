<app-modal [modalSize]="'modal-small'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Convert Request</h5>
  </div>
  <div class="modal-body">
    <p class="text-muted">Select the new request type</p>
    <div class="table-responsive">
      <table class="table align-middle tableStyle">
        <tbody>
        <tr *ngFor="let type of this.options " class="divLink" [class.sdesk-filter-selected]="newTypeId == type.id" (click)="newTypeId = type.id">
          <td>
            <i *ngIf="newTypeId != type.id" class="far fa-check-circle"></i>
            <i *ngIf="newTypeId == type.id" class="fas fa-check-circle"></i>
            {{type.name}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary" [class.disabled]="!newTypeId">Convert</div>
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
