import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminWebhookService} from "../../../../../services/api/admin/admin-webhook-api/admin-webhook-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminWebhookComponentDialog} from "./admin-webhooks-create-edit-dialog/admin-webhooks-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-webhooks',
  templateUrl: './admin-webhooks.html'
})
export class AdminWebhookComponent implements OnInit {

  @Input() filterRequestTypeId: number;

  data;
  isLoading = true;

  browserHostname = window.location.hostname

  constructor(private apiAdminWebhookService: ApiAdminWebhookService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
    this.getData();
  }

  filterData() {
    if (this.filterRequestTypeId) {
      return this.data.filter(row => row.RequestTypeId == this.filterRequestTypeId)
    } else {
      return this.data;
    }
  }

  getData() {
    this.isLoading = true;
    this.apiAdminWebhookService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create() {
    // Replace null with Component
    this.dialogService.createDialog(AdminWebhookComponentDialog, {
      createNewWithRequestTypeId: this.filterRequestTypeId,
      id: null
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id) {
    // Replace null with Component
    this.dialogService.createDialog(AdminWebhookComponentDialog, {
      createNewWithRequestTypeId: null,
      id: id
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminWebhookService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }


}
