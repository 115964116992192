

import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminResolutionCodesComponentDialog} from "./admin-resolution-codes-create-edit-dialog/admin-resolution-codes-create-edit-dialog.component";
import {ApiAdminResolutionCodeService} from "../../../../../services/api/admin/admin-resolution-codes-api/admin-resolution-codes-api.service";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-resolution-codes',
  templateUrl: './admin-resolution-codes.html'
})
export class AdminResolutionCodesComponent implements OnInit {

  @Input() requestTypeId;

  data;
  isLoading = true;

  constructor(private apiAdminResolutionCodeService: ApiAdminResolutionCodeService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.isLoading = true;
    this.apiAdminResolutionCodeService.listAll(this.requestTypeId).then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminResolutionCodesComponentDialog, {id: null, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminResolutionCodesComponentDialog, {id: id, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminResolutionCodeService.delete(this.requestTypeId, id).then(response => this.getData())
      }, cancelled => null)
  }



}
