<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading" class="row p-l-20 mt-2">
  <div class="col-md-3">
    <div class="sectionBox p-0" style="margin-left: -17px;">

    <div class="btn-group sdesk-button-group-100">
      <a  (click)="createPortal()" class="btn btn-lightblue-block" aria-current="page">
        <span class="pull-left fa fa-plus" style="color: #93abc0;"></span>
        Portal
      </a>
      <a  (click)="createCategory()" class="btn btn-lightblue-block" aria-current="page">
        <span class="pull-left fa fa-plus" style="color: #93abc0;"></span>
        Category
      </a>
      <a  (click)="createArticle()" class="btn btn-lightblue-block" aria-current="page">
        <span class="pull-left fa fa-plus" style="color: #93abc0;"></span>
        Article
      </a>
    </div>

    <div *ngFor="let x of this.data?.Portals " class="kbTree-Portal">
      <span class="fa fa-building icon"></span>
      {{x.PortalDescription ? x.PortalDescription : x.HostName }}
      <span *ngIf="!x.IsHostNameVerified" class="text-danger" style="font-size: 10px; font-weight: bold">(Verification Pending)</span>
      <span (click)="editPortal(x.Id)" class="kbTree-cog fa fa-cog divLink p-l-10"></span>
    </div>
  </div>
    <div class="sectionBox p-0" style="margin-left: -17px; margin-top: 10px">

      <div class="kbTree-KnowledgeBase p-t-15">
        <span class="fa fa-book icon"></span>
        Knowledge Base
      </div>
      <div *ngFor="let category of data?.Categories">
        <div [class.published]="category.Published" [class.unpublished]="!category.Published" class="kbTree-Folder">
          <i class="fa fa-folder fa-15x"></i>
          {{category.Name}}
          <span (click)="editCategory(category.Id)" class="kbTree-cog fa fa-cog divLink"></span>
          <span (click)="expandedCategories.toggle(category.Id)"
                [ngClass]="expandedCategories.isSelected(category.Id) ? 'fa fa-minus' : 'fa fa-plus'"
                class="float-end fa-15x kbTree-expand divLink"></span>
        </div>

        <div *ngIf="this.expandedCategories.isSelected(category.Id)">
          <div (click)="editArticle(article.Id)" *ngFor="let article of getArticles(category.Id)"
               [class.published]="article.Published"
               [class.unpublished]="!article.Published" class="kbTree-Article divLink">
            <i class="fa fa-file"></i>
            {{article.Title}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" style="padding-right: 10px;">
    <div class="row" style="margin-bottom: 10px;">
      <div class="col-md-8"></div>
      <div class="col-md-4">
        <div class="wrap" style="position: relative;">
          <div class="search" style=" width: 100%;
                    position: relative;
                    display: flex;">
            <input type="text" [(ngModel)]="filterField" (input)="Search()" class="searchTerm"
              placeholder="Search an article..." style="width: 100%;
                       border: 1px solid #b4b4b4;
                       border-right: none;
                       padding: 5px;
                       min-height: 20px;
                       border-radius: 5px 0 0 5px;">
            <button type="submit" class="searchButton" style="width: 40px;
                       height: 36px;
                       border: 1px solid #b4b4b4;
                       /* background: #00B4CC; */
                       text-align: center;
                       color: #b4b4b4;
                       border-radius: 0 5px 5px 0;
                       cursor: pointer;
                       font-size: 18px;">
              <i class="fa fa-search"></i>
            </button>
          </div>
          <ul *ngIf="filteredArticles.length > 0 && filterField !== ''" style="list-style: none;padding-left: 0;position: absolute;
                      z-index: 9;
                      width: 310px;">
            <li *ngFor="let article of filteredArticles" [class.published]="article?.Published"
              [class.unpublished]="!article.Published" class="kbTree-Article divLink"
              style="padding-left: 15px;cursor: pointer;" (click)="editArticle(article.Id)"> <i
                class="fa fa-file"></i>{{article?.Title}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="sectionBox" *ngIf="showEditArticle">
      <app-loading-spinner *ngIf="isLoadingArticle" ></app-loading-spinner>
      <div *ngIf="!isLoadingArticle && showEditArticle">

        <div>
          <button (click)="saveArticle()" class="btn btn-primary">Save</button>
          <button (click)="cancel()" class="btn btn-cancel">Cancel</button>
          <button (click)="deleteArticle()" *ngIf="selectedArticleId" class="btn btn-cancel float-end">Delete Article</button>
        </div>

        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10">
            Name <span class="text-danger">*</span>
          </div>
          <div class="col-sm p-10">
            <input (keyup)="updatedTitle()" [(ngModel)]="selectedArticle.Title" type="text" class="form-control" id="Title" placeholder="Title" autocomplete="off">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10">
            Category <span class="text-danger">*</span>
          </div>
          <div class="col-sm p-10">
            <ng-select [items]="data?.Categories"
                       bindLabel="Name"
                       bindValue="Id"
                       [(ngModel)]="selectedArticle.CategoryId">
            </ng-select>

          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10">
            Customer
          </div>
          <div class="col-sm p-10">
            <ng-select [items]="customers"
                       bindLabel="Name"
                       bindValue="Id"
                       [(ngModel)]="selectedArticle.CustomerId" [multiple]="true" (ngModelChange)="onModelChange($event)">
            </ng-select>

          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10">
            Tags
          </div>
          <div class="col-sm p-10">
            <app-tags-input [(model)]="selectedArticle.Tags"></app-tags-input>

          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10">
            Url
          </div>
          <div class="col-sm p-10">
            <input [(ngModel)]="selectedArticle.Url" disabled type="text" class="form-control" id="Url" autocomplete="off">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10">
            Published
          </div>
          <div class="col-sm p-10">
            <input [(ngModel)]="selectedArticle.Published" class="form-check-input" id="Published" type="checkbox"><label for="Published" class="p-l-10"></label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <quill-editor [modules]="modules" [(ngModel)]="selectedArticle.Body" [styles]="{height: '250px'}"  placeholder="New Article"></quill-editor>         
          </div>
        </div>  
      </div>
    </div>
  </div>
</div>


