import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { AttachmentService } from "../../../../../services/api/attachment/attachment.service";
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InstanceParamsService } from "../../../../../services/instance-params/instance-params.service";
import { RequestApiService } from "../../../../../services/api/request/request-api.service";
import { DialogService } from "../../../../../services/dialog-service/dialog.service";
import { ModalConfirmationComponent } from "../../../../../shared/modal-confirmation/modal-confirmation.component";


@Component({
  selector: 'app-request-thread-item',
  templateUrl: './request-thread-item.component.html',
  styleUrls: ['./request-thread-item.component.css']
})
export class RequestThreadItemComponent implements OnInit {

  @Input() threadItem;
  @Input() threadItemAttachments = [];
  @Input() isRequestClosed: boolean;
  @Output() refreshData = new EventEmitter<any>();

  instanceParams: any;
  originalBodyText: string;
  isSaving: boolean = false;
  isTextChanged: boolean = false;

  constructor(private attachmentService: AttachmentService, private authService: AuthenticationService, private sanitizer: DomSanitizer,
    private instanceParamsService: InstanceParamsService, private requestApi: RequestApiService,  private dialogService: DialogService, 
    private viewReference: ViewContainerRef) 
    { }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getClassName() {
    switch (this.threadItem.ThreadType) {
      case 1:
        return 'inboundEmail';
      case 2:
        return 'outboundEmail'
      case 3:
        return 'comment'
      case 4:
        return 'resolution'
    }
  }

  getFromText() {

    if (this.threadItem.SystemTriggered) {
      return 'Service Desk Automation'
    }

    if (this.threadItem.AgentName) {
      return this.threadItem.AgentName;
    }

    return `${this.threadItem.FromName}  (${this.threadItem.FromEmail})`

  }

  downloadAttachment(attachmentId) {
    this.attachmentService.downloadAttachment(attachmentId);
  }

  generateImages() {

    const authToken = this.authService.returnAuthToken();
    let str = this.threadItem.BodyText;

    this.threadItemAttachments.forEach(attachment => {
      const name = attachment.AttachmentName;
      const id = attachment.AttachmentId;

      var regex = new RegExp("src=\".*cid:" + name + "@.*?\"");

      console.log(regex);

      str = str.replace(regex, `src="/download/${id}?auth=${authToken}"`);
    })

    this.threadItem.BodyText = str;
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";

    if (m == 0 && h == 0 && s != 0) {
      return sDisplay;
    } else {
      return hDisplay + mDisplay;
    }
  }

  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });
    this.generateImages();
  }

  // Toggle edit mode
  editMessage() {
    this.originalBodyText = this.threadItem.BodyText;
    this.isTextChanged = false;
    this.threadItem.isEditing = true;
  }

  onTextChange() {
    this.isTextChanged = this.threadItem.BodyText !== this.originalBodyText;
  }

  // Save edited message
  saveEdit() {
    this.isSaving = true;
    this.requestApi.threadUpdateComment(this.threadItem.RequestId, this.threadItem.BodyText, this.threadItem.Id).then(response => {
      this.isSaving = false;
      this.threadItem.isEditing = false;
      this.isTextChanged = false;
    })
  }

  // Delete comment 
  deleteComment() {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => { 
        this.requestApi.threadDeleteComment(this.threadItem.RequestId, this.threadItem.Id).then(response => 
          this.refreshParent())
      }, cancelled => null)
  }

  // Cancel editing
  cancelEdit() {
    this.threadItem.BodyText = this.originalBodyText;
    this.isTextChanged = false;
    this.threadItem.isEditing = false;
  }

  refreshParent() {
    this.refreshData.emit();
  }
}