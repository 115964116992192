<div class="m-t-10 m-b-10">

  <h4 class="p-10"><strong>Mass Communication Topics</strong></h4>

  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
  <div class="sectionBox p-0">
    <div *ngIf="!isLoading" class="table-responsive p-0">
      <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th class="col-7">Topic Subject</th>
          <th>Status</th>
          <th>Owner</th>
          <th>Recipients</th>
          <th>Updates</th>
          <th>Last Update</th>

          <th>
            <div (click)="create()" class="btn btn-primary float-end">New Topic</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of this.data " class="divLink" (click)="openTopic(row.Id)">
          <td>{{row.TopicSubject}}</td>
          <td>{{row.ClosedDateTime ? 'Closed' : 'Open'}}</td>
          <td>{{row.UserName}}
            <app-user-online-indicator [userId]="row.UserId"></app-user-online-indicator>
          </td>
          <td><span>{{row.SubscriberCount}}</span></td>
          <td>{{row.UpdatesCount}}</td>
          <td>
            <app-friendly-time [utcTimeData]="row.LastUpdateDateTime" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time>
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
