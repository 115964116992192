
import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiAssetRegisterService {

  apiUri = '/api/asset-register'
  apiUri1 = '/api'
  apiUri2 = 'asset-register'

  constructor(private apiService: ApiService) {
  }

  listAll() {
    return this.apiService.get(this.apiUri);
  }

  get(id) {
    return this.apiService.get(`${this.apiUri}/${id}`);
  }

  delete(id) {
    return this.apiService.delete(`${this.apiUri}/${id}`);
  }

  create(data,typeId) {
    return this.apiService.post(`/api/${typeId}/asset-register`, data);
  }

  update(id, data) {
    return this.apiService.put(`${this.apiUri}/${id}`, data);
  }


}



