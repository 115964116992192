<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<div class="row p-l-20 p-r-20 mb-3">
  <div class="col-sm-6 m-b-10 float-end">
    <input [(ngModel)]="searchTerm" type="text" class="form-control" id="Search" placeholder="Search..."
      autocomplete="off">
  </div>
  <div class="col-md-6">
    <div (click)="create()" class="btn btn-primary float-end">Create</div>
  </div>
</div>
<div class="row p-l-20 p-r-20 mb-3">
  <div class="col-md-4">
 <p class="page-entries">Showing {{fromRowscount}} to <span *ngIf="!isLastPage()">{{showingRowscount}}</span><span *ngIf="isLastPage()">{{recordCount}}</span> of {{recordCount}} groups</p> 

 </div>
 <div class="col">
  <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="recordCount" [rowsPerPageOptions]="[50,100,500]"></p-paginator>
 
 </div>
</div>
<div class="row p-l-20 p-r-20 mb-3">
<div class="col-md-6">
  <div class="btn-group" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-secondary" style="font-size: 14px;" (click)="this.currentFilter = 'all'"
      [class.active]="this.currentFilter == 'all'">All <span class="group-count">({{allCount}})</span></button>
    <button type="button" class="btn btn-secondary" style="font-size: 14px;" (click)="this.currentFilter = 'active'"
      [class.active]="this.currentFilter == 'active'">Active <span class="group-count">({{activeCount}})</span></button>
    <button type="button" class="btn btn-secondary" style="font-size: 14px;" (click)="this.currentFilter = 'inactive'"
      [class.active]="this.currentFilter == 'inactive'">Inactive <span
        class="group-count">({{inactiveCount}})</span></button>
  </div>
</div>
</div>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">
  <table class="table align-middle tableStyle">
    <thead>
      <tr>
        <th class="">Groups
        
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of this.getFilteredData() ">
        <td><span class="divLink" (click)="edit(row.Id)">{{row.Name}}</span>
          <i class="far fa-trash-alt float-end m-r-20 divLink m-t-5" (click)="delete(row.Id)"></i>
          <i class="fas fa-cog float-end m-r-20 divLink m-t-5" (click)="edit(row.Id)"></i>
          <span *ngIf="row.Enabled === 0" (click)="recover(row.Id)" class="float-end divLink"
            style="background: lightgray; padding: 2px 10px 2px 10px; border-radius: 10px; margin-right: 5px; margin-left: 5px">Recover</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>