<app-modal [modalSize]="'modal-small'" *ngIf="!isLoading && !isLoadingOptions">
  <div class="modal-header">
    <h5 class="modal-title">{{this.loadedId ? 'Edit' : 'Create'}} Location</h5>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Timezone <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="options"
                   [(ngModel)]="loadedData.Timezone"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary">Save</div>
    <div (click)="close()" class="btn btn-cancel">Cancel</div>
  </div>
</app-modal>
