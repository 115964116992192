

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminSlaHolidaysService} from "../../../../../../services/api/admin/admin-sla-holidays-api/admin-sla-holidays-api.service";

@Component({
  selector: 'app-admin-sla-holidays-create-edit-dialog',
  templateUrl: './admin-sla-holidays-create-edit-dialog.component.html',
  styleUrls: ['./admin-sla-holidays-create-edit-dialog.component.css']
})

export class AdminSlaHolidaysComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedData;
  loadedId = null;
  minDate: Date; // just in case theres a rule that user can only select a date greater than the current date, we can use this as param on the calendar component

  constructor(
    private apiAdminSlaHolidaysService: ApiAdminSlaHolidaysService
  ) {}

  ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);  // Set minDate to tomorrow

    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

    // Load Default Data
    this.loadedData = {
      Date: ''
    }
    this.isLoading = false;
    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminSlaHolidaysService.get(this.loadedId).then(response => {
      this.loadedData = response;

      // conver the date
      let dateString = this.loadedData.Date;
      let parts = dateString.split("-");
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      this.loadedData.Date = date;

      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.loadedData.Date = this.convertdate(this.loadedData.Date);
      this.apiAdminSlaHolidaysService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {

      this.loadedData.Date = this.convertdate(this.loadedData.Date);
      this.apiAdminSlaHolidaysService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }
  }

  convertdate(valueDate) {
    let date = new Date(valueDate);
    // Adjust the date to the desired output format "YYYY-MM-DD"
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
    let day = String(date.getDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
    console.log("formattedDate", formattedDate);
    return formattedDate;
  }
}
