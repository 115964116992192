<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal *ngIf="!isLoading" [modalSize]="'modal-small'">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Manage API Keys</h3>
  </div>
  <hr>

  <div class="container p-20">

    <div *ngIf="showApiKeySecretMessage" class="alert alert-warning" role="alert">
      <i class="fas fa-exclamation-triangle"></i> API key is only visible until this window is closed.
    </div>

    <div class="row">

      <div class="row">
        <div class="col-2"><strong>Api Key:</strong></div>
        <div class="col-10" style="word-break: break-all;">{{this.apiKeyData.ApiKey}}</div>
      </div>

      <div *ngIf="this.apiKeyData.ApiKeySet" class="row">
        <div class="col-5"><strong>Api Key Generated on:</strong></div>
        <div class="col">{{this.apiKeyData.ApiKeyGenerationDate}}</div>
      </div>
    </div>

    <div class="m-t-20">
      <div *ngIf="!showApiKeySecretMessage" (click)="this.createApiKey()" class="btn btn-primary"> {{this.apiKeyData.ApiKeySet ? 'Regenerate API Key' : 'Create API Key' }}</div>
    </div>


  </div>
</app-modal>
