import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../shared/dialog/dialog";
import {ApiAdminCustomerPortalsService} from "../../../../../services/api/admin/admin-customer-portal/admin-customer-portal.service";
import {ApiService} from "../../../../../services/api/api.service";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-admin-customer-portal-category-create-edit-dialog',
  templateUrl: './admin-customer-portal-category-create-edit-dialog.component.html',
  styleUrls: ['./admin-customer-portal-category-create-edit-dialog.component.css']
})

export class AdminCustomerPortalCategoryCreateEditDialogComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;
  errorMessage = '';

  loadedData;

  constructor(
    private apiAdminCustomerPortalsService: ApiAdminCustomerPortalsService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  deleteCategory(){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomerPortalsService.deleteCategory(this.loadedId).then(
          response => {
            // Close the Dialog and emit a value. If a value is emited it will refresh the parent.
            this.remove.emit("Deleted")
          })
      }, cancelled => null)
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomerPortalsService.getCategory(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if (!this.loadedData.Name || !this.loadedData.Description) {
      this.errorMessage = 'Name and Description are required.';
      return;
    }
    if(this.loadedId){
      this.apiAdminCustomerPortalsService.updateCategory(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminCustomerPortalsService.createCategory(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}

