<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading && !isOptionsLoading" size="blade-medium">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} User</h3>
  </div>
  <hr>

  <div class="col-12 m-b-20 ">
    <ul class="nav dialogTabs">
      <li class="nav-item divLink" (click)="selectedTab = 'details'">
        <a class="nav-link-thread" [class.active]="selectedTab == 'details'">
          User Details
        </a>
      </li>

      <li *ngIf="loadedData.Agent" class="nav-item divLink" (click)="selectedTab = 'permissions'">
        <a class="nav-link-thread" [class.active]="selectedTab == 'permissions'">
          Agent Permissions
        </a>
      </li>

    </ul>
  </div>
  <div *ngIf="selectedTab == 'details'" class="container p-0">

    <div *ngIf="loadedData.CreatedUsingAzureSyncId" class="alert alert-success" role="alert">
      This user is being synchronised from Azure AD
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Email Address <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <!-- Disabled if LoadedId is set. LoadedId is set on edit user -->
        <input [(ngModel)]="loadedData.EmailAddress" [disabled]="this.loadedId" type="text" class="form-control" id="EmailAddress" placeholder="EmailAddress" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Phone Number <span class="text-danger"></span>
      </div>
      <div class="col-sm p-10">
        <!-- Disabled if LoadedId is set. LoadedId is set on edit user -->
        <input [(ngModel)]="loadedData.PhoneNumber" [disabled]="loadedData.CreatedUsingAzureSyncId" type="tel" class="form-control" id="PhoneNumber" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Preferred Contact Method
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="preferredContactMethods"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.PreferredContactMethod"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

    <hr>

    <div *ngIf="this.environmentParams.ShowExtendedUserParameters">
      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Job Title
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.JobTitle"  [disabled]="loadedData.CreatedUsingAzureSyncId" type="text" class="form-control" id="JobTitle" placeholder="" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Department
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.Department" [disabled]="loadedData.CreatedUsingAzureSyncId" type="text" class="form-control" id="Department" placeholder="" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Line Manager
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.LineManager" [disabled]="loadedData.CreatedUsingAzureSyncId" type="text" class="form-control" id="LineManager" placeholder="" autocomplete="off">
        </div>
      </div>

      <hr>

      <div *ngFor="let customField of this.loadedData.CustomFields" class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          {{customField.Name}}<span *ngIf="customField.Required" class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <ng-container *ngIf="customField.FieldType === 2">
            <app-custom-field-dropdown
              (optionOutput)="setCustomFieldValue(customField, $event)"
              [customFieldId]="customField.CustomFieldId"
              [customFieldOptions]="customField.Options"
              [selectedLevelValues]="customField.LevelValues"> 
  
            </app-custom-field-dropdown>
          </ng-container>
          <ng-container *ngIf="customField.FieldType === 1">
            <!-- Show textbox for FieldType 1 -->
            <input [(ngModel)]="customField.Value" type="text" class="form-control" [id]="'customField_' + customField.CustomFieldId" [placeholder]="'Enter ' + customField.Name" autocomplete="off" (input)="setCustomFieldValue(customField, $event.target.value)">
          </ng-container>
        </div>
      </div>
      
    </div>
    <hr>
    <div class="row" *ngIf="!loadedData.Agent">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Link to Customer
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="options.customer"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.CustomerId"
                   [clearable]="true"
                   [disabled]="loadedData.Agent">
        </ng-select>
      </div>
      <hr>
    </div>

    <div class="row" [ngClass]="{'roles-section': !loadedUserData.Admin}">
      <div class="col-sm-3 formHeader p-10">
        Roles
      </div>
      <div class="col-sm p-10">
        <div><input [(ngModel)]="loadedData.AllowPortalLogin" class="form-check-input" id="AllowPortalLogin" type="checkbox"><label for="AllowPortalLogin" class="p-l-10">Allow Portal Login</label></div>
        <div><input [(ngModel)]="loadedData.Agent" (change)="toggledAgent()" class="form-check-input" id="AgentCheckbox" type="checkbox"><label for="AgentCheckbox" class="p-l-10">Agent</label></div>
        <div><input [(ngModel)]="loadedData.Reporting" [disabled]="!loadedData.Agent" class="form-check-input" id="Reporting" type="checkbox"><label for="Reporting" class="p-l-10">Reporting</label></div>
        <div><input [(ngModel)]="loadedData.Admin" [disabled]="!loadedData.Agent" class="form-check-input" id="Admin" type="checkbox"><label for="Admin" class="p-l-10">Admin</label></div>
        <div><input [(ngModel)]="loadedData.ChangeManager" [disabled]="!loadedData.Agent" class="form-check-input" id="ChangeManager" type="checkbox"><label for="ChangeManager" class="p-l-10">Change Manager</label></div>
        <div><input [(ngModel)]="loadedData.Permission_MassComms" [disabled]="!loadedData.Agent" class="form-check-input" id="Permission_MassComms" type="checkbox"><label for="Permission_MassComms" class="p-l-10">Mass Comms Permission</label></div>
        <div><input [(ngModel)]="loadedData.AssetManagementViewOnly" [disabled]="!loadedData.Agent" class="form-check-input" id="Asset_View" type="checkbox"><label for="Asset_View" class="p-l-10">Asset Management View</label></div>
        <div><input [(ngModel)]="loadedData.AssetManagementAdmin" [disabled]="!loadedData.Agent" class="form-check-input" id="Asset_Admin" type="checkbox"><label for="Asset_Admin" class="p-l-10">Asset Management Admin</label></div>
        <div><input [(ngModel)]="loadedData.IsAllTicketsFromAllGroups" [disabled]="!loadedData.Agent || !loadedData.Admin" class="form-check-input" id="Ticket_View" type="checkbox"><label for="Asset_Admin" class="p-l-10">View All Tickets From All Groups</label></div>
      </div>
      <hr>
    </div>

    <div *ngIf="loadedData.Agent" class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Agent Groups
      </div>
      <div class="col-sm p-10">
        <app-sdesk-multi-select [data]="options.group" [(selectedData)]="loadedData.AgentGroups"></app-sdesk-multi-select>
      </div>
    </div>


  </div>
  <div *ngIf="selectedTab == 'permissions'" class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Request Types
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="requestTypePermissions"
                   bindLabel="name"
                   bindValue="id"
                   (change)="checkRequestType()"
                   [(ngModel)]="loadedData.AgentPermission_Type"
                   [clearable]="false">
        </ng-select>

        <app-sdesk-multi-select *ngIf="this.options.type && loadedData.AgentPermission_Type == 2" [data]="options.type" [(selectedData)]="loadedData.AgentPermission_SelectedRequestTypes"></app-sdesk-multi-select>


        <div *ngIf="isDropdownEmpty" class="error-message" style="color: red;">
          Please select a Request Type
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Customer
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="customerPermissions"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.AgentPermission_Customer"
                   [clearable]="false">
        </ng-select>

        <app-sdesk-multi-select  *ngIf="this.options.customer && loadedData.AgentPermission_Customer == 2" [data]="options.customer"
                                 [(selectedData)]="loadedData.AgentPermission_SelectedCustomers"></app-sdesk-multi-select>


      </div>
    </div>


    <div  class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Group
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="groupPermissions"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.AgentPermission_Group"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>


  </div>

  <div class="m-t-20">
    <div (click)="save()" class="btn btn-primary">Save</div>
    <div (click)="close()" class="btn btn-cancel">Cancel</div>
  </div>


</app-blade-right>
