<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Service</h3>
  </div>
  <hr>
  <div class="container p-0">


    <!-- Dialog Tabs -->
    <div class="col-12 m-b-20 ">
      <ul class="nav dialogTabs">
        <li class="nav-item divLink" (click)="selectedTab = 'details'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'details'">
            Service Details
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'contact'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'contact'">
            Contact Details
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'sla'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'sla'">
            SLA
          </a>
        </li>

      </ul>
    </div>

    <!-- Tab Content -->

    <div *ngIf="selectedTab == 'details'">
      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Vendor
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="vendors"
                     bindLabel="Name"
                     bindValue="Id"
                     [(ngModel)]="loadedData.VendorId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Customer
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="customers"
                     bindLabel="Name"
                     bindValue="Id"
                     [(ngModel)]="loadedData.CustomerId"
                     (ngModelChange)="changeCustomer()"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Location
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="customerLocations"
                     bindLabel="Name"
                     bindValue="Id"
                     [(ngModel)]="loadedData.CustomerLocationId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Tags
        </div>
        <div class="col-sm p-10">
          <div class="tagsContainer">
            <div *ngFor="let tag of loadedData.Tags; let i = index" class="sdeskTags">{{tag}} <i (click)="removeTag(i)"
                                                                                               class="fas fa-times divLink"></i>
            </div>

            <input [(ngModel)]="tagInputField" class="tagsInput" type="text" placeholder="Add Tag..."
                   (keyup.enter)="addTag()">

          </div>
        </div>
      </div>


    </div>

    <div *ngIf="selectedTab == 'contact'">

      <p><strong>Major Incident Contact</strong></p>
      <p>When there is a Major Incident affecting this Service, the below contact will be notified:</p>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Contact Name
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.ContactName" type="text" class="form-control" id="ContactName" placeholder="" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Contact Email Address
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.ContactEmailAddress" type="email" class="form-control" id="ContactEmailAddress" placeholder="" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Contact Mobile Number (Used for SMS Notifications)
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.PhoneNumber" type="email" class="form-control" id="PhoneNumber" placeholder="" autocomplete="off">
        </div>
      </div>


    </div>

    <div *ngIf="selectedTab == 'sla'">
      <div class="p-10 p-b-0 m-t-20">
        <h4>Override SLA's</h4>
      </div>
      <hr>


      <div *ngFor="let slaLink of this.loadedData.SlaLinks" class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          {{slaLink.TypeName}}
        </div>
        <div class="col-sm p-10">

          <ng-select [items]="slaTemplates"
                     bindLabel="Name"
                     bindValue="Id"
                     [(ngModel)]="slaLink.SlaId"
                     [clearable]="true">
          </ng-select>

        </div>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
