<!DOCTYPE html>
<html>
<head>
  <style>
body {
    font-family: Arial, sans-serif; /* Uniform font for the whole document */
    font-size: 16px; /* Uniform font size */
    line-height: 1.6; /* Improved readability */
    display: flex;
    flex-direction: column;
    margin: 40px;
  }
  h3 {
    margin-top: 20px; /* Add space above headings */
    margin-bottom: 0px; /* Add space below headings */
    text-transform: capitalize;
  }
  p, ol, ul {
    margin-top: 10px; /* Add space above paragraphs and lists */
    margin-bottom: 10px; /* Add space below paragraphs and lists */
    text-align: justify; /* Justify text for uniform alignment */
  }
  a {
    color: #0077cc; /* Uniform link color */
  }
  /* Additional styles for lists to ensure consistency */
  ol, ul {
    padding-left: 20px; /* Ensures consistent indentation for lists */
  }
  ol li  {
    margin-bottom: 5px; /* Adds space between list items */
    margin-left: 25px;  /* Adds space of the left of list items */
  } 
  ul li{
    margin-bottom: 5px;
    margin-left: 5px;
  }
  
  </style>
</head>
<body>
<h3 style="margin-top: 0px; font-weight: bold;">Terms of Service</h3>
<p>This document and the documents referred to in it are your Terms of Use with SDesk Ltd. Please read this carefully
  before using our cloud-based Service Desk.<br>On Accepting these terms, I confirm that I am authorised on behalf of the Client to place an order for SDesk Ltd
  subject to the terms and conditions set out below. I also warrant that the personal information and details submitted
  during the signup process is correct and accurate to the best of my knowledge.</p>
<p><strong>A: Property Of SDesk Ltd</strong></p>
<p>Following acceptance, you may access and use the sdesk ltd software through our server. the copyright, database rights and any other intellectual property rights in the programs and data which constitute this software product ('the materials'), are and remain the property of sdesk ltd.</p>
<p><strong>B: Licence Acceptance Procedure</strong></p>
<p style="text-transform: capitalize;">On acceptance you indicate agreement to this end user licence agreement and the limited warranty and limitation of liability set out in this end user licence agreement on behalf of any corporate entity which employs you or which you represent ('client'). in this end user licence agreement, 'you' includes both the reader and any client. you should therefore read this end user licence agreement carefully before accepting.</p>
<p><strong>Definitions</strong></p>
<p>The following terms as used in this Agreement have the following meanings:</p>
<p><u>"Accept/Accepting/Acceptance"</u> means (i) your placing a check in the box on our sign up form confirming that
  you accept these terms and (ii) clicking the &lsquo;start using SDesk&rsquo; box which shall together constitute a
  binding contract between SDesk Ltd and the Client</p>
<p><u>"Agreement"</u> means these terms of use which may be amended by SDesk Ltd from time to time in its sole
  discretion;</p>
<p><u>"SDesk Ltd"</u> means the cloud based (online) IT Service Management solution, managed by SDesk Ltd and provided
  through the Website;</p>
<p><u>"SDesk Ltd Technology"</u> means all the proprietary technology used in delivering the Service (including the
  software, hardware, products, processes, algorithms, user interfaces, know-how, techniques, designs and other tangible
  or intangible technical material or information) made available to the Client by SDesk Ltd in providing the Service;
</p>
<p><u>"Business Day"</u> means a day other than a Saturday, Sunday or public holiday in England when banks in England
  are open for business;</p>
<p><u>"Cancellation Period"</u> means the period of 21 days starting on the Effective Date;</p>
<p><u>"Card Details"</u> means valid credit or debit card details provided by the Client for payment of the Fees;</p>
<p><u>"Client"</u> means the corporate entity or organisation ordering the Service(s);</p>
<p><u>"Client Data"</u> means any data, information or material provided or submitted by the Client to the Service or
  generated by the Service in the course of using the Service including but not limited to your customer data held in
  the Service;</p>
<p><u>"Content"</u> means the documents, software, products and services contained or made available to the Client in
  the course of using the Service;</p>
<p><u>"Data Protection Legislation"</u> means (i) unless and until the GDPR is no longer directly applicable in the UK,
  the General Data Protection Regulation ((EU) 2016/679) and any national implementing laws, regulations and secondary
  legislation, as amended or updated from time to time, in the UK and then (ii) any successor legislation to the GDPR or
  the Data Protection Act 1998.</p>
<p><u>"Defect"</u> means an error in the operation of the Service that causes the Service to fail to operate
  substantially as documented;</p>
<p><u>"Effective Date"</u> means the date the Accepting Individual Accepts on behalf of the Client to commence use of
  the Service;</p>
<p><u>"Fee(s)"</u> means all fees charges or other payments due made from the Client to SDesk Ltd as provided by the
  Pricing Plan in force at the time the Fees become payable.</p>
<p><u>"Intellectual Property Rights"</u> means unpatented inventions, patent applications, patents, design rights,
  copyrights, trademarks, service marks, trade names, domain name rights, mask work rights, know-how and other trade
  secret rights, and all other intellectual property rights, derivatives thereof, and forms of protection of a similar
  nature anywhere in the world;</p>
<p><u>"Licensed Agents(s)"</u> means those Users who are authorised to administer the Client use of the Service;</p>
<p><u>"License Term"</u> means the period during which the Client is licensed to use the Service pursuant to this
  Agreement;</p>
<p><u>"Trial Period"</u> means the initial period of 30 days from the Effective Date;</p>
<p><u>"Period"</u> means the period of either (i) One calendar month or (ii) One year. Each such period shall begin on
  the day of the month (in the case of (i) above) or date of the year (in the case of (ii) above) on which the Client
  began paying for the Services. Such date shall be recorded in the administration pages of the Service.</p>
<p><u>"Pricing Plan"</u> means the schedule of fees and billing terms currently in force which can be viewed at <a
  href="https://www.sdesk.co.uk/pricing">https://www.sdesk.co.uk/pricing</a> . SDesk Ltd may replace the Pricing Plan on
  15 days prior written notice (including by email or though the Administration pages in the Service) and for the
  avoidance of doubt such new Pricing Plan may increase the Fees payable and/or introduce new Fees.</p>
<p><u>"Service(s)"</u> means the online Service Desk system, developed, operated, and maintained by SDesk Ltd (and its
  licensors, where applicable), or ancillary online or offline products and services provided to the Client by SDesk
  Ltd, to which the Client is being granted access under this Agreement, including the SDesk Ltd Technology and the
  Content;</p>
<p><u>"User(s)"</u> means the Client's employees, representatives, consultants, contractors or agents who are authorised
  to use the Service and have been supplied user identifications and passwords by the Client (or by SDesk Ltd at the
  Clients request);</p>
<p><u>"Website"</u> means www.sdesk.co.uk</p>
<p><strong>Trial Period And Duration</strong></p>
<p>The Services will be provided free of charge for the Trial Period only. Thereafter the Client will:</p>
<ol style="list-style-type: lower-roman;">
  <li>provide Card Details for payment of the Fees referred to below, or</li>
  <li>At SDesk Ltd&rsquo;s sole discretion arrange for the payment of Fees by BACS</li>
</ol>
<p>Failing which SDesk Ltd may immediately cease provision of the Services in accordance with clause "Termination Upon
  Expiration"</p>
<p>If the Client has provided Card Details to SDesk Ltd, or has been permitted to pay by BACS, the Services shall
  continue to be provided for so long as the Fees are paid as aforesaid unless and until this Agreement is terminated in
  accordance with these terms and conditions.</p>
<p><strong>Disclosure</strong></p>
<p>Regarding any personal data input by or collected from the Client that may be stored or processed in the SDesk Ltd
  system, such data shall be stored and processed by SDesk Ltd in accordance with Data Protection Legislation. Note that
  because the Service is a hosted, online application, SDesk Ltd occasionally may need to notify all Users of the
  Service of important announcements regarding the operation of the Service.</p>
<p><strong>Privacy &amp; Data Processing</strong></p>
<p>SDesk Ltd's processing policy in relation to the Client's use of the Service may be viewed <a
  href="https://www.sdesk.co.uk/gdpr">https://www.sdesk.co.uk/gdpr</a>. The Processing Policy sets out the scope, nature
  and purpose of processing by SDesk Ltd, the duration of the processing and the types of personal data (as defined in
  the Data Protection Legislation, Personal Data) and categories of Data Subject. SDesk Ltd reserves the right to modify
  its processing policy in its reasonable discretion and in accordance with Data Protection Legislation from time to
  time.</p>
<p>Both parties shall comply with all applicable requirements of the Data Protection Legislation. This clause is in
  addition to, and does not relieve, remove or replace, a party's obligations under the Data Protection Legislation. The
  parties acknowledge that for the purposes of the Data Protection Legislation, the Client is the data controller and
  SDesk Ltd is the data processor (where Data Controller and Data Processor have the meanings as defined in the Data
  Protection Legislation).</p>
<p>Without prejudice to the generality of the forgoing in this clause, the Client will ensure that it has all necessary
  appropriate consents and notices in place to enable lawful transfer of the Personal Data to SDesk Ltd for the duration
  and purposes of this agreement.</p>
<p>Without prejudice to the generality of the forgoing of this clause, SDesk Ltd shall, in relation to any Personal Data
  processed in connection with the performance by SDesk Ltd of its obligations under this agreement:</p>
<ul>
  <li style="list-style: none;">
    <ol style="list-style-type: lower-roman;">
      <li>Process that Personal Data only on the written instructions of the Client unless SDesk Ltd is required by the
        laws of any member of the European Union or by the laws of the European Union applicable to SDesk Ltd to process
        Personal Data (Applicable Laws). Where SDesk Ltd is relying on laws of a member of the European Union or
        European Union law as the basis for processing Personal Data, SDesk Ltd shall promptly notify the Client of this
        before performing the processing required by the Applicable Laws unless those Applicable Laws prohibit SDesk Ltd
        from so notifying the Client;
      </li>
      <li>Ensure that it has in place appropriate technical and organisational measures, reviewed and approved by the
        Client, to protect against unauthorised or unlawful processing of Personal Data and against accidental loss or
        destruction of, or damage to, Personal Data, appropriate to the harm that might result from the unauthorised or
        unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having
        regard to the state of technological development and the cost of implementing any measures (those measures may
        include, where appropriate, pseudonymising and encrypting Personal Data, ensuring confidentiality, integrity,
        availability and resilience of its systems and services, ensuring that availability of and access to Personal
        Data can be restored in a timely manner after an incident, and regularly assessing and evaluating the
        effectiveness of the technical and organisational measures adopted by it);
      </li>
      <li>Ensure that all personnel who have access to and/or process Personal Data are obliged to keep the Personal
        Data confidential; and
      </li>
      <li>Assist the Client, at the Client's cost, in responding to any request from a Data Subject and in ensuring
        compliance with its obligations under the Data Protection Legislation with respect to security, breach
        notifications, impact assessments and consultations with supervisory authorities or regulators;
      </li>
      <li>Notify the Client without undue delay on becoming aware of a Personal Data breach;</li>
      <li>At the written direction of the Client, delete or return Personal Data and copies thereof to the Client on
        termination of the agreement in accordance with Clause &ldquo;Data Return and Destruction&rdquo; unless required
        by Applicable Law to store the Personal Data; and
      </li>
      <li>Maintain complete and accurate records and information to demonstrate its compliance with this clause and
        allow for audits by the Client or the Client's designated auditor.
      </li>
    </ol>
  </li>
</ul>
<p>SDesk Ltd will not transfer any Personal Data outside of the European Economic Area.</p>
<p>The Client consents to SDesk Ltd appointing the following classes of third-party processors of Personal Data under
  this agreement:</p>
<ul>
  <li>Service providers acting as processors based in the UK who provide IT, development and system administration
    services.
  </li>
  <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers
    based in the UK who provide consultancy, banking, legal, insurance and accounting services.
  </li>
  <li>HM Revenue &amp; Customs, regulators and other authorities acting as processors or joint controllers based in the
    United Kingdom who require reporting of processing activities in certain circumstances.
  </li>
</ul>
<p>SDesk Ltd confirms that it has entered or (as the case may be) will enter with the third-party processor into a
  written agreement incorporating terms which are substantially similar to those set out in this clause. As between the
  Client and SDesk Ltd, SDesk Ltd shall remain fully liable for all acts or omissions of any third-party processor
  appointed by it pursuant to this clause.</p>
<p>SDesk Ltd may, at any time on not less than 30 days&rsquo; notice, revise this clause by replacing it with any
  applicable controller to processor standard clauses or similar terms forming part of an applicable certification
  scheme (which shall apply when replaced by attachment to this agreement).</p>
<p>Without prejudice to the generality of the foregoing, if the client becomes a paying user of the service, the client agrees that sdesk ltd can disclose the fact that the client is a paying user of the service.</p>
<p><strong>License Grant &amp; Restrictions</strong></p>
<p>SDesk Ltd hereby grants the Client a non-exclusive, non-transferable, right to use the Service, solely for the
  Client's own internal business purposes, subject to the terms and conditions of this Agreement. All rights not
  expressly granted to the Client are reserved by SDesk Ltd and its licensors.</p>
<p>The Client may not access the Service if they are a direct or indirect competitor of SDesk Ltd, except with SDesk
  Ltd's prior written consent. In addition, the Client may not access the Service for purposes of monitoring its
  availability, performance or functionality, or for any other benchmarking or competitive purposes.</p>
<p>The client shall not:</p>
<ol style="list-style-type: lower-roman;">
  <li>license, lease, sublicense, sell, resell, transfer, display, disclose, assign, distribute or otherwise
    commercially exploit or make available to any third party the Service or the Content in any way;
  </li>
  <li>Modify, duplicate copy or make derivative works based upon the Service or the Content;</li>
  <li>Create Internet "links" to the Service or "frame", "mirror", republish, transmit or distribute any Content on any
    other server or wireless or Internet-based device;
  </li>
  <li>Reverse compile, decompile, or in any way reverse engineer or otherwise reduce to human perceivable form all or
    any part of the Service or Content;
  </li>
  <li>Attempt to obtain, or assist third parties in obtaining, access to the Services and/or Content (other than as
    provided under this Agreement); or
  </li>
  <li>Access the Service in order to (a) build a competitive product or service, (b) build a product using similar
    ideas, features, functions or graphics of the Service, or (c) copy any ideas, features, functions or graphics of the
    Service.
  </li>
</ol>
<p>The Client may use the Service only for internal business purposes and shall not:</p>
<ol style="list-style-type: lower-roman;">
  <li>Store infringing, obscene, sexually explicit, threatening, harassing or racially or ethnically insensitive,
    libellous, or otherwise unlawful or tortious material, including material harmful to children or in violation of
    third-party privacy rights;
  </li>
  <li>Store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts,
    agents or programs;
  </li>
  <li>Interfere with or disrupt the integrity or performance of the Service or the data contained therein; or</li>
  <li>Attempt to gain unauthorised access to the Service or its related systems or networks.</li>
</ol>
<p><strong>The Client's Responsibilities</strong></p>
<p>The Client is responsible for all activity occurring under their User accounts and shall:</p>
<ol style="list-style-type: lower-roman;">
  <li>Abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with their
    use of the Service, including those related to data privacy, international communications and the transmission of
    technical or personal data;
  </li>
  <li>Carry out all other Client responsibilities set out in this Agreement in a timely and efficient manner;</li>
  <li>Review and approve the technical and organisational measures taken by SDesk Ltd to protect against (a)
    unauthorised or unlawful processing, (b) accidental loss or destruction of or (c) damage to Personal Data (available
    here:&nbsp;https://www.sdesk.co.uk/security);
  </li>
  <li>Be solely responsible for procuring and maintaining its network connections and telecommunications links from its
    systems to SDesk Ltd, and all problems, conditions, delays, delivery failures and all other loss or damage arising
    from or relating to the Client&rsquo;s network connections or telecommunications links or caused by the internet
  </li>
  <li>Notify SDesk Ltd immediately of any unauthorised use of any password or account or any other known or suspected
    breach of security;
  </li>
  <li>Report to SDesk Ltd immediately and use reasonable efforts to stop immediately any copying or distribution of
    Content that is known or suspected by the Client or their Users; and
  </li>
  <li>Not impersonate another SDesk Ltd User or provide false identity information to gain access to or use the
    Service.
  </li>
</ol>
<p><strong>Account Information And Data</strong></p>
<p>SDesk Ltd does not own any of the Client Data. The Client, not SDesk Ltd, shall have sole responsibility for the
  accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to
  use of all Client Data,</p>
<p>The Client shall indemnify and hold SDesk Ltd, its licensors and each such party's parent organisations,
  subsidiaries, affiliates, officers, directors, employees, attorneys and agents harmless from and against any and all
  claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in
  connection with:</p>
<ol style="list-style-type: lower-roman;">
  <li>Any breach of the Data Protection Legislation by the Client; or</li>
  <li>Any inaccuracy in the Client Data as input by the Client.</li>
</ol>
<p><strong>Intellectual Property Ownership</strong></p>
<p>SDesk Ltd alone (and its licensors, where applicable) shall own all right, title and interest, including all related
  Intellectual Property Rights, in and to the SDesk Ltd Technology and any content and the Service and any suggestions,
  ideas, enhancement requests, feedback, recommendations or other information provided by the Client or any other party
  relating to the Service. This Agreement is not a sale and does not convey to the Client any rights of ownership in or
  related to the Service, the SDesk Ltd Technology or the Intellectual Property Rights owned by SDesk Ltd. The SDesk Ltd
  name, the SDesk Ltd logo, and the product names associated with the Service are trademarks of SDesk Ltd or third
  parties, and no right or license is granted to use them.</p>
<p><strong>Fees And Renewal</strong></p>
<p>If the Client wishes to continue with the Service after the Trial Period the Client must provide Card Details or
  obtain the permission of SDesk Ltd to pay by bacs. The provision of any such Card Details, either upon the Effective
  Date or subsequently, shall be the Client's authorisation to charge all Fees to those Card Details.</p>
<p>SDesk Ltd collects Fees in advance on the first day of the Period in relation to which Fees are being charged (or the
  next Business Day) by charging the Client&rsquo;s card using the Card Details.</p>
<p>All payment obligations are non-cancellable, and all amounts paid are non-refundable. The Client is responsible for
  paying for all Services ordered for the entire License Term. If the Client wishes to amend the number of employees
  with access to the Service, the Client must make any such amendment through the Administration pages in the Service
  and any resulting adjustment to the Pricing Plan shall be reflected in the next Period.</p>
<p>Fees for other services will be charged on an as-quoted basis.</p>
<p>SDesk Ltd's fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and the Client shall be
  responsible for payment of all such taxes, levies, or duties.</p>
<p>All pricing terms are confidential, and the Client agrees not to disclose them to any third party.</p>
<p>The Client warrants that it has provided SDesk Ltd with complete and accurate billing and contact information. The
  Client agrees to provide SDesk Ltd with updated information within 30 days of any change to it through the Client's
  Administration Page in the Service. If the contact information the Client has provided is false or fraudulent, SDesk
  Ltd reserves the right to terminate access to the Service in addition to any other legal remedies.</p>
<p>SDesk Ltd is only available to corporate entities or organisations and all billing will be in &pound; sterling.</p>
<p>If the Client believes their bill is incorrect, they must notify SDesk Ltd within 60 days of the date of the disputed
  invoice, following which SDesk Ltd shall assess whether any adjustment or credit is due.</p>
<p><strong>Non-payment And Suspension</strong></p>
<p>In addition to any other rights granted to SDesk Ltd, SDesk Ltd reserves the right to suspend or terminate this
  Agreement and the Client's access to the Service if their account falls into arrears. If any sum payable under this
  Agreement is not paid within 14 days after the due date or any payment is rejected revoked or refused then (without
  prejudice to the Company's other rights and remedies) SDesk Ltd reserves the right to suspend the provision of any
  Services being rendered and to delete all Client preferences and settings in relation to the SDesk Ltd system as set
  up for the Client and/or to charge interest on such sum on a day to day basis (as well after as before any judgment)
  from the date or last date for payment thereof to the date of actual payment (both dates inclusive) at the rate of 4
  per cent above the base rate of National Westminster Bank plc from time to time in force compounded quarterly. Such
  interest shall be paid on demand by the Client.</p>
<p>SDesk Ltd reserves the right to impose a reconnection fee in the event the Client is suspended and thereafter
  requests access to the Service.</p>
<p><strong>Termination Within Cancellation Period</strong></p>
<p>SDesk Ltd may at its sole discretion terminate this Agreement with immediate effect at any time before the expiry of
  the Cancellation Period.</p>
<p>SDesk Ltd shall not be obliged to give notice of such termination to the Client but may do so at its sole discretion
  in any form.</p>
<p><strong>Termination Upon Expiration</strong></p>
<p>This Agreement will automatically expire at the end of the Trial Period unless the Client has provided Card Details
  or obtained SDesk Ltd&rsquo;s permission to pay by BACS.</p>
<p><strong>Termination By Notice</strong></p>
<p>If the Client selects the cancellation option within the Administration pages in the Service, this Agreement will
  terminate immediately.</p>
<p>SDesk Ltd may terminate this Agreement if SDesk Ltd decides to withdraw the Service (whether on a temporary or
  permanent basis) or decides to no longer permit access to the Service by the Client (by use of passwords or changes of
  passwords or by any other means). No refunds shall be due in cases in which Clients have paid for but not received
  Services.</p>
<p><strong>Termination For Cause</strong></p>
<p>SDesk Ltd may terminate this Agreement if the Client commits any material breach of any of the terms of this
  Agreement and (if such a breach is remediable) fails to remedy that breach within 30 days of being notified of the
  breach. Without limit to the generality of the foregoing any breach of the Client's payment obligations or
  unauthorised use of the SDesk Ltd Technology or Service will be deemed a material and irremediable breach of this
  Agreement.</p>
<p><strong>Termination Consequences</strong></p>
<p>Following termination for any reason SDesk Ltd will terminate the Client's password, account and use of the Service.
  Within 14 days of the termination of the contract the Client must pay to SDesk Ltd in full and without set off:</p>
<ol style="list-style-type: lower-roman;">
  <li>The Fees due up until the date of such termination, and</li>
  <li>Any other sums due under this Agreement.</li>
</ol>
<p>Any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination,
  including the right to claim damages in respect of any breach of the Agreement which existed at or before the date of
  termination shall not be affected or prejudiced.</p>
<p><strong>Data Return and Destruction</strong></p>
<p>Subject to Clause &ldquo;Internet Delays&rdquo; and Clause &ldquo;Force Majeure&rdquo;, SDesk Ltd will give the
  Client access to all or part of the Client&rsquo;s data in its possession or control in the format and medium in which
  the Client uploaded the data to the service.</p>
<p>On termination of this Agreement for any reason or the expiry of its term, SDesk Ltd will securely delete or destroy
  or, if directed by the Customer using the administration pages of the Service prior to such termination, return and
  not retain, all or any personal data related to this Agreement in its possession or control in the format and medium
  in which the Client uploaded the data to the service.</p>
<p>If any law, regulation, or government or regulatory body requires SDesk Ltd to retain any documents or materials that
  SDesk Ltd would otherwise be required to return or destroy, it will notify the Client in writing of that retention
  requirement, giving details of the documents or materials that it must retain, the legal basis for retention, and
  establishing a specific timeline for destruction once the retention requirement ends.</p>
<p><strong>Representations &amp; Warranties</strong></p>
<p>Each party represents and warrants that it has the legal power and authority to enter into this Agreement. SDesk Ltd
  represents and warrants that it will provide the Service in a manner consistent with general industry standards
  reasonably applicable to the provision thereof and that the Service will perform substantially as documented under
  normal use and circumstances. The sole remedy for breach of this warranty shall be correction of any Defects by SDesk
  Ltd within a reasonable time from notification by the Client of the Defect that constitutes such a breach, providing
  that the Client provides all the information that may be necessary to assist SDesk Ltd in resolving the Defect,
  including sufficient information to enable SDesk Ltd to recreate the Defect.</p>
<p>The Client represents and warrants that they have not falsely identified themselves nor provided any false
  information to gain access to the Service and that their billing information is correct.</p>
<p><strong>Confidential Information</strong></p>
<p>SDesk Ltd will keep all Client Data confidential, providing that Client Data may be disclosed to SDesk Ltd's
  employees, representatives, consultants, contractors or agents (who in turn will be legally bound to keep the Client
  Data confidential).</p>
<p>The obligation to keep the Client Data confidential will not apply to any information that:</p>
<ol style="list-style-type: lower-roman;">
  <li>Is already known to the public; or</li>
  <li>Is required to be disclosed by law, by any governmental or other regulatory authority, or by a court or other
    authority of competent jurisdiction, providing that SDesk Ltd will give the Client notice of the requirement to
    disclose of that disclosure as soon as practicable.
  </li>
</ol>
<p>This clause shall survive termination of this agreement, however arising.</p>
<p><strong>Mutual Indemnification</strong></p>
<p>The Client shall indemnify and hold SDesk Ltd, its licensors and each such party's parent organisations,
  subsidiaries, affiliates, officers, directors, employees, attorneys and agents harmless from and against any and all
  claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in
  connection with: (i) a claim alleging that use of the Client Data infringes the rights of, or has caused harm to, a
  third party; (ii) a claim, which if true, would constitute a violation by the Client of their representations and
  warranties; or (iii) a claim arising from the breach by the Client or their Users of this Agreement, provided in any
  such case that SDesk Ltd (a) gives written notice of the claim promptly to the Client; (b) gives the Client sole
  control of the defence and settlement of the claim (provided that the Client may not settle or defend any claim unless
  they unconditionally release SDesk Ltd of all liability and such settlement does not affect SDesk Ltd's business or
  Service); (c) provides to the Client all available information and assistance; and (d) has not compromised or settled
  such claim.</p>
<p>SDesk Ltd shall indemnify and hold the Client and their parent organisations, subsidiaries, affiliates, officers,
  directors, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses,
  liabilities and expenses (including all reasonable legal and professional fees and costs) arising out of or in
  connection with: (i) a proven claim that the Service directly infringes the copyright, or a trademark of a third
  party; (ii) a claim, which if true, would constitute a substantial and actual violation by SDesk Ltd of its
  representations or warranties; or (iii) a claim arising from SDesk Ltd's wilful and knowing disclosure of your Client
  Data to any unauthorised parties unless required by law; provided that the Client (a) promptly give written notice of
  the claim to SDesk Ltd; (b) give SDesk Ltd sole control of the defence and settlement of the claim ; (c) provide to
  SDesk Ltd all available information and assistance; and (d) have not compromised or settled such claim. SDesk Ltd
  shall have no indemnification obligation, and the Client shall indemnify SDesk Ltd pursuant to this Agreement, for
  claims arising from any infringement arising from the combination of the Service with any of the Client's products,
  service, hardware or business process(es).</p>
<p><strong>Disclaimer Of Warranties</strong></p>
<p>SDesk Ltd and its licensors do not guarantee that:</p>
<ol style="list-style-type: lower-roman;">
  <li>The Services will operate error free or without interruption;</li>
  <li>All program defects in relation to the Services will be corrected; and</li>
  <li>The Services will operate with any hardware, software, system or data not identified in the ordering process.</li>
</ol>
<p>All conditions, representations and warranties, whether express, implied, statutory or otherwise, including, without limitation, any implied warranty of satisfactory quality, fitness for a particular purpose, or non-infringement of third party rights, are hereby disclaimed to the maximum extent permitted by applicable law by sdesk ltd and its licensors</p>
<p>Internet delays</p>
<p>Sdesk ltd's services may be subject to limitations, delays, and other problems inherent in the use of the internet and electronic communications. Sdesk ltd is not responsible for any delays, delivery failures, or other damage resulting from such problems.</p>
<p>Force majeure</p>
<p>In this Agreement, "force majeure" shall mean any cause preventing SDesk Ltd from performing any or all of SDesk
  Ltd's obligations which arise from or are attributable to acts, events, omissions or accidents beyond SDesk Ltd's
  reasonable control including without limitation strikes, lock-outs or other industrial disputes, acts of God, war,
  riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction,
  accident, breakdown of plant or machinery, fire, flood, interruption or failure of utility service, including but not
  limited to electric power, gas or water or default of suppliers or sub-contractors.</p>
<p>SDesk Ltd shall not be in breach of this Agreement if it is subject to a force majeure event, provided that it uses
  reasonable endeavours to notify you in writing of the nature and extent of the force majeure event causing SDesk Ltd's
  failure or delay in performance.</p>
<p>If the force majeure event prevails for a continuous period of more than 2 months, the Client may terminate this
  Agreement by giving 14 days' written notice to SDesk Ltd. On the expiry of this notice period, this Agreement will
  terminate. Such termination shall be without prejudice to SDesk Ltd's rights in respect of any breach of this
  agreement occurring prior to such termination.</p>
<p><strong>Limitation Of Liability</strong></p>
<p>Nothing in this agreement will exclude or limit sdesk ltd's liability for</p>
<ol style="list-style-type: lower-roman;">
  <li>Death or personal injury caused by sdesk ltd's negligence; or</li>
  <li>Fraud or fraudulent misrepresentation.</li>
</ol>
<p>Subject to the indemnity clause, sdesk ltd shall not be liable for any damages or losses as a result of a force majeure event.</p>
<p>Sdesk ltd shall not be liable for:</p>
<ol style="list-style-type: lower-roman;">
  <li>Any loss of profits or other economic advantage;</li>
  <li>Any loss of data</li>
  <li>Any loss of goodwill;</li>
  <li>Any loss of anticipated savings;</li>
  <li>Any consequential losses; and/or</li>
  <li>Any exemplary or punitive losses,</li>
</ol>
<p>Arising in respect of any representation, statement, act or omission in connection with this agreement, whether the claim arises under contract, tort, misrepresentation or breach of statutory duty. Subject to paragraph 1 of this clause, in no event shall sdesk ltd's aggregate liability exceed the amounts actually paid by and/or due from the client in the twelve (12) month period immediately preceding the event giving rise to such claim.</p>
<p><strong>Notice</strong></p>
<p>SDesk Ltd may give notice by means of electronic mail to the Client's e-mail address on record in SDesk Ltd's account
  information, or by written communication sent by first class mail or pre-paid post to the address on record in SDesk
  Ltd's account information. Such notice shall be deemed to have been given upon the expiration of 48 hours after
  mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email).</p>
<p>The Client may only give:</p>
<ol style="list-style-type: lower-roman;">
  <li>Notice to cancel the agreement in accordance with Clause &ldquo;Termination by Notice, and/or</li>
  <li>Instructions in relation to the destruction or retention of data in accordance with Clause &ldquo;Data Return and
    Destruction&rdquo;
  </li>
</ol>
<p>In the administration pages of the Service.</p>
<p><strong>Modification Of Terms</strong></p>
<p>SDesk Ltd reserves the right to modify the terms and conditions of this Agreement or its policies relating to the
  Service at any time. Such modified terms and conditions will be issued or made available to the Client electronically
  via email or the administration pages in the Service and shall be deemed effective 12 hours after electronic delivery
  or notification. Continued use of the Service after any such changes shall constitute the Clients consent to such
  changes.</p>
<p><strong>Assignment; Change In Control</strong></p>
<p>This Agreement may not be assigned by the Client without the prior written approval of SDesk Ltd but may be assigned
  without the Clients consent by SDesk Ltd to (i) a parent or subsidiary, (ii) an acquirer of assets, or (iii) a
  successor by merger. Any purported assignment in violation of this section shall be void. Any actual or proposed
  change in control of the Client that results or would result in a direct or indirect competitor of SDesk Ltd directly
  or indirectly owning or controlling 50% or more of the Client shall entitle SDesk Ltd to terminate this Agreement for
  cause immediately upon written notice.</p>
<p><strong>Other Terms</strong></p>
<p>This Agreement shall be governed by and construed in accordance with the laws of England and Wales and the parties
  agree that any dispute relating to its terms or subject matter shall be subject to the exclusive jurisdiction of the
  English courts.</p>
<p>If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then
  such provision(s) shall be construed, as nearly as possible, to reflect the intentions of the invalid or unenforceable
  provision(s), with all other provisions remaining in full force and effect.</p>
<p>No joint venture, partnership, employment, or agency relationship exists between the Client and SDesk Ltd as a result
  of this agreement or use of the Service.</p>
<p>The failure of SDesk Ltd to enforce any right or provision in this Agreement shall not constitute a waiver of such
  right or provision unless acknowledged and agreed to by SDesk Ltd in signed writing authorised by a director of SDesk
  Ltd.</p>
<p>This Agreement together with the GDPR Policy, Pricing Plan, and any copyright notices on the Website comprises the
  entire agreement between the Client and SDesk Ltd in relation to the Services and supersedes all prior or
  contemporaneous negotiations, discussions or agreements, whether written or oral, between the parties regarding the
  subject matter contained herein.</p>
<p><strong>Third Parties</strong></p>
<p>For the purposes of the Contracts (Rights of Third Parties) Act 1999 this Agreement is not intended to, and does not,
  give any person who is not a party to it any right to enforce any of its provisions.</p>
</body>
</html>
