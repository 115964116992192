<app-modal [modalSize]="'modal-small'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Search Agents</h5>
  </div>
  <div class="modal-body">

    <!--suppress HtmlFormInputWithoutLabel -->
    <input [(ngModel)]="searchTerm" class="form-control" name="searchTerm" placeholder="Search...">

    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

    <div *ngIf="!isLoading">
      <div class="table-responsive">
        <table class="table align-middle tableStyle">
          <thead>
          <tr>
            <th>Name</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of this.filteredUsers()" class="divLink" [class.sdesk-filter-selected]="selectedUserId == user.id" (click)="selectedUserId = user.id; selectedUserCustomerId = user.CustomerId; selectedUserName = user.Name">
            <td>{{user.name}}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <p class="text-muted">
        {{this.filteredUsers().length}} of {{this.userList.length}} results
      </p>

    </div>

  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary" [class.disabled]="!selectedUserId">Select User</div>
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
