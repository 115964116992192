import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {EnvironmentApiService} from "../api/environment/environment-api.service";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  public environmentVariablesSubscription = new BehaviorSubject<any>(null);

  constructor(private environmentApi: EnvironmentApiService) {
    this.refreshEnvironmentProperties();
  }

  refreshEnvironmentProperties(){
    this.environmentApi.getEnvironmentInfo().then(response => {
      this.environmentVariablesSubscription.next(response)
    }).catch(error => {})
  }
}
