import { Component, EventEmitter, Input, OnInit, Output,ChangeDetectorRef} from '@angular/core';
import { ApiService } from '../../../../services/api/api.service';
import { DialogService } from 'src/app/services/dialog-service/dialog.service';

@Component({
  selector: 'app-request-select-task-list',
  templateUrl: './request-select-task-list.component.html',
  styleUrls: ['./request-select-task-list.component.css']
})
export class RequestSelectTaskListComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();
  isLoading = true;
  taskLists;  // This holds the original data
  filteredTaskLists = [];  // This will hold the filtered data
  selectedTaskListId;
  searchTerm = '';

  constructor(private api: ApiService, private dialogService: DialogService,private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.fetchTaskLists();
  }

  fetchTaskLists() {
    this.isLoading = true;
    this.api.get("/api/taskLists").then(response => {
      this.taskLists = response;
      this.applyFilter();
      this.isLoading = false;
    }).catch(error => {
      console.error('Failed to fetch task lists:', error);
      this.isLoading = false;
    });
  }
  

  applyFilter() {
    if (this.taskLists) {
      this.filteredTaskLists = this.searchTerm ?
        this.taskLists.filter(tl => tl.Name.toLowerCase().includes(this.searchTerm.toLowerCase())) :
        [...this.taskLists]; // Use spread to ensure changes are detected
    } else {
      this.filteredTaskLists = [];
    }
    this.changeDetectorRef.detectChanges(); // Manually trigger change detection
  }

  onSearchTermChange() {
    this.applyFilter();
  }

  closeModal() {
    this.remove.emit(null);
  }

  save() {
    this.api.put("/api/selectTaskList/" + this.data, { "TaskListId": this.selectedTaskListId }).then(success => {
      this.remove.emit("Success");
      this.dialogService.emitModalEvent('success');
    });
  }
}
