import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class ReportingApiService {

  constructor(private apiService: ApiService) { }

  getReportingData(dateFrom, dateTo, filterTypeIds, filterGroupIds, filterAgentIds, filterStatusIds, filterCustomerIds){

    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo,
      'DownloadCsv' : 0
    }

    let body = {
      filter: {
        type: filterTypeIds,
        group: filterGroupIds,
        agent: filterAgentIds,
        status: filterStatusIds,
        customer: filterCustomerIds
      }
    }

    return this.apiService.post(`/api/itil/reportingdashboard`, body, params)

  }

  downloadReportingData(dateFrom, dateTo, filterTypeIds, filterGroupIds, filterAgentIds, filterStatusIds){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo,
      'DownloadCsv' : 1
    }

    let body = {
      filter: {
        type: filterTypeIds,
        group: filterGroupIds,
        agent: filterAgentIds,
        status: filterStatusIds
      }
    }

    this.apiService.download(`/api/itil/reportingdashboard`, 'get', body, params);

  }

  getTicketList(dateFrom, dateTo, filterTypeIds, filterGroupIds, filterAgentIds, filterStatusIds, filterCustomerIds, withTasks=false){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo,
      'ReturnTickets' : 1
    }

    if(withTasks){
      params['IncludeTasks'] = 1;
    }

    let body = {
      filter: {
        type: filterTypeIds,
        group: filterGroupIds,
        agent: filterAgentIds,
        status: filterStatusIds,
        customer: filterCustomerIds
      }
    }

    return this.apiService.post(`/api/itil/reportingdashboard`, body, params);

  }

  getTimeElapsed(dateFrom, dateTo){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo
    }

    return this.apiService.post(`/api/itil/reporting-time-tracking`, null, params);

  }

  getTimeElapsedCustomer(dateFrom, dateTo){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo
    }

    return this.apiService.post(`/api/itil/reporting-time-tracking-customer`, null, params);

  }

  getAllReportingFilters(){
    return this.apiService.get(`/api/itil/report-filter`);
  }

  getReportingFilterDetails(id){
    return this.apiService.get(`/api/itil/report-filter/${id}`);
  }

  saveReportingFilter(data){
    return this.apiService.post(`/api/itil/report-filter`, data);
  }

  deleteReportingFilter(id){
    return this.apiService.delete(`/api/itil/report-filter/${id}`);
  }

}
