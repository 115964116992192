import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminCabService} from "../../../../../../services/api/admin/admin-cab/admin-cab.service";
import {RequesterSearchModalComponent} from "../../../../../../shared/requester-search-modal/requester-search-modal.component";
import {DialogService} from "../../../../../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-admin-cab-create-edit-dialog',
  templateUrl: './admin-cab-create-edit-dialog.component.html'
})

export class AdminCabComponentDialog implements Dialog {

  @Input() data; // {id: 122, requestTypeId: 12}
  @Output() remove = new EventEmitter<any>();

  requestTypeId;
  isLoading = true;
  loadedId = null;

  loadedData;

  constructor(
    private apiAdminCabService: ApiAdminCabService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef,
  ) {}


  ngOnInit(): void {
    this.requestTypeId = this.data.requestTypeId;

    if(this.data.id !== null){
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        Description: '',
        CabMembers: []
      }

      this.isLoading = false;

    }
  }

  openUserSearchModal(){
    this.dialogService.createDialog(RequesterSearchModalComponent, null, this.viewReference)
      .then((dialogSaved: any) => {
        const userId = dialogSaved?.userId;
        const customerId = dialogSaved?.customerId;
        const userName = dialogSaved?.userName;

        if(userId) {
          this.addCabMember(userId, userName);
        }

      }, dialogCancelled => {
        console.log("dialogCancelled:"+dialogCancelled)
      })

  }

  addCabMember(userId, name){

    let dataToAdd = {
      Id: userId,
      Name: name
    }

    if(!this.loadedData.CabMembers.some(array => array.Id == userId)){
      this.loadedData.CabMembers.push(dataToAdd);
    }
  }

  removeCabMember(index){
    this.loadedData.CabMembers.splice(index, 1);
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCabService.get(this.requestTypeId, this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminCabService.update(this.requestTypeId, this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminCabService.create(this.requestTypeId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
