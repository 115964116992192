<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Chatbot</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Chat Opened Message
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.ConversationOpenedMessage" type="text" class="form-control" id="ConversationOpenMessage" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Chat Closed Message
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.ConversationResolvedMessage" type="text" class="form-control" id="ConversationClosedMessage" autocomplete="off">
      </div>
    </div>

<!--    <div class="row">-->
<!--      <div class="col-sm-3 formHeader alignMiddle p-10">-->
<!--        Feedback Message-->
<!--      </div>-->
<!--      <div class="col-sm p-10">-->
<!--        <input [(ngModel)]="loadedData.FeedbackMessage" type="text" class="form-control" id="FeedbackMessage" autocomplete="off">-->
<!--      </div>-->
<!--    </div>-->

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Reopen Hours
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.AllowReopenHours" type="number" class="form-control" id="AllowReopenHours" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Request Type - Required
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="options.type"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.RequestTypeId"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Support Group - Required
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="options.group"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.RequestGroupId"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
