<div class="row">
  <label (click)="toggle()" class="col-sm-12 control-label m-l-10 divLink">Template Syntax
    <span [class.fa-chevron-up]="expanded" [class.fa-chevron-down]="!expanded" class="m-r-10 fa"></span>
  </label>
</div>

<div class="row">
  <div *ngIf="expanded" style="margin: 10px; background: white">
    <table class="table">
      <tbody>
      <tr class="">
        <th scope="row">{{ "{" }}RequestType{{ "}" }}</th>
        <td>The Request Type, for example Incident, Change Request or Problem.</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}SubDomain{{ "}" }}</th>
        <td>Your SDesk sub-domain.</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}AgentRequestLink{{ "}" }}</th>
        <td>Request URL to the Agent Portal</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}CustomerRequestLink{{ "}" }}</th>
        <td>Request URL to the Self-Service Portal</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}GlobalRequestId{{ "}" }}</th>
        <td>GlobalRequestId</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}RequestId{{ "}" }}</th>
        <td>RequestId</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}RequestSubject{{ "}" }}</th>
        <td>Request Subject</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}RequestDescription{{ "}" }}</th>
        <td>Request Description</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}GeneratedThread{{ "}" }}</th>
        <td>Complete e-mail thread. All outbound and inbound emails.</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}RequesterName{{ "}" }}</th>
        <td>The name of the requester</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}RequesterEmail{{ "}" }}</th>
        <td>E-mail address of the requester</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}AgentName{{ "}" }}</th>
        <td>Name of the agent this request is assigned to</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}GroupName{{ "}" }}</th>
        <td>The group this request is assigned to</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}PriorityName{{ "}" }}</th>
        <td>The Priority assigned to this request</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}SlaResolutionDueDate{{ "}" }}</th>
        <td>DateTime (UTC) of when this request is due</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}TaskName{{ "}" }}</th>
        <td>The Name of the Task, this variable can only be used on Task email notifications.</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}TaskId{{ "}" }}</th>
        <td>The Id of the Task, this variable can only be used on Task email notifications.</td>
      </tr>
      <tr class="">
        <th scope="row">{{ "{" }}TaskInstructions{{ "}" }}</th>
        <td>The Instructions of the Task, this variable can only be used on Task email notifications.</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
