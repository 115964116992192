import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class AdminGlobalService {

  adminGlobalGeneralApi = '/api/admin/global/general/';
  adminTicketSettingsApi = '/api/admin/ticketsettings';

  constructor(private api: ApiService) {

  }

  getData(){
    return this.api.get(this.adminGlobalGeneralApi);
  }

  updateData(dataObject){
    return this.api.put(this.adminGlobalGeneralApi, dataObject);
  }
  getSettingsData(){
    return this.api.get(this.adminTicketSettingsApi);
  }
  updateSettingsData( data){
    return this.api.post(this.adminTicketSettingsApi,data);
  }

}
