import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsApiService} from "../../../../services/api/options/options-api.service";
import {RequestApiService} from "../../../../services/api/request/request-api.service";
import { InstanceParamsService } from 'src/app/services/instance-params/instance-params.service';

@Component({
  selector: 'app-request-audit-modal',
  templateUrl: './request-audit-modal.component.html',
  styleUrls: ['./request-audit-modal.component.css']
})
export class RequestAuditModalComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  newTypeId;
  auditEvents;
  instanceParams: any;

  constructor(private requestApi: RequestApiService, 
    private instanceParamsService: InstanceParamsService) { }

  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });

    this.requestApi.getAuditLogs(this.data).then(data => {
      this.auditEvents = data;
      this.isLoading = false;
    })
  }

  closeModal(){
    this.remove.emit(null);
  }

  save(){
    this.remove.emit("Success");
  }

}
