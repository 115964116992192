<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>
<div class="col-12 sectionBox m-t-10">
  <div>
    <div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

      <div (click)="create()" class="btn btn-primary float-end">Create</div>
      <div class="col-sm-5 mb-2 flex items-center">
        <input [(ngModel)]="searchTerm" type="text" class="form-control flex-grow" id="Search" placeholder="Search..." autocomplete="off">
        
      </div>
      <br>
      <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th class="">Customers 
            <span class="customer-count ml-20 px-2 py-1 rounded-full text-sm text-gray-600 bg-gray-200 ">({{ filteredData().length }})</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of filteredData() ">
          <td><span class="divLink" (click)="edit(row.Id)">{{row.Name}}</span>
            <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
            <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

