<highcharts-chart [Highcharts]="Highcharts"
                  [constructorType]="chartConstructor"
                  [options]="options"
                  [callbackFunction]="chartCallback"
                  [(update)]="updateFlag"
                  [oneToOne]="oneToOneFlag"
                  [runOutsideAngular]="runOutsideAngular"
                  style="height: {{this.height}}; display: block; max-width: 700px;">
</highcharts-chart>


<!--<div id="chartContainer" style="max-width: 700px;"></div>-->
