<div *ngIf="isLoading && conversationId">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading" style="background-color: white; border-radius: 15px; margin: 10px">

  <div class="chatWindowHeader">

    <div  [class.disable-button]="isSendingRequest" *ngIf="!data.ClosedDateTime" (click)="closeConversation()" style="padding: 5px 15px;float: right;background-color: #c53e3e;color: white;cursor: pointer;font-size: 12px;border-radius: 5px; margin-left:15px">Mark Closed</div>

<!--    <div *ngIf="!data.AssignedToAgentId" (click)="assignToMe()" style="padding: 5px 15px;float: right;background-color: #4b935e;color: white;cursor: pointer;font-size: 12px;border-radius: 5px; margin-left:15px">Assign to me</div>-->

<!--    <div *ngIf="data.AssignedToAgentId" (click)="unassign()" style="padding: 5px 15px;float: right;background-color: #e7e7e7;color: #595959;cursor: pointer;font-size: 12px;border-radius: 5px; margin-left:15px">-->
<!--      <strong>Assigned to:</strong> {{data.AgentName}} <i style="font-size: 12px;" class="fas fa-times"></i>-->
<!--    </div>-->

    <div  [class.disable-button]="isSendingRequest" *ngIf="data.ClosedDateTime" (click)="reopen()" style="padding: 5px 15px;float: right;background-color: #2f506d;color: white;cursor: pointer;font-size: 12px;border-radius: 5px;">Reopen</div>
    <div>{{data.RequesterName}} ({{data.RequesterEmailAddress}})     <app-loading-dots *ngIf="isSendingRequest" style="margin-left:25px"></app-loading-dots></div>

  </div>

  <div *ngIf="onlineUsers.length > 0" style="padding: 10px;background-color: #f1b200;color: #3d3d3d;">
    <i style="font-size: 12px; margin-right:5px" class="fas fa-eye"></i>
    <span *ngFor="let user of onlineUsers">{{user.Name}}</span> is also viewing at this chat
  </div>

  <div (mouseenter)="mouseMove()" class="chatMessagesContainer" id="chatContainer">
    <div *ngFor="let message of data.Messages" [ngClass]="message.IsInbound ? 'recieve' : 'send'" class="chatMessage clearfix">
      <div style="font-weight: bold; padding-left: 3px;">{{message.UserName}}
        <span style="font-weight: normal; font-size: 11px; padding-left: 5px; ">{{message.CreatedDateTime | amTimeAgo}}</span>
      </div>
      <div class="chatBubble" [innerHTML]="message.Text"></div>
    </div>
  </div>

  <div *ngIf="!data.ClosedDateTime" class="chatActionsContainer">

    <div class="row">
      <div class="col">
        <quill-editor [(ngModel)]="reply" [styles]="{height: '100px'}" placeholder="" style="display:block"></quill-editor>
      </div>
      <div style="width:65px">
        <div (click)="send()" [class.disable-button]="isSendingRequest" style="background-color: #2f506d;padding: 50px 15px;color: white;cursor: pointer;font-size: 20px;border-radius: 10px;height: 89%;padding-top: auto;margin: 6px;margin-left: 11px;"><i class="fas fa-paper-plane"></i></div>
      </div>
    </div>


  </div>

</div>
