<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Customer Portal</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Portal Description <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.PortalDescription" type="text" class="form-control" id="PortalDescription" placeholder="Enter Description" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Host name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <div class="input-group">
          <input [disabled]="!editingHostName" [(ngModel)]="loadedData.HostName" type="text" class="form-control" id="hostName" placeholder="" autocomplete="off">
          <div *ngIf="editingHostName" (click)="checkHostNameAvailable()" class="btn btn-primary">Check</div>
          <div *ngIf="!editingHostName" (click)="editHostName()" class="btn btn-primary">Edit</div>

        </div>

        <div *ngIf="editingHostName && hostNameAvailable == null" class="text-danger">Please check the availability of the Hostname</div>
        <div *ngIf="editingHostName && hostNameAvailable == false" class="text-danger">Hostname is not available</div>
        <div *ngIf="editingHostName && hostNameAvailable == true" class="text-success">Hostname is available</div>

        <!-- Hostname verification information -->
        <div *ngIf="!editingHostName && !loadedData.IsHostNameVerified">
          <div class="text-danger">Hostname is not verified, please follow instructions below to verify:</div>
          <div>{{ loadedData.HostNameSetupInstructions }}</div>
        </div>

        <div *ngIf="!editingHostName && loadedData.IsHostNameVerified">
          <div class="text-success">Hostname has been verified</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Logo <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <img style="border: #e1e1e1 solid 1px; max-height:45px; max-width:200px " src="{{loadedData.LogoUrl}}">
        <input id="ServiceDeskLogo" type="file" class="filestyle" (change)="uploadLogo($event)">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Require Login
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.RequireLogin" class="form-check-input" id="RequireLogin" type="checkbox"><label for="RequireLogin" class="p-l-10"></label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Load My Tickets By Default
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.LoadMyTicketsByDefault" class="form-check-input" id="LoadMyTicketsByDefault" type="checkbox"><label for="LoadMyTicketsByDefault" class="p-l-10"></label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Show KnowledgeBase
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.ShowKnowledgeBase" class="form-check-input" id="ShowKnowledgeBase" type="checkbox"><label for="ShowKnowledgeBase" class="p-l-10"></label>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
      <div (click)="deletePortal()" class="btn btn-cancel float-end">
        <i class="fas fa-trash m-r-5"></i>Delete</div>
    </div>

  </div>
</app-blade-right>
