<div class="d-flex" id="wrapper" [class.toggled]="!sideBarNavOpen">

  <nav class="navbar topBarMakeFixed" id="navbar">

    <button *ngIf="!sideBarNavOpen" (click)="sideBarNavOpen = !sideBarNavOpen" 
      class="navbar-button d-block d-sm-none float-left" id="menu-toggle-mobile">
      <i class="fas fa-bars"></i>
    </button>

    <button *ngIf="sideBarNavOpen" (click)="sideBarNavOpen = !sideBarNavOpen" 
      class="navbar-button d-block float-left" id="menu-toggle-mobile-open">
      <i class="fas fa-bars"></i>
    </button>

    <button *ngIf="!sideBarNavOpen" (click)="sideBarNavOpen = !sideBarNavOpen"
      class="navbar-button d-none d-sm-block float-start navbar-nav-item-icon" id="menu-toggle-right">
      <i class="fas fa-bars"></i>
    </button>

    <div class="navbar-logo text-center">
      <img *ngIf="brandingSubscription?.LogoUrl" alt="Logo" style="max-height:40px; max-width:200px;" class="ng-scope"
        [src]="brandingSubscription?.LogoUrl">
      <!--      <img alt="Logo" style="max-height:40px; max-width:200px " class="ng-scope" src="assets/logo-darkmode.png">-->
    </div>

    <!--    Hidden only on XS-->
    <button (click)="this.location.back()" class="navbar-button d-none d-sm-block navbar-nav-item-icon"
      id="menu-toggle-left"><i class="fas fa-chevron-left"></i></button>

    <button [routerLink]="['/agent/search']" class="navbar-button" id="menu-search"><i class="fas fa-search"></i>
    </button>

    <div class="dropdown">
      <a class="navbar-button dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
        aria-expanded="false">
        Create New
      </a>

      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li><a class="dropdown-item divLink" (click)="createTicket(type.id, type.CmdbEnabled)"
            *ngFor="let type of requestTypes">{{type.name}}</a></li>
      </ul>
    </div>


    <!-- Hidden on XS Screens -->



    <div id="alignRight" class="d-none d-sm-none d-md-none d-lg-none d-xl-block" style="margin-left: auto">

      <div routerLink="/agent/chat/conversation/unassigned"
        *ngIf="instanceParams?.ChatBotEnabled && chatNotifications && chatNotifications?.UnassignedConversations"
        style="background-color: #f7f7f7; margin-right: 5px;" class="navbar-button divLink" id="hi1">
        <i class="fas fa-comments fa-15x" style="color: #c53e3e;"></i> <span
          style="margin-left:7px">{{chatNotifications.UnassignedConversations}} Unassigned</span>
      </div>

      <div routerLink="/agent/chat/conversation/mine"
        *ngIf="instanceParams?.ChatBotEnabled && chatNotifications && chatNotifications?.UnreadMessageCount"
        style="background-color: #f7f7f7; margin-right: 5px;" class="navbar-button divLink" id="hi2">
        <i class="fas fa-comment-dots fa-15x" style="color: #c53e3e;"></i> <span
          style="margin-left:7px">{{chatNotifications.UnreadMessageCount}} Unread</span>
      </div>

      <div *ngIf="timerEnabled" (click)="recordTime()" style="background-color: #f7f7f7; margin-right: 5px;"
        class="navbar-button hoverToRevealSpan divLink" id="record-time-entry">
        <i class="fad fa-stopwatch fa-15x" style="color: #43aa8b;"></i>
      </div>
      <div (click)="raiseBug()" style="background-color: #f7f7f7; margin-right: 5px;"
        class="navbar-button hoverToRevealSpan divLink" id="report-bug">
        <i class="fad fa-bullhorn fa-15x" style="color: #43aa8b;"></i>
      </div>
    </div>


    <div class="dropdown">
      <a class="navbar-button dropdown-toggle" href="#" role="button" id="accountDropDown" data-bs-toggle="dropdown"
        aria-expanded="false">
        <i class="fas fa-user m-r-5"></i> {{ instanceParams?.userName }}
      </a>

      <ul class="dropdown-menu" aria-labelledby="accountDropDown">
        <li><a class="dropdown-item divLink" (click)="logoff()">Logout</a></li>
        <li><a class="dropdown-item divLink" href="/">Self-Service</a></li>
        <li><a class="dropdown-item divLink" (click)="openApiKeyDialog()">My API Keys</a></li>
      </ul>

    </div>

  </nav>

  <!-- Sidebar -->

  <div class="sideBarMakeFixed" id="sidebar-wrapper" [class.hidden]="!sideBarNavOpen">
    <ul>
      <li>
        <a routerLink="/agent/dashboard" routerLinkActive="active"><i class="fas fa-tachometer-alt"></i>
          <span> Dashboard </span></a>
      </li>

      <li *ngIf="instanceParams?.ChatEnabled && instanceParams?.ChatBotEnabled">
        <a routerLink="/agent/chat/conversation/unassigned/" [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="active"><i class="fas fa-comments"></i>
          <span> Chats </span></a>
      </li>

      <li>
        <a [routerLink]="['/agent/view/myrequests']" routerLinkActive="active">
          <i class="fas fa-user"></i>
          <span> My Tickets </span>
        </a>
        <a [routerLink]="['/agent/tasks']" routerLinkActive="active">
          <i class="fas fa-list"></i>
          <span> My Tasks </span>
        </a>
        <a [routerLink]="['/agent/all-tasks']" routerLinkActive="active">
          <i class="fas fa-list"></i>
          <span> All Tasks </span>
        </a>
        <a [routerLink]="['/agent/view/following']" routerLinkActive="active">
          <i class="fas fa-user-friends"></i>
          <span> Following </span>
        </a>
        <hr>

        <div *ngFor="let rt of this.requestTypes" [class.navExtendedBackground]="navigationExpanded.isSelected(rt.id)">
          <a (click)="navigationExpanded.toggle(rt.id)" class="divLink"
            [class.expanded]="navigationExpanded.isSelected(rt.id)">
            <span style="margin-left: 10px"> {{rt.name}}s </span>
            <i [ngClass]="navigationExpanded.isSelected(rt.id) ? 'fa-chevron-up' : 'fa-chevron-down'"
              class="fas float-end"></i>
          </a>
          <ul *ngIf="navigationExpanded.isSelected(rt.id)" class="navIndentSubMenu">
            <li><a [routerLink]="['/agent/view/active/type/', rt.id]" routerLinkActive="active">Active</a></li>
            <li><a [routerLink]="['/agent/view/unassigned/type/', rt.id]" routerLinkActive="active">Unassigned</a></li>
            <li><a [routerLink]="['/agent/view/overdue/type/', rt.id]" routerLinkActive="active"
                *ngIf="rt.CmdbEnabled !== 1">Overdue</a></li>
            <li><a [routerLink]="['/agent/view/duetoday/type/', rt.id]" routerLinkActive="active"
                *ngIf="rt.CmdbEnabled !== 1">Due Today</a></li>
            <li><a [routerLink]="['/agent/view/openedtoday/type/', rt.id]" routerLinkActive="active"
                *ngIf="rt.CmdbEnabled !== 1">Opened Today</a>
            </li>
            <li><a [routerLink]="['/agent/view/resolvedtoday/type/', rt.id]" routerLinkActive="active"
                *ngIf="rt.CmdbEnabled !== 1">Resolved
                Today</a></li>
            <li><a *ngFor="let filter of this.returnFiltersForRequestType(rt.id)"
                [routerLink]="['/agent/filter/', filter.Id]" routerLinkActive="active" [title]="filter.Name">{{
                filter.Name.length > 15 ? (filter.Name | slice:0:15) + '...' : filter.Name }}<i
                  class="fas fa-lock float-end" *ngIf="filter.Private"></i></a></li>
            <li class="divLink" (click)="this.createFilter(rt.id, rt.CmdbEnabled)"><a>New Filter<i
                  class="fas fa-plus float-end"></i></a></li>
          </ul>
        </div>

        <hr>

        <div *ngIf="instanceParams?.MassCommunicationEnabled && userPermissionsSubscription?.MassComms">
          <a [routerLink]="['/agent/mass-communication']" routerLinkActive="active">
            <i class="fas fa-envelope"></i>
            <span> Mass Communication </span>
          </a>
          <hr>
        </div>

        <a *ngIf="userPermissionsSubscription?.Reporting" [routerLink]="['/agent/reporting']" routerLinkActive="active">
          <i class="fas fa-chart-line"></i>
          <span> Reporting </span>
        </a>

        <hr *ngIf="userPermissionsSubscription?.Reporting">


        <a *ngIf="isCmdbEnabled() && !accessPermissions?.AssetManagementAdmin && !accessPermissions?.AssetManagementViewOnly" [routerLink]="['/agent/assets']" routerLinkActive="active">
          <i class="fas fa-laptop"></i>
          <span> Asset Register </span>
        </a>

        <hr>


        <div [class.navExtendedBackground]="navigationExpanded.isSelected('admin')">
          <a class="divLink" *ngIf="userPermissionsSubscription?.Admin" (click)="navigationExpanded.toggle('admin')">
            <i class="fas fa-cogs"></i>
            <span> Admin </span>
            <i [ngClass]="navigationExpanded.isSelected('admin') ? 'fa-chevron-up' : 'fa-chevron-down'"
              class="fas float-end"></i>
          </a>
          <ul *ngIf="navigationExpanded.isSelected('admin')" class="navIndentSubMenu">
            <li><a [routerLink]="['/agent/admin/global/settings']" routerLinkActive="active">Service Desk Settings</a>
            </li>
            <li><a [routerLink]="['/agent/admin/audit-log']" routerLinkActive="active">Audit Log</a></li>
            <li><a [routerLink]="['/agent/admin/users-groups/users']" routerLinkActive="active">Users & Groups</a></li>
            <li *ngIf="instanceParams?.ChatEnabled"><a [routerLink]="['/agent/admin/chat-config']"
                routerLinkActive="active">Chat Config</a></li>
            <li><a [routerLink]="['/agent/admin/customers']" routerLinkActive="active">Customers</a></li>
            <li><a [routerLink]="['/agent/admin/task-lists']" routerLinkActive="active">Task Lists</a></li>
            <li><a [routerLink]="['/agent/admin/services-vendors/services']" routerLinkActive="active">Services &
                Vendors</a></li>
            <li><a [routerLink]="['/agent/admin/email-webhooks/email']" routerLinkActive="active">E-mail & Webhooks</a>
            </li>
            <li><a [routerLink]="['/agent/admin/sla/templates']" routerLinkActive="active">SLA Management</a></li>
            <li><a [routerLink]="['/agent/admin/cannedresponses']" routerLinkActive="active">Canned Responses</a></li>
            <li><a [routerLink]="['/agent/admin/scheduled']" routerLinkActive="active">Scheduled Tickets</a></li>
            <li><a [routerLink]="['/agent/admin/automation']" routerLinkActive="active">Automation</a></li>
            <li><a [routerLink]="['/agent/admin/customerportals']" routerLinkActive="active">Customer Portals</a></li>
            <li *ngFor="let rt of this.adminrequestypes">
              <a [routerLink]="['/agent/admin/type/',rt.id,rt.CmdbEnabled ? 'customfields' : 'settings']" routerLinkActive="active">{{rt.name}}
                settings</a>
            </li>
            <li><a [routerLink]="['/agent/terms-of-service']" routerLinkActive="active">Terms of Service</a></li>


          </ul>
        </div>
      </li>
    </ul>
  </div>
  <!-- /#sidebar-wrapper -->


  <!-- Page Content -->
  <div id="page-content-wrapper" [class.expanded]="!sideBarNavOpen">
    <div class="container-fluid p-0">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->

<!-- Menu Toggle Script -->