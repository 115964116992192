

import {Component, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminGroupsService} from "../../../../../../services/api/admin/admin-groups-api/admin-groups-api.service";
import {ApiService} from "../../../../../../services/api/api.service";
import {RequesterSearchModalComponent} from '../../../../../../shared/requester-search-modal/requester-search-modal.component';
import {DialogService} from '../../../../../../services/dialog-service/dialog.service';
import {AgentSearchModalComponent} from '../../../../../../shared/agent-search-modal/agent-search-modal.component';
import { ModalConfirmationComponent } from '../../../../../../shared/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-admin-groups-create-edit-dialog',
  templateUrl: './admin-groups-create-edit-dialog.component.html'
})

export class AdminGroupsComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  selectedTab = 'details';

  isLoading = true;
  isLoadingRequestTypes = true;
  isLoadingSlaTemplates = true;

  loadedId = null;
  loadedData;

  requestTypes;
  slaTemplates;

  constructor(
    private apiAdminGroupsService: ApiAdminGroupsService,
    private apiService: ApiService,private dialogService: DialogService, private viewReference: ViewContainerRef, private api: ApiService
  ) {

    // ToDo: Use the SLA Template service when it has been generated
    this.apiService.get('/api/admin/slatemplate').then(response => {
      this.slaTemplates = response;
      this.isLoadingSlaTemplates = false;
    })

  }

  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminGroupsService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  removeMember(userId){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => { 
        this.api.delete(`/api/admin/group-membership/${this.loadedId}/user/${userId}`).then(response => {
          this.getData();
        })
      }, cancelled => null)
    
  }

  openUserSearchModal(){
    this.dialogService.createDialog(AgentSearchModalComponent, this.loadedId, this.viewReference)
      .then((dialogSaved: any) => {
        this.getData();

      }, dialogCancelled => {
        console.log("dialogCancelled:"+dialogCancelled)
      })

  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminGroupsService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminGroupsService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
