

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from '../../../shared/dialog/dialog';
import {ApiService} from '../../../services/api/api.service';

@Component({
  selector: 'app-api-key-dialog',
  templateUrl: './api-key-dialog.html'
})

export class ApiKeyDialogComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  apiKeyData;
  isLoading = true;
  showApiKeySecretMessage = false;

  constructor(
    private apiService: ApiService
  ) {}


  ngOnInit(): void {
    this.apiService.get('/api/api-key').then(response => {
      this.apiKeyData = response;
      this.isLoading = false;
    })
  }

  createApiKey(){
    this.isLoading = true;

    this.apiService.post('/api/api-key/generate').then(response => {
      this.apiKeyData = response;
      this.showApiKeySecretMessage = true;

      this.isLoading = false;
    })
  }


  close(){
    this.remove.emit(null);
  }

}
