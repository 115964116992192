import {Component, Input, OnInit} from '@angular/core';
import {InstanceParamsService} from "../../../../services/instance-params/instance-params.service";


@Component({
  selector: 'app-request-major-incident-thread',
  templateUrl: './request-major-incident-thread.component.html',
  styleUrls: ['./request-major-incident-thread.component.css']
})
export class RequestMajorIncidentThreadComponent implements OnInit {
  @Input() requestDetails;
  instanceParams: any;


  constructor(private instanceParamsService: InstanceParamsService,) { }

  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });
  }

}
