<app-modal [modalSize]="'modal-large'">
  <div class="modal-header sticky-header">
    <h5 class="modal-title" id="exampleModalLabel">Add Link</h5>
    <div>
      <div *ngIf="windowShown == 'selectLinkType'" (click)="getTickets()" class="btn btn-primary" [class.disabled]="!selectedLinkType">Browse</div>
      <div *ngIf="windowShown == 'selectTicket'" (click)="save()" class="btn btn-primary" [class.disabled]="!selectedLinkType">Save</div>
      <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
    </div>
  </div>
  <div class="modal-body">

    <div *ngIf="windowShown == 'selectLinkType'" id="selectLinkTypeWindow">
      <p class="text-muted">Select the type of link you would like to add</p>
      <div class="list-group m-b-15 m-t-10">
        <a class="list-group-item divLink" *ngFor="let x of this.availableLinks" [class.active]="selectedLinkType == x" (click)="selectedLinkType = x">
          <h4 class="list-group-item-heading">{{x.Direction == 'LeftToRight' ? x.Right_Name : x.Left_Name}}</h4>
          <p class="list-group-item-text">{{x.Description}}</p>
        </a>
      </div>
    </div>

    <div *ngIf="windowShown == 'selectTicket'" id="browseTicketWindow">
      <p class="text-muted">Select the ticket you would like to link to</p>


      <!--suppress HtmlFormInputWithoutLabel -->
      <input [(ngModel)]="searchTerm" (ngModelChange)="resetPage()" class="form-control" name="searchTerm" placeholder="Search...">


      <div class="table-responsive">
        <table class="table align-middle tableStyle">
          <thead>
          <tr>
            <th class="">Type</th>
            <th class="">{{tickets.Results[0].CmdbEnabled == 1 ? 'Asset No.' : 'Id' }}</th>
            <th class="">{{tickets.Results[0].CmdbEnabled == 1 ? 'Owner' : 'Requester' }} </th>
            <th class="col-sm-3">{{tickets.Results[0].CmdbEnabled == 1 ? 'Asset Name' : 'Subject' }}</th>
            <th class="">Status</th>
            <th *ngIf="tickets.Results[0].CmdbEnabled != 1" class="">Priority</th>
            <th *ngIf="tickets.Results[0].CmdbEnabled != 1" class="">Agent</th>
            <th class="">Updated</th>
            <th class="">Created</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let request of this.filteredTicketsThisPage()" class="divLink" [class.sdesk-filter-selected]="selectedRequestId == request.Id" (click)="selectedRequestId = request.Id">
            <td>{{request.RequestTypeName}}</td>
            <td>{{request.CmdbEnabled == 1 ? request.AssetNo : request.LocalRequestId}}</td>
            <td>{{request.Requester}}</td>
            <td>{{request.Subject}}</td>
            <td>{{request.Status}}</td>
            <td *ngIf="request.CmdbEnabled != 1">{{request.Priority}}</td>
            <td *ngIf="request.CmdbEnabled != 1">{{request.Agent}}</td>
            <td>{{request.DateTimeUpdated | amTimeAgo}}</td>
            <td>{{request.DateTimeCreated | amTimeAgo}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <ul *ngIf="this.totalPages > 1" class="pagination m-b-0 float-end">
        <li class="page-item">
          <a class="page-link divLink" (click)="pageBackwards()" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item">
          <a class="page-link divLink" (click)="pageForward()" ria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
      <p class="text-muted text-center m-b-10">
        {{this.filteredTicketsThisPage().length}} of {{this.totalFilteredResults}} results - page {{this.page}} of {{this.totalPages}}
      </p>
    </div>

  </div>
</app-modal>
