<div id="majorIncidentManagementTabs">
  <div class="row">

    <div (click)="selectedTab = 'tasks'" class="col-1 text-center dashboardTile divLink" [class.active]="selectedTab == 'tasks'">
      <i class="fas fa-list fa-2x m-t-10"></i>
    </div>

    <div (click)="selectedTab = 'links'" class="col dashboardTile divLink" [class.active]="selectedTab == 'links'">
      <div class="statisticContainer">
        <div class="statisticBox">
          <h3>{{(requestDetails.Links).length}}</h3>
        </div>
        <h4 class="statisticBoxHeading pt-2">Linked Incidents</h4>
      </div>
    </div>

    <div (click)="selectedTab = 'customers'" class="col dashboardTile divLink" [class.active]="selectedTab == 'customers'">
      <div class="statisticContainer">
        <div class="statisticBox">
          <h3>{{(requestDetails.CustomerLinks).length}}</h3>
        </div>
        <h4 class="statisticBoxHeading pt-2">Affected Customers</h4>
      </div>
    </div>

    <div (click)="selectedTab = 'services'" class="col dashboardTile divLink" [class.active]="selectedTab == 'services'">
      <div class="statisticContainer">
        <div class="statisticBox">
          <h3>{{(requestDetails.ServiceLinks).length}}</h3>
        </div>
        <h4 class="statisticBoxHeading pt-2">Affected Services</h4>
      </div>
    </div>

  </div>
</div>



<div class="majorIncidentManagementContent sectionBox">
  <div *ngIf="selectedTab == 'tasks'">
    <div class="table-responsive">
      <table class="table align-middle tableStyle">
        <tbody>
        <tr *ngFor="let task of this.requestDetails.MITasks">
          <th scope="row" class="col-7">
            {{task.TaskName}}
          </th>
          <td>
            <i *ngIf="!task.IsComplete" class="fas fa-hourglass-start icon-amber"></i>
            <i *ngIf="task.IsComplete" class="fas fa-check icon-success"></i>
            {{task.IsComplete ? 'Completed' : 'Pending'}}
          </td>
          <td>
            <span *ngIf="!task.IsComplete" (click)="markTaskComplete(task.Id)" class="requestBodyButton divLink">
              Mark Complete</span>
          </td>
          <td>
            <span *ngIf="!task.IsComplete && task.IsCommunicationTask" (click)="sendMassCommunication(task)" class="requestBodyButton divLink">
            <i class="far fa-envelope"></i>
            Send Communication</span>
          </td>
        </tr>
        </tbody>
      </table>

    </div>

  </div>

  <div *ngIf="selectedTab == 'links'">
    <div class="table-responsive">
      <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th>Id</th>
          <th>Subject</th>
          <th>Link Type</th>
          <th>Link Description</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let x of this.requestDetails.Links" ng-repeat="x in details.Links">
          <th scope="row">
            <a href="/agent/request/{{(x.LeftRequestId == this.requestDetails.Id) ? x.RightRequestId : x.LeftRequestId }}">
              {{(x.LeftRequestId == this.requestDetails.Id) ? x.RightRequestLocalId :
              x.LeftRequestLocalId}}
            </a>
          </th>
          <td>
            <a href="/agent/request/{{(x.LeftRequestId == this.requestDetails.Id) ? x.RightRequestId : x.LeftRequestId }}">
              {{(x.LeftRequestId == this.requestDetails.Id) ? x.RightSubject : x.LeftSubject}}
            </a>
          </td>
          <td>
            {{(x.LeftRequestId == this.requestDetails.Id) ? x.Right_Name : x.Left_Name}}
          </td>
          <td>
            {{x.IsAdvanced ? ((x.LeftRequestId == this.requestDetails.Id) ? x.RightToLeft :
            x.LeftToRight) : 'Linked to'}} this {{(x.LeftRequestId == this.requestDetails.Id) ?
            x.Left_Name : x.Right_Name}}
          </td>
          <td class="divLink" (click)="removeLink(x.Id)">Delete</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class=""><button class="btn btn-primary divLink" (click)="addLink()">Add Links</button></div>
  </div>

  <div *ngIf="selectedTab == 'customers'">
    <table class="table m-0">
      <thead>
      <tr>
        <th>Customer Name</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let x of requestDetails.CustomerLinks">
        <td>
          {{x.CustomerName}}
          <button type="button"
                  class="btn-xs btn-primary float-end"
                  (click)="removeCustomerLink(x.CustomerId)">Remove
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <button class="btn btn-primary divLink" (click)="linkCustomers()">Link Customers</button>
  </div>

  <div *ngIf="selectedTab == 'services'">
    <table class="table m-0">
      <thead>
      <tr>
        <th>Service Name <a (click)="linkServices()"
                            class="btn btn-inverse btn-sm waves-effect waves-light pull-right"><i
          class="md md-add"></i>Link Services</a>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let x of requestDetails.ServiceLinks">
        <td>
          {{x.ServiceName}}
          <button type="button"
                  class="btn-xs btn-primary float-end"
                  (click)="removeServiceLink(x.ServiceId)">Remove
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <button class="btn btn-primary divLink" (click)="linkServices()">Link Services</button>
  </div>
</div>
