<app-modal [modalSize]="'modal-medium'">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Please select the parent ticket</h5>
      </div>
      <div class="modal-body">
        <p class="text-muted">
          All tickets will be merged with the parent ticket. If any of the child tickets have links to other tickets, they will be removed.
        </p>
        <div class="table-responsive">
          <table class="table align-middle tableStyle">
            <thead>
            <tr>
              <th class="">Type</th>
              <th class="">Requester</th>
              <th class="col-sm-3">Subject</th>
              <th class="">Status</th>
              <th class="">Priority</th>
              <th class="">Agent</th>
              <th class="">Updated</th>
              <th class="">Created</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let request of this.data" class="divLink" [class.sdesk-filter-selected]="selectedTicketId == request.Id" (click)="selectedTicketId = request.Id">
             <td>{{request.RequestTypeName}}</td>
              <td>{{request.Requester}}</td>
              <td>{{request.Subject}}</td>
              <td>{{request.Status}}</td>
              <td>{{request.Priority}}</td>
              <td>{{request.Agent}}</td>
              <td>{{request.DateTimeUpdated | amTimeAgo}}</td>
              <td>{{request.DateTimeCreated | amTimeAgo}}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <div (click)="save()" class="btn btn-primary" [class.disabled]="!selectedTicketId">Merge</div>
        <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
      </div>
</app-modal>
