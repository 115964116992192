import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {OptionsApiService} from '../../services/api/options/options-api.service';

@Component({
  selector: 'app-record-time',
  templateUrl: './record-time.component.html',
  styleUrls: ['./record-time.component.css']
})
export class RecordTimeComponent implements OnInit {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  customers = [];
  customerId = null;
  hours = 0;
  minutes = 0;
  comment = "";

  constructor(public apiService: ApiService, private options: OptionsApiService) {
  }

  ngOnInit(): void {
    this.options.AllCustomers().then(response => this.customers = response);
  }

  close() {
    this.remove.emit(null);
  }

  save() {

    let totalSeconds = this.hours * 60 * 60;
    totalSeconds = totalSeconds + (this.minutes * 60);

    this.apiService.post('/api/timer', {CustomerId: this.customerId, Comment: this.comment, ElapsedSeconds: totalSeconds}).then(success => {

      this.remove.emit("Success");
    })

  }

}
